import { Component, OnInit } from '@angular/core';
import { DemoService } from 'src/app/services/demo.service';
import {ToastService} from '../../services/general/toast.service';
import {UnitsService} from '../../services/config/units.service';
import {CriteriaService} from '../../services/config/criteria.service';
import {WorkingSectorsService} from '../../services/config/workingSectors.service';
import {AnalysisMethodsService} from '../../services/analysis-methods.service';
import {UserService} from '../../services/user.service';
import {FrameworkService} from '../../services/framework.service';
import {ActivatedRoute} from '@angular/router';
import {generateRandomId} from 'angular-google-charts/lib/helpers/id.helper';
@Component({
  selector: 'app-task-management',
  templateUrl: './task-management.component.html',
  styleUrls: ['./task-management.component.scss']
})
export class TaskManagementComponent implements OnInit {

  constructor(private demoService: DemoService) { }

  tasks = [];
  loading = false;

  ngOnInit(): void {
    this.tasks = this.demoService.tasks;
    console.log(this.tasks);
  }

  get completedTasks() {
    return this.tasks.filter((task) => task.has_completed);
  }

  get uncompletedTasks() {
    return this.tasks.filter((task) => !task.has_completed);
  }

  toggleEditMode(task) {
    task.editMode = !task.editMode;
  }

  setCompleted(task) {
    task.has_completed = true;
  }

  deleteTask(task) {
    this.tasks = this.tasks.filter((tmp) => task.id !== tmp.id);
  }

  isValid(task) {
    return task.id && task.additional_text && task.action && task.assigned_user_name && task.end_date
  }

  saveTask(task, newTask = false ) {
    if(this.isValid(task)) {
      task.editMode = false;
      if (newTask) {
        task.new = false;
      }
    }
  }

  newTask() {
    if(!this.tasks.some((task) => task.new)){
      this.tasks.unshift({
        id: this.findMaxId(),
        editMode: true,
        has_completed: false,
        new: true,
      });
    }
  }

  private findMaxId() {
    let maxId = 0;

    this.tasks.forEach((obj) => {
      if (obj.id > maxId) {
        maxId = obj.id;
      }
    });

    return maxId;
  }


}
