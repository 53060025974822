import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModalNewProposalComponent } from 'src/app/modals/modal-new-proposal/modal-new-proposal.component';
import { DemoService } from 'src/app/services/demo.service';
import { ToastService } from 'src/app/services/general/toast.service';
import { HeaderTitleService } from 'src/app/services/header-title.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import {
  ModalContextDiscoveryComponent
} from "../../../modals/modal-context-discovery/modal-context-discovery.component";
import {ModalNetworkAnalisysComponent} from "../../../modals/modal-network-analisys/modal-network-analisys.component";

@Component({
  selector: 'app-show-decision-table',
  templateUrl: './show-decision-table.component.html',
  styleUrls: ['./show-decision-table.component.scss']
})
export class ShowDecisionTableComponent implements OnInit {

  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;


  private _activeRouteSubscription: Subscription;

  id: any;

  professionalRoles: any = [];
  //  TODO_MICHELE: aggiungere i ruoli che vengono gestiti dal framework
  process_roles: any = [
    { key: 'RESPONSIBLE', title: 'Responsible'},
    { key: 'ACCOUNTABLE', title: 'Accountable'},
    { key: 'CONSULTED', title: 'Consulted'},
    { key: 'INFORMED', title: 'Informed'}
  ];

  tmp = null;

  evaluation_sessions_by_row: number = 5;
  private evaluation_sessions: any[] =  [];
  evaluation_sessions_row_1 = [];
  evaluation_sessions_row_2 = [];

  suggestions: any = [] = [];
  proposals: any = [] = [];

  startNewEvaluationChoice: boolean = false;
  startNewEvaluation: boolean = false;

  //  model per l'avvio di una nuova sessione di valutazione
  modelStartNewEvaluation: any = {
    start_date: null,
    end_date: null,
    settings: {
      provide_new_proposal_enabled: false,
      review_enabled: false,
      degree_of_confidence_enabled: false,
      provide_feedback_enabled: false,
      anonymous_survey_enabled: false
    },
    team_members: [

    ]
  }

  constructor(private userService: UserService, private demoService: DemoService, public dialog: MatDialog,
              private activeRoute: ActivatedRoute, private headerService: HeaderTitleService, private toast: ToastService) { }

  ngOnInit(): void {

    //  controllo se sono in modifica o in creazione
    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.id = routeParams.id_decision;
      this.init();
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();
  }

  init() {

    //  TODO_MICHELE: versione demo per Enzo
    if (environment.demo) {

      switch (this.id) {
        case "1":
          var modelDemo = this.demoService.casoSarcomatoid;

          // this.evaluation_sessions = modelDemo.evaluation_sessions;
          this.evaluation_sessions = modelDemo.evaluation_sessions.slice();
          this.suggestions = modelDemo.suggestions.slice();
          this.proposals = modelDemo.proposals.slice();

          this.modelStartNewEvaluation.team_members = modelDemo.team_members.slice();
          this.modelStartNewEvaluation.title = modelDemo.title;
          this.modelStartNewEvaluation.closing_date = modelDemo.closing_date;

          console.log(modelDemo);
          break;
        case "2":
          var modelDemo = this.demoService.casoYamal;

          // this.evaluation_sessions = modelDemo.evaluation_sessions;
          this.evaluation_sessions = modelDemo.evaluation_sessions.slice();
          this.suggestions = modelDemo.suggestions.slice();
          this.proposals = modelDemo.proposals.slice();

          this.modelStartNewEvaluation.team_members = modelDemo.team_members.slice();

          this.modelStartNewEvaluation.title = modelDemo.title;
          this.modelStartNewEvaluation.closing_date = modelDemo.closing_date;
          console.log(modelDemo);
          break;
        default:
          break;
      }
      this.tmp = modelDemo;
    }

    // this.headerService.setTitle(this.headerService.getTitle() + ': ' + this.modelStartNewEvaluation.title);
    this.headerService.setTitle(this.modelStartNewEvaluation.title);

    this.evaluation_sessions_row_1 = this.evaluation_sessions.splice(0, this.evaluation_sessions_by_row);
    this.evaluation_sessions_row_2 = this.evaluation_sessions;

    this.professionalRoles = this.userService.getAllProfessionalRoles();
  }


  /**
   * Restituisce la classe che colora il box
   */
  getClassSuggestion(proposal) {
    switch (proposal.result) {
      case "DISCARD":
        return 'bg-red'
      case "GOOD":
        return 'bg-green'
      case "CHANGE":
        return 'bg-orange'
      case "EVALUATE":
        return ''
    }
  }
  getIconSuggestion(proposal) {
    switch (proposal.result) {
      case "DISCARD":
        return 'icon-Close'
      case "GOOD":
        return 'icon-Yes'
      case "CHANGE":
        return 'icon-punto_esclamativo'
      case "EVALUATE":
        return ''
    }
  }

  /** Evento di pressione sui pulsanti di scelta se cominciare o meno una nuova sessione di valutazione */
  onClickStartNewSession(clickedValue) {
    this.startNewEvaluationChoice = clickedValue;

    if (this.startNewEvaluationChoice == false) {
      this.startNewEvaluation = false;
      this.modelStartNewEvaluation = {
        settings: [],
        team_members: []
      };
    }
  }

  /** Conferma di avvio di una nuova sessione di valutazione */
  onClickConfirmStartNewSession() {
    if (this.startNewEvaluationChoice == true) {
      this.startNewEvaluation = true;
    }
  }


  /** Valida e lancia la nuova sessione */
  onClickSaveAndStartSession() {
    console.log("start new session");
  }




  /** Apre una dialogue per inserire una nuova proposal */
  newProposal() {
    const dialogRef = this.dialog.open(ModalNewProposalComponent, {
      panelClass: 'fullscreen-dialog',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  /** Apre una dialogue per il context discovery */
  openContextDiscovery() {
    const dialogRef = this.dialog.open(ModalContextDiscoveryComponent, {
      panelClass: 'medium-dialog',
      data: this.id,
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openNetworkAnalisys() {
    const dialogRef = this.dialog.open(ModalNetworkAnalisysComponent, {
      panelClass: 'medium-dialog',
      data: this.id,
    });
  }

  /** Apre una dialogue per editare una proposal */
  editProposal(proposal: any) {

    const dialogRef = this.dialog.open(ModalNewProposalComponent, {
      panelClass: 'fullscreen-dialog',
      data: proposal
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  /** Genera automaticamente una nuova proposta */
  generateProposal() {

    if (this.id == 1) {
      this.toast.alert("Attenzione", "Non è possibile generare una proposata per questo tipo di analisi");
      return;
    }

    this.dialog.open(this.dialogTemplate, {minWidth: "600px"} );
  }

  confirmGenerateProposal() {
    this.dialog.closeAll();
    this.demoService.setProposal(this.proposalGenerated);
    this.init();
  }









  proposalGenerated: any =
  {
    title: "Proposal A", proposed_by: "ProposalBot", proposed_by_role:"ML", proposed_by_avatar: "https://cdn3.iconfinder.com/data/icons/chat-bot-blue-filled-color/300/215226424Untitled-3-512.png", creation_session: "Sessione Valutazione 1", when_evaluated: "", score: null, score_value: null, result: "EVALUATE", suggestion: "Non ancora valutata",
    framework: {
      framework_name: "Construction Strategy",
      organization_id: 3,
      analysis_type: 1,
      sectors: [{sector_key: "ENGINEERING", subsector_key: "INDUSTRIALENGINEERING"}],
      purpose_description: 'This framework is helping you in deciding a proper construction strategy of industrial plant in a severe conditions environment and to evaluate advantage of modularization approach.',
      primary_target: {type: 'primary', title: 'Project Success', evaluation_criteria: "HIGHESTISTHEBEST"},
      secondary_targets: [
        { type: 'secondary', title: 'Cost', evaluation_criteria: 'LOWESTISTHEBEST', chartData: { } },
        { type: 'secondary', title: 'Delivery', evaluation_criteria: 'LOWESTISTHEBEST', chartData: { } },
        { type: 'secondary', title: 'Quality', evaluation_criteria: 'HIGHESTISTHEBEST', chartData: { } },
        { type: 'secondary', title: 'Risk', evaluation_criteria: 'HIGHESTISTHEBEST', chartData: { } },
      ],
      criteria: [
        { order: 1, title: "Site conditions", measure_unit: null, evaluation: null, typology: "QUALITATIVE", description: "Extreme environmental conditions: very low temperatures, site not reachable by sea for 9 months a year.Non-Modularization couldn't be used as management and construction strategy in order to properly face critical aspects due to extreme environmental conditions by simplifying all activities to be carried out on site.",
          feedbackData: [],
          evidenceData: [],
          indicators: {},
          chartData: {}
        },
        { order: 2, title: "Project Schedule", measure_unit: null, evaluation: null, typology: "QUALITATIVE", description: "Due to extreme site conditions, time schedule is critical and all activities, in terms of materials delivery have to be carried out during a restricted time window. Non-Modularization set bigger issues to meet time schedule by avoiding additional critical issued due to activities to be carried out directly on site.",
          feedbackData: [],
          evidenceData: [],
          indicators: {},
          chartData: {}
        },
        { order: 3, title: "Installation and Erection on Site", measure_unit: null, evaluation: null, typology: "QUALITATIVE", description: "Installation and erection activities on site can be carried out only when weather conditions permit them. Non-Modularization can make difficulty all activities related to installation and erection as several and complex integration activities will remain to be finalize on site.",
          feedbackData: [],
          evidenceData: [],
          indicators: {},
          chartData: {}
        },
        { order: 4, title: "Integration", measure_unit: null, evaluation: null, typology: "QUALITATIVE", description: "All packages have to shipped and delivered to site as much as possible completed; system integration will be a critical aspect to let proper plant operation. This activity is an essential step to get all positive impacts from modularization strategy.",
          feedbackData: [],
          evidenceData: [],
          indicators: {},
          chartData: {}
        },
        { order: 5, title: "Project Management Plan", measure_unit: null, evaluation: null, typology: "QUALITATIVE", description: "Triple constrains triangle factors can be put in a well defined priority order: time, quality and cost. Non-Modularization can't permit to divide in more simple activities each project activity and manage easily the Project Scope.",
          feedbackData: [],
          evidenceData: [],
          indicators: {},
          chartData: {}
        },
        { order: 6, title: "Logistic Philosophy", measure_unit: null, evaluation: null, typology: "QUALITATIVE", description: "AlAll packages have to shipped and delivered to site as much as possible completed and during a well defined time. Non-Modularization will be more complex to be applied from Logistic point of view as all the Vendors will have to meet timely the completion of packages they are providing.",
          feedbackData: [],
          evidenceData: [],
          indicators: {},
          chartData: {}
        },
        { order: 7, title: "HSE Operation", measure_unit: null, evaluation: null, typology: "QUALITATIVE", description: "Due to the particular climatic conditions, the aspects related to workers' safety and health can have a decisive impact on the entire project. Non-Modularization increases risky activity to be carried out on site.",
          feedbackData: [],
          evidenceData: [],
          indicators: {},
          chartData: {}
        },
        { order: 8, title: "Procurement Management Strategy", measure_unit: null, evaluation: null, typology: "QUALITATIVE", description: "Vendors selection based on vendors factory location and vendors past performance in meeting delivery. Non-Modularization has to be the base of Vendors selection in order to have the proper results in terms of delivery and quality.",
          feedbackData: [],
          evidenceData: [],
          indicators: {},
          chartData: {}
        },
        { order: 9, title: "Engineering Design", measure_unit: null, evaluation: null, typology: "QUALITATIVE", description: "Engineering design in order to reduce any construction activity (e.g. welding operation, ...) as much as possible and to simplify any on site assembly (integration) activity. Non-Modularization implies a smaller effort to prepare a proper set of documents and drawings and a big effort for preparing all the procedures to properly execute each activity on site.",
          feedbackData: [],
          evidenceData: [],
          indicators: {},
          chartData: {}
        },
        { order: 10, title: "Modularization Site Selection", measure_unit: null, evaluation: null, typology: "QUALITATIVE", description: "In case of non-modularization strategy selection, modularization site is not applicable. Logistic is fundamental success factor. Non-Modularization strategy implies the correct selection each Vendors.",
          feedbackData: [],
          evidenceData: [],
          indicators: {},
          chartData: {}
        },
        { order: 11, title: "Time", measure_unit: "MONTH", evaluation: "HIGHESTISTHEBEST", typology: "QUANTITATIVE", description: "18 MONTHs",
          feedbackData: [],
          evidenceData: [],
          indicators: {},
          chartData:{}
        }
      ]
    }
  }

}
