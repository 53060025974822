<ng-template #notification let-title="title" let-content="content">
  <div class="toast" style="opacity: 1;" role="alert">
    <div class="toast-header">
      <div class="d-flex w-100">
        <i class="icon-Menu"></i>
        <h5 class="mb-0">{{title}}</h5>
        <i class="icon-Cancel ml-auto"></i>
      </div>
      <small class="ml-auto"></small>
      <i *ngIf="currentMessage.type == 'success'" class="tio-checkmark-circle-outlined tio-lg text-success"></i>
      <i *ngIf="currentMessage.type == 'error'" class="tio-clear-circle-outlined tio-lg text-danger"></i>
      <i *ngIf="currentMessage.type == 'alert'" class="tio-alarm tio-lg text-warning"></i>
      <i *ngIf="currentMessage.type == 'warn'" class="tio-warning-outlined tio-lg text-warning"></i>
      <i *ngIf="currentMessage.type == 'info'" class="tio-info-outined tio-lg text-primary"></i>      
    </div>
    <div *ngIf="content" class="toast-body text-dark">
      {{content}}
    </div>
  </div>
</ng-template>

<simple-notifications [options]="notificationOptions"></simple-notifications>
