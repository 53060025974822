<header class="container-fluid">
    <div class="container">
        <mat-toolbar>
            <mat-toolbar-row>
                <img [routerLink]="['/']" routerLinkActive="router-link-active"  class="logo" src="../assets/img/butterfly-logo.png"/>
                <span class="divider"></span>
                <img [routerLink]="['/']" class="logo" src="../assets/img/decision-maker-logo.png"/>
                <button mat-icon-button class="ml-auto">
                  Hello Enzo &nbsp;
                  <img class="avatar-img-pic" src="https://pbs.twimg.com/profile_images/1246785644371771393/6g1yXzF5_400x400.jpg">
                </button>
            </mat-toolbar-row>
            <mat-toolbar-row>
                <h1>{{pageTitle}}</h1>
            </mat-toolbar-row>
        </mat-toolbar>
    </div>
</header>
