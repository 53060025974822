import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderTitleService {

  title = new BehaviorSubject('');

  constructor(private titleService: Title) { }

  public setTitle(title: string) {
    this.titleService.setTitle(title);
    this.title.next(title);
  }

  public getTitle(): string {
    return this.titleService.getTitle();
  }


}
