
<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span *ngIf="loading">Loading network analisys...</span>
    <span *ngIf="!loading">Network analisys</span>
    <span class="modal-toolbar-spacer"></span>
    <button class="ml-auto" mat-icon-button>
      <mat-icon (click)="closeDialogue()">close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<mat-dialog-content *ngIf="!loading">
  <mat-accordion class="accordion" hideToggle>
    <mat-expansion-panel expanded disabled>
      <ng-container>
        <div class="container my-3">
          <div class="row">
            <img [src]="imgSrc" class="centered-image">
          </div>
        </div>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>

