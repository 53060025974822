import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DemoService} from "../../../services/demo.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";

@Component({
    selector: 'app-project-details',
    templateUrl: './project-details.component.html',
    styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit, AfterViewInit {
    projectId: string | null = null;
    data: object | null = null;
    model: object | null = null;
    loading: boolean = false;
    picoQuestionStatus: string | null = null;
    biblioSearchStatus: string | null = null;
    biblioSearchDisplayedColumns: string[] = ['articleTitle', 'authors', 'year', 'studyType', 'mainOutcome', 'riskOfBias', 'consistency', 'precision', 'effectSize', 'gradeQuality'];
    biblioSearchDataSource: MatTableDataSource<any>;
    @ViewChild(MatPaginator) biblioSearchPaginator!: MatPaginator;
    @ViewChild(MatSort) biblioSearchSort!: MatSort;

    constructor(
        private route: ActivatedRoute,
        private demoService: DemoService
    ) {
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.projectId = params.get('id_project');
            this.loading = true;
            setTimeout(() => {
                this.init();
                this.loading = false;
            }, 1000);
        });
    }

    ngAfterViewInit() {
        // @ts-ignore
    }

    init() {
        // @ts-ignore
        this.data = this.demoService.projectDemos[this.projectId - 1];
        this.model = {...this.data};

        // @ts-ignore
        this.biblioSearchDataSource = new MatTableDataSource(this.data.biblio.articles);
        this.biblioSearchDataSource.sort = this.biblioSearchSort;
        // @ts-ignore
    }

    generatePICOQuestion() {
        this.picoQuestionStatus = 'pending';
        setTimeout(() => {
            this.picoQuestionStatus = 'ready';
        }, 2000);
    }

    generateBiblioTable() {
        this.biblioSearchStatus = 'pending';
        setTimeout(() => {
            this.biblioSearchStatus = 'ready';
        }, 2000);
    }

    updateProjectDetails() {
        // @ts-ignore
        this.data.projectDetails = {...this.model.projectDetails};
    }

    resetProjectDetails() {
        // @ts-ignore
        this.model.projectDetails = {...this.data.projectDetails};
    }

    areObjectsDifferent(obj1, obj2) {
        if (obj1 === obj2) return false;

        if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
            return true;
        }

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return true;
        }

        for (const key of keys1) {
            if (!keys2.includes(key)) {
                return true;
            }

            if (this.areObjectsDifferent(obj1[key], obj2[key])) {
                return true;
            }
        }

        return false;
    }

}
