import { Component, OnInit } from '@angular/core';
import { WorkingSectorsService } from 'src/app/services/config/workingSectors.service';
import { AnalysisMethodsService } from 'src/app/services/analysis-methods.service';
import { ToastService } from 'src/app/services/general/toast.service';
import { UserService } from 'src/app/services/user.service';
import { Scroll } from 'src/app/_helpers/scroll/scroll.component';
import { UnitsService } from 'src/app/services/config/units.service';
import { CriteriaService } from 'src/app/services/config/criteria.service';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-new-decision-table',
  templateUrl: './new-decision-table.component.html',
  styleUrls: ['./new-decision-table.component.scss'],
})
export class NewDecisionTableComponent implements OnInit {

  step: number = 1;
  searchDone: boolean = false;
  frameworkSelected: any;

  searchModel: any = {
    sector_key: null,
    subsector_key: null,
    analysis_type: null,
  };

  model: any = {
    decision_name: null,
    organization_id: null,
    delivery_date: null,
    framework_id: null,
  };

  change_rating_scale: boolean = false;
  rating_scale_empty: any = {
    min_value: null,
    max_value: null,
    step: null,
    description: null,
    selected: false
  };
  createArrayOfNumber (start:number = 0, end:number) {
    return [...Array(end).keys()]
  }

  modelStartDecision: any = {
    targets: [],
    proposals: [],
    rating_scales: [],
    team_members: []
    // organization_id: null,
    // delivery_date: null,
    // framework_id: null,
  };

  userOrganizzation: any = [];
  professionalRoles: any = [];

  //  TODO_MICHELE: aggiungere i ruoli che vengono gestiti dal framework
  process_roles: any = [
    { key: 'RESPONSIBLE', title: 'Responsible'},
    { key: 'ACCOUNTABLE', title: 'Accountable'},
    { key: 'CONSULTED', title: 'Accountable'},
    { key: 'INFORMED', title: 'Informed'}
  ];

  //  TODO_MICHELE: lista di framework che deve arrivare dal server
  frameworkFilteredList: any = [];

  constructor(private toast: ToastService, private userService: UserService, private unitsService: UnitsService, private criteriaService: CriteriaService,
              private workingSectorService: WorkingSectorsService, private analysisMethodsService: AnalysisMethodsService) {}

  ngOnInit(): void {

    //  inizializzo i dati dell'utente
    this.userOrganizzation = this.userService.getAllOrganizzations();
    this.professionalRoles = this.userService.getAllProfessionalRoles();
  }

  /**
   * Evento di submit di tutta la pagina
   */
  onSubmit(form: NgForm) {

    console.log(this.model);

    if (this.step == 1) {

      if (this.model.decision_name)
        this.model.decision_name = this.model.decision_name.trim();

      //  validazione dei dati
      if (!this.model.decision_name) { this.toast.error("Attenzione", "Non è stato inserito il nome del tavolo decisionale"); return; }
      if (!this.model.organization_id) { this.toast.error("Attenzione", "Non è stata selezionata l'organizzazione"); return; }
      if (!this.model.framework_id) { this.toast.error("Attenzione", "Non è stato selezionato nessun framework"); return; }
      if (!this.searchValid) { this.toast.error("Attenzione!", "Non è stato selezionato nessun filtro di ricerca per il framework")}

      console.log('------------- VALID -----------');
      console.log(this.modelStartDecision);

      //  preparo il model per la proposta
      this.prepareTargets();
      this.prepareProposal();
      this.prepareRatingScales();
      this.prepareTeam();

      this.step = 2;

      Scroll.toTop();
    } else {

      this.modelStartDecision.team_members.forEach(teamMember => {
        if (teamMember.username) teamMember.username = teamMember.username.trim();
        if (teamMember.email) teamMember.email = teamMember.email.trim();
      });

      if (!this.targetsValid) { this.toast.error("Attenzione", "Mancano i dati degli obiettivi"); return; }
      if (!this.proposalsValid) { this.toast.error("Attenzione", "Mancano alcuni dati nella proposta"); return; }
      if (!this.ratingScaleValid) { this.toast.error("Attenzione", "Le scale di valutazione non sono corrette"); return; }
      if (!this.ratingScaleSelectionValid) { this.toast.error("Attenzione", "Non è stata selezionata nessuna scala di valutazione"); return; }
      if (!this.teamMembersValid) { this.toast.error("Attenzione", "Mancano alcuni dati sui membri del team"); return; }

      console.log("form valid " + form.form.valid);
      if (!form.form.valid) {
        console.log(form.form);

        return;
      }

      console.log("step2");
      console.log('------------- VALID -----------');

      console.log(this.modelStartDecision);

      // Scroll.toTop();
    }
  }


  /**
   * Prepara gli obiettivi sulla base del framework selezionato
   */
  prepareTargets () {

    this.modelStartDecision.targets =  {
      primary: null,
      secondary: []
    };

    //  per ogni target creo l'obiettivo per la proposta
    this.frameworkSelected.targets.forEach(target => {
      let compiledTarget = {
        type: target.type,
        title: target.title,
        measure_unit: null,
        data: null,
        evaluation_criteria: target.evaluation_criteria
      };

      if (compiledTarget.type == 'primary')
        this.modelStartDecision.targets.primary = compiledTarget;
      else
        this.modelStartDecision.targets.secondary.push(compiledTarget);
    });
  }


  /**
   * Setta una proposta sulla base del framework selezionato
   */
  prepareProposal() {

    this.modelStartDecision.proposals = [];
    this.addNewProposal();
  }


  /** Aggiunge una proposal vuota */
  addNewProposal() {

    let newProposal = { title: null, criteria: [] };

    for (let criterio of this.frameworkSelected.criteria) {
      let compiledCriterio = {
        title: criterio.title,
        typology: criterio.typology,
        measure_unit: criterio.measure_unit,
        value: null,
      }
      newProposal.criteria.push(compiledCriterio);
    };

    this.modelStartDecision.proposals.push(newProposal);

    console.log(this.modelStartDecision);
  }



  /** Prepara le scale di valutazione per questo processo decisionale */
  prepareRatingScales() {
    this.modelStartDecision.rating_scales = [];
    this.addNewRatingScale();
  }


  /** Aggiunge una scala di valutazione vuota */
  addNewRatingScale() {
    this.modelStartDecision.rating_scales.push(JSON.parse(JSON.stringify(this.rating_scale_empty)));
  }



  /** Setta un team member vuoto iniziale */
  prepareTeam() {
    this.modelStartDecision.team_members = [];
    this.addNewTeamMember();
  }

  /** Aggiunge un team member vuoto */
  addNewTeamMember () {

    this.modelStartDecision.team_members.push({
      username: null,
      email: null,
      orgnization_professional_role_id: null,
      process_role: null,
      complete_process_enabled: false,
      review_enabled: false,
      provide_new_proposal_enabled: false
    });
  }

  /** Rimuove un determinato utente inserito */
  removeTeamMember(positionToDelete) {

    this.modelStartDecision.team_members.splice(positionToDelete, 1);
    if (this.modelStartDecision.team_members.length == 0)
      this.addNewTeamMember();
  }


  /**
   * Valida i filtri di ricerca dei framework
   */
  get searchValid() {
    if (!this.searchModel.sector_key && !this.searchModel.subsector_key && !this.searchModel.analysis_type)
      return false;
    return true;
  }


  /**
   * Gestione della ricerca di un framework al cambiamento di un parametro
   */
  onChangeSearchFilter() {

    this.frameworkFilteredList = [
      {framework_id: 2, title: "Make or buy strategy ", method: "Multicriteria analysis", sector: "Medical", purpose_description: "Decision-making scheme for strategic choices on the purchase or home production of a good or service (make or buy)", roles: []},
      {framework_id: 3, title: "Supplier evaluation", method: "Consent analysis", sector: "Medical", purpose_description: "Decision scheme for evaluating a supplier according to both qualitative and quantitative criteria.", roles: []},
      {
        framework_id: 1,
        title: "oncology ORL",
        method: "Consent analysis",
        sector: "Medicine",
        purpose_description: "Decision scheme for the multidisciplinary analysis of throat cancer cases and related therapeutic treatment.",
        roles: [],
        targets: [
          { framework_id: 1, type: "primary", title: "sale", evaluation_criteria: "HIGHESTISTHEBEST"},
          { framework_id: 1, type: "secondary", title: "times", evaluation_criteria: "HIGHESTISTHEBEST"}
        ],
        criteria: [
          { framework_id: 1, title: "criteria 1", typology: "QUANTITATIVE", measure_unit: "POINT", evaluation: "HIGHESTISTHEBEST", order: 0},
          { framework_id: 1, title: "criteria 2", typology: "QUALITATIVE", measure_unit: null, evaluation: "LOWESTISTHEBEST", order: 1},
        ]
      },
    ]

    this.searchDone = true;
  }


  /**
   * Gestione dei box dei settori
   */
  getSubsectorsArrayFiltered (sectorKey) {
    return this.workingSectorService.getAllsubsectorsForSelectBySectorKey(sectorKey);
  }


  /**
   * Gestione del click su di un framework
   */
  onClickFramework(framework_index) {
    this.frameworkSelected = JSON.parse(JSON.stringify(this.frameworkFilteredList[framework_index]));

    //  setto l'id del framework selezionato
    if (this.frameworkSelected?.framework_id)
      this.model.framework_id = this.frameworkSelected.framework_id;

    console.log(this.frameworkSelected);
  }



  /** Valida i target del progetto */
  get targetsValid () {

    if (this.modelStartDecision.targets.primary && (!this.modelStartDecision.targets.primary.measure_unit || !this.modelStartDecision.targets.primary.data))
      return false;

    if (this.modelStartDecision.targets.secondary) {
      for (let target of this.modelStartDecision.targets.secondary)
        if (!target.measure_unit || !target.data)
          return false;
    }

    return true;
  }


  /** Valida le proposte create */
  get proposalsValid () {

    for (let proposal of this.modelStartDecision.proposals) {
      if (!proposal.title) return false;

      for (let proposalCriteria of proposal.criteria)
        if (!proposalCriteria.value) return false;
    };

    return true;
  }


  /** Valida le scale di valutazione */
  get ratingScaleValid () {

    if (this.change_rating_scale == false) return true;

    for (let ratingScale of this.modelStartDecision.rating_scales)
      if (!ratingScale.description || !ratingScale.max_value || !ratingScale.min_value || !ratingScale.step)
        return false;

    return true;
  }


  /** Valida le scale in base a se ne esiste almeno una selezionata */
  get ratingScaleSelectionValid () {

    if (this.change_rating_scale == false) return true;

    for (let ratingScale of this.modelStartDecision.rating_scales)
      if (ratingScale.selected == true)
        return true;

    return false;
  }


  /** Valida il team inserito */
  get teamMembersValid () {

    for (let team_member of this.modelStartDecision.team_members)
      if (!team_member.username || !team_member.email)
        return false

    return true;
  }



  public getAnalysisMethodsService () {
    return this.analysisMethodsService;
  }
  public getWorkingSectorService() {
    return this.workingSectorService;
  }
  public getUnitsService() {
    return this.unitsService;
  }
  public getCriteriaService() {
    return this.criteriaService;
  }

}
