



<div class="container-fluid box-sub-header py-3 pt-lg-5">
    <div class="container">
        <mat-form-field class="col-12 col-lg-6 select-custom-button" appearance="fill">
            <mat-label>Organizzazione</mat-label>
            <mat-select name="organizzation">
                <mat-option value="Nome organizzazione" selected>Nome organizzazione</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="col-12 col-lg-6 datepicker-custom-button" appearance="fill">
            <mat-label>Data di consegna</mat-label>
            <input matInput [matDatepicker]="dp">
            <mat-datepicker-toggle matSuffix [for]="dp">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #dp></mat-datepicker>
        </mat-form-field>
    </div>
</div>

<!-- Blocco 2 -->
<div class="container-fluid accordion-container px-0 px-md-3">
    <div class="container">
        <mat-accordion class="accordion" multi>
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="index">2</span>
                        Valutazione
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">

                    <div class="col-12 col-lg-6 px-0 mb-0">
                        <div class="box py-0 px-2 bg-transparent border-0 mb-0">
                            <mat-label class="box-title px-3 mb-0">Criteri rispetto agli obiettivi secondari</mat-label>
                        </div>
                    </div>

                    <div class="container-fluid accordion-container mt-3 accordion-inner rounded-0 bg-white arrow-left">
                        <div class="container">
                            <mat-accordion class="accordion" multi [togglePosition]="'before'">
                                <mat-expansion-panel class="px-0" expanded>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <span>Rispetto ai tempi</span>
                                            <i class="icon-circle_info d-flex"></i>
                                            <i class="icon-checkmark abs-tr green-color mx-0"></i>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <mat-form-field class="select-primary col-12 col-md-6 mb-2" appearance="fill">
                                            <mat-label>Tipologia del dato</mat-label>
                                            <mat-select>
                                                <mat-option value="" selected></mat-option>
                                                <mat-option value="test01">test 01</mat-option>
                                                <mat-option value="test02">test 02</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field class="select-primary col-12 col-md-6 mb-2" appearance="fill">
                                            <mat-label>Unità</mat-label>
                                            <mat-select>
                                                <mat-option value="" selected></mat-option>
                                                <mat-option value="test01">test 01</mat-option>
                                                <mat-option value="test02">test 02</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field class="input-primary col-12 mb-2 px-4">
                                            <input matInput placeholder="Valore">
                                        </mat-form-field>
                                    </div>

                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>

                    <div class="container-fluid accordion-container mt-3 accordion-inner rounded-0 bg-white arrow-left required">
                        <div class="container">
                            <mat-accordion class="accordion" multi [togglePosition]="'before'">
                                <mat-expansion-panel class="px-0">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <span>Miglior costo</span>
                                            <i class="icon-circle_info d-flex"></i>
                                            <i class="icon-checkmark abs-tr orange-color mx-0"></i>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <mat-form-field class="select-primary col-12 col-md-6 mb-2" appearance="fill">
                                            <mat-label>Tipologia del dato</mat-label>
                                            <mat-select>
                                                <mat-option value="" selected></mat-option>
                                                <mat-option value="test01">test 01</mat-option>
                                                <mat-option value="test02">test 02</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field class="select-primary col-12 col-md-6 mb-2" appearance="fill">
                                            <mat-label>Unità</mat-label>
                                            <mat-select>
                                                <mat-option value="" selected></mat-option>
                                                <mat-option value="test01">test 01</mat-option>
                                                <mat-option value="test02">test 02</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field class="input-primary col-12 mb-2 px-4">
                                            <input matInput placeholder="Valore">
                                        </mat-form-field>
                                    </div>

                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>

                    <div class="box-info mt-3 mb-1 position-relative container">
                        <p class="description col-12">
                            <span class="title">
                                <i class="icon-circle_Warning float-left flex-column black-color mr-2"></i>
                                Suggerimento
                            </span>
                            Dato che è stato modificato l’obiettivo rispetto tempi è stato modificato anche l’obiettivo correlato rispetto all’equazione Criterio B = Criterio A *2
                        </p>
                        <i class="icon-circle_info small-icon abs-tr"></i>
                    </div>

                    <div class="container-fluid accordion-container mt-3 accordion-inner rounded-0 bg-white arrow-left">
                        <div class="container">
                            <mat-accordion class="accordion" multi [togglePosition]="'before'">
                                <mat-expansion-panel class="px-0">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <span>Migliore qualità</span>
                                            <i class="icon-circle_info d-flex"></i>
                                            <i class="icon-checkmark abs-tr green-color mx-0"></i>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <mat-form-field class="select-primary col-12 col-md-6 mb-2" appearance="fill">
                                            <mat-label>Tipologia del dato</mat-label>
                                            <mat-select>
                                                <mat-option value="" selected></mat-option>
                                                <mat-option value="test01">test 01</mat-option>
                                                <mat-option value="test02">test 02</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field class="select-primary col-12 col-md-6 mb-2" appearance="fill">
                                            <mat-label>Unità</mat-label>
                                            <mat-select>
                                                <mat-option value="" selected></mat-option>
                                                <mat-option value="test01">test 01</mat-option>
                                                <mat-option value="test02">test 02</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field class="input-primary col-12 mb-2 px-4">
                                            <input matInput placeholder="Valore">
                                        </mat-form-field>
                                    </div>

                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>

                    <div class="container-fluid accordion-container mt-3 accordion-inner rounded-0 bg-white arrow-left required">
                        <div class="container">
                            <mat-accordion class="accordion" multi [togglePosition]="'before'">
                                <mat-expansion-panel class="px-0">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <span>Obiettivo secondario</span>
                                            <i class="icon-circle_info d-flex"></i>
                                            <i class="icon-checkmark abs-tr orange-color mx-0"></i>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <mat-form-field class="select-primary col-12 col-md-6 mb-2" appearance="fill">
                                            <mat-label>Tipologia del dato</mat-label>
                                            <mat-select>
                                                <mat-option value="" selected></mat-option>
                                                <mat-option value="test01">test 01</mat-option>
                                                <mat-option value="test02">test 02</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field class="select-primary col-12 col-md-6 mb-2" appearance="fill">
                                            <mat-label>Unità</mat-label>
                                            <mat-select>
                                                <mat-option value="" selected></mat-option>
                                                <mat-option value="test01">test 01</mat-option>
                                                <mat-option value="test02">test 02</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field class="input-primary col-12 mb-2 px-4">
                                            <input matInput placeholder="Valore">
                                        </mat-form-field>
                                    </div>

                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>

                    <div class="box-info mt-3 mb-1 position-relative container">
                        <p class="description col-12">
                            <span class="title">
                                <i class="icon-circle_Warning float-left flex-column black-color mr-2"></i>
                                Tip
                            </span>
                            Description
                        </p>
                        <i class="icon-circle_info small-icon abs-tr"></i>
                    </div>

                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>

<div class="container-fluid mt-4">
    <div class="row justify-content-center w-100 mt-0 mb-3 mx-auto">
        <button class="px-5" mat-flat-button color="primary">Salva in bozze</button>
    </div>
</div>

<!-- Buttons -->
<div class="buttons-container container py-4">
    <button class="button-primary float-left" mat-button>
        <i class="icon-Backward-arrow-small"></i>
        Go Back
    </button>
    <button class="button-primary float-right" mat-button>
        <i class="icon-Forward-arrow-small"></i>
        Save and continue
    </button>
</div>
