<!-- Blocco 1 -->
<div class="container-fluid accordion-container">
  <div class="container">
    <mat-accordion class="accordion" multi>

      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">1 </span>
            Assigned tasks
          </mat-panel-title>
        </mat-expansion-panel-header>

        <!-- Pulsante di aggiunta Task -->
        <div class="container p-4">
          <div class="row">
            <button class="col-12 big-button" mat-raised-button (click)="newTask()">
              <i class="icon-big_add"></i>
              Add new task
            </button>
          </div>
        </div>

        <ng-container *ngFor="let task of uncompletedTasks; let i = index">
          <div class="row">
            <div class="col-12 px-2">
              <div class="box px-0 box-two-row py-3" [class]="task.editMode == true ? 'border-modify': ''">
                <mat-label class="box-title px-3" *ngIf="!task.editMode">{{task.additional_text}}</mat-label>

                <mat-form-field class="pb-2 select-primary col-12" appearance="fill" *ngIf="task.editMode">
                  <mat-label>{{'Level' | translate}}</mat-label>
                  <mat-select [(ngModel)]="task.additional_text" required>
                    <mat-option [value]="'level project'">Level Project</mat-option>
                    <mat-option [value]="'level portfolio'">Level Portfolio</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="m-2 input-primary col-12">
                  <mat-label>Action</mat-label>
                  <textarea matInput [(ngModel)]="task.action" [readonly]="!task.editMode" required></textarea>
                </mat-form-field>
                <mat-form-field class="datepicker-primary col-4" appearance="fill">
                  <mat-label>End date</mat-label>
                  <input matInput [matDatepicker]="end_date" [readonly]="!task.editMode" [(ngModel)]="task.end_date" class="color-default ng-trim-ignore" disabled>
                  <mat-datepicker-toggle matSuffix [for]="end_date">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #end_date [disabled]="!task.editMode"></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="ml-4 input-primary col-4">
                  <input matInput placeholder="Responsible" [(ngModel)]="task.assigned_user_name" [readonly]="!task.editMode">
                </mat-form-field>

                <!-- <mat-checkbox class="abs-tr" name="check_completed_{{i}}" [checked]="task.has_completed == true" [disabled]="true" ></mat-checkbox> -->

                <div class="row mx-auto flex-row-reverse" *ngIf="!task.editMode">
                  <button mat-icon-button class="mr-2" color="warn" [disableRipple]="true"  matTooltip="Delete" [matTooltipPosition]="'above'" (click)="deleteTask(task)"><mat-icon class="red-color">delete_forever</mat-icon></button>
                <button mat-icon-button [disableRipple]="true" matTooltip="Edit" [matTooltipPosition]="'above'" (click)="toggleEditMode(task)"><i class="icon-edit main-color"></i></button>
                  <button *ngIf="task.has_completed == true" mat-stroked-button class="rounded-pill border-success text-success">
                    Completed
                  </button>
                  <button *ngIf="task.has_completed == false" mat-stroked-button class="rounded-pill" (click)="setCompleted(task)">
                    Mark as completed
                  </button>
                </div>
                <div class="row mx-auto flex-row-reverse" *ngIf="task.editMode">
                  <button *ngIf="task.id && !task.new && isValid(task)" mat-flat-button color="primary" class="mr-2" (click)="saveTask(task)">Update</button>
                  <button *ngIf="isValid(task) && (!task.id || task.new)" mat-flat-button color="primary" class="mr-2" (click)="saveTask(task, true)">Save</button>
                  <button *ngIf="task.new" mat-flat-button color="primary" class="mr-2" (click)="deleteTask(task)">Delete</button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </mat-expansion-panel>

    </mat-accordion>
  </div>
</div>

<!-- Blocco 2 -->
<div class="container-fluid accordion-container">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">2 </span>
            Completed tasks
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-container *ngFor="let task of completedTasks; let i = index">
          <div class="row">
            <div class="col-12 px-2">
              <div class="box px-0 box-two-row py-3">
                <mat-label class="box-title px-3">{{task.additional_text}}</mat-label>
                <mat-form-field class="m-2 input-primary col-12">
                  <mat-label>Action</mat-label>
                  <textarea matInput [ngModel]="task.action" readonly></textarea>
                </mat-form-field>
                <mat-form-field class="datepicker-primary col-4" appearance="fill">
                  <mat-label>End date</mat-label>
                  <input matInput [matDatepicker]="end_date" [readonly]="true" [ngModel]="task.end_date" class="color-default ng-trim-ignore" disabled>
                  <mat-datepicker-toggle matSuffix [for]="end_date">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #end_date [disabled]="true"></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="ml-4 input-primary col-3">
                  <input matInput placeholder="Responsible" [ngModel]="task.assigned_user_name" readonly>
                </mat-form-field>

                <!-- <mat-checkbox class="abs-tr" name="check_completed_{{i}}" [checked]="true" [disabled]="true" ></mat-checkbox> -->

                <div class="row mx-auto flex-row-reverse">
                  <button mat-icon-button class="mr-2" color="warn" [disableRipple]="true" (click)="deleteTask(task)" matTooltip="Delete" [matTooltipPosition]="'above'" ><fa-icon [icon]="faTrash"></fa-icon></button>
                  <button mat-stroked-button class="rounded-pill border-success text-success">
                    Completato
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>


<!-- Buttons -->
<div class="buttons-container container py-4">
  <button class="button-primary float-left" mat-button [disabled]="loading" [routerLink]="['../show']">
    <i class="icon-Backward-arrow-small"></i>
    {{'generic.buttons.go-back' | translate}}
  </button>
</div>
