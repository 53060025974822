<form (keydown.enter)="$event.preventDefault()" novalidate>
  <div class="container-fluid box-sub-header">
    <div class="container">
      <mat-form-field class="col-12 input-big">
        <input matInput name="framework_name" placeholder="Enter the framework name" [(ngModel)]="model.framework_name" required>
      </mat-form-field>
      <mat-form-field class="col-6 col-sm-auto col-lg-3 select-custom-button" appearance="fill">
        <mat-label>Organization</mat-label>
        <mat-select name="organizzation" [(ngModel)]="model.organization_id" required>
          <mat-option value="" selected></mat-option>
          <mat-option *ngFor="let organizzation of userOrganizzation" [value]="organizzation.id">{{organizzation.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <mat-form-field class="col-6 col-sm-auto col-lg-3 datepicker-custom-button" appearance="fill">
        <mat-label>Data di consegna</mat-label>
        <input matInput [matDatepicker]="dp">
        <mat-datepicker-toggle matSuffix [for]="dp">
          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #dp></mat-datepicker>
      </mat-form-field> -->

    </div>
  </div>


  <!-- Blocco 1 -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index">1</span>
              Analysis methodology
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <mat-radio-group class="col-12 mat-radio-box px-0" aria-label="Select an option">
              <mat-grid-list cols="12" rowHeight="100px">
                <ng-container *ngFor="let method of analysisMethods; let i = index">
                  <mat-grid-tile colspan="6" (click)="analysisOnClick(i)">
                    <mat-radio-button class="col-12" name="analysis{{i}}" [value]="false" [checked]="method.enabled">{{method.title}}</mat-radio-button>
                    <!-- <mat-slide-toggle class="toggle" [checked]="method.enabled"></mat-slide-toggle> -->
                  </mat-grid-tile>
                  <mat-grid-tile colspan="5">
                    <div class="description-container">
                      <!-- {{method.description}} -->
                    </div>
                  </mat-grid-tile>
                  <mat-grid-tile class="icon-info-custom" colspan="1">
                    <i #tooltip="matTooltip" matTooltip="{{method.tipDescription}}" matTooltipPosition="above" matTooltipHideDelay="10000">i</i>
                  </mat-grid-tile>
                </ng-container>
              </mat-grid-list>
            </mat-radio-group>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>


  <!-- Blocco 2 -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index">2</span>
              Sector
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row settore">

            <div class="col-12 box" *ngFor="let sector of model.sectors; let i = index">
              <ng-container>
                <mat-form-field class="select-primary" appearance="fill">
                  <mat-label>Sector</mat-label>
                  <mat-select #sectorInput name="sector{{i}}" [(ngModel)]="sector.sector_key" required>
                    <mat-option value="" selected></mat-option>
                    <mat-option *ngFor="let sector of getWorkingSectorService().getAllSectorsForSelect()" [value]=sector.key>{{sector.value}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="select-primary ml-3 col-6" appearance="fill">
                  <mat-label>SubSector</mat-label>
                  <mat-select name="subsector{{i}}" [(ngModel)]="sector.subsector_key" [disabled]="!sectorInput.value">
                    <mat-option value="" selected></mat-option>
                    <mat-option *ngFor="let subsector of getSubsectorsArrayFiltered(sectorInput.value)" [value]="subsector.key">{{subsector.value}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="icons-container" (click)="removeSector(i)">
                  <i class="icon-cestino grey-color float-right"></i>
                </div>
              </ng-container>
            </div>

            <button type="button" class="col-12 big-button" mat-raised-button (click)="addSector()">
              <i class="icon-big_add"></i>
              Add sector
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>


  <!-- Blocco 3 -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index">3</span>
              Purpose of the Framework
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-12 box flex-column my-0 pb-0 pt-3 px-3">
              <mat-form-field>
                <mat-label>Description</mat-label>
                <textarea class="description" matInput rows="5" name="purpose_description" [(ngModel)]="model.purpose_description" placeholder="Inserisci lo scopo di questo framework" required></textarea>
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>


  <!-- Blocco 4 -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index">4</span>
              Targets
            </mat-panel-title>
          </mat-expansion-panel-header>
            <div class="row">
              <div class="col-12 px-2">
                <div class="box px-0">
                  <mat-form-field class="input-primary col-8 pt-2">
                    <input matInput name="primary_target" [(ngModel)]="model.primary_target.title" placeholder="Enter primary target" required>
                  </mat-form-field>
                  <mat-form-field class="select-primary col-4" appearance="fill">
                    <mat-label>Evaluation criterion</mat-label>
                    <mat-select name="primary_target_evaluation" [(ngModel)]="model.primary_target.evaluation_criteria" required>
                      <mat-option value="" selected></mat-option>
                      <mat-option *ngFor="let criterion of getCriteriaService().getAllCriteriaForSelect()" [value]="criterion.key">{{criterion.value}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="col-6 px-2" *ngFor="let target of model.secondary_targets; let i = index">
                <ng-container>
                  <div class="box px-0">
                    <mat-form-field class="input-primary col-7 pt-2">
                      <input matInput name="secondary_targets{{i}}" [(ngModel)]="target.title" placeholder="Inserisci obiettivo secondario" required>
                    </mat-form-field>
                    <mat-form-field class="select-primary col-5" appearance="fill">
                      <mat-label>Evaluation criterion</mat-label>
                      <mat-select name="secondary_target_evaluation{{i}}" [(ngModel)]="target.evaluation_criteria" required>
                        <mat-option value="" selected></mat-option>
                        <mat-option *ngFor="let criterion of getCriteriaService().getAllCriteriaForSelect()" [value]="criterion.key">{{criterion.value}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <i class="icon-cestino grey-color right-bottom" (click)="removeSecondaryTarget(i)"></i>
                  </div>
                </ng-container>
              </div>

              <div class="col-6 px-2">
                <div type="button" class="box-button" (click)="addSecondaryTarget()">
                  <p class="my-0">
                    <i class="icon-Add mx-2"></i>
                    Add secondary target
                  </p>
                </div>
              </div>
            </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>


  <!-- Blocco 5 -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <!-- TODO_ALESSANDRO: in seguito al cambiamento della tavola non è pià possibile vedere il pulsante di cancellazione
                              per risolvere ho dovuto sovrascrivere l'overflow-->
        <mat-expansion-panel expanded style="overflow: visible;">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index">5</span>
              Criteria
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="container px-0">
            <div class="row">
              <div class="col-12 box" *ngFor="let criterio of model.criteria; let i = index">
                <ng-container>
                  <div class="col-auto box-index">
                    {{i + 1}}
                  </div>
                  <mat-form-field class="input-primary col-3 pt-2">
                    <input matInput name="criteria_description{{i}}" [(ngModel)]="criterio.title" placeholder="Description" required>
                  </mat-form-field>
                  <mat-form-field class="select-primary col-3 px-0" appearance="fill">
                    <mat-label>Criterion of the data</mat-label>
                    <mat-select name="criteria_typology{{i}}" [(ngModel)]="criterio.typology" required>
                      <mat-option value="" selected></mat-option>
                      <mat-option *ngFor="let criteriaType of getCriteriaService().getAllCriteriaTypeForSelect()" [value]="criteriaType.key">{{criteriaType.value}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field *ngIf="getCriteriaService().criteriaIsQuantitative(criterio.typology)" class="select-primary col-3 px-0" appearance="fill">
                    <mat-label>Unit of measure</mat-label>
                    <mat-select name="criteria_unit{{i}}" [(ngModel)]="criterio.measure_unit" required>
                      <mat-option value="" selected></mat-option>
                      <mat-option *ngFor="let unit of getUnitsService().getAllUnitsForSelect()" [value]="unit.key">{{unit.value}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field *ngIf="getCriteriaService().criteriaIsQuantitative(criterio.typology)" class="select-primary col-3 px-0" appearance="fill">
                    <mat-label>Classification criterion</mat-label>
                    <mat-select name="criteria_evaluation{{i}}" [(ngModel)]="criterio.evaluation" required>
                      <mat-option value="" selected></mat-option>
                      <mat-option *ngFor="let criterion of getCriteriaService().getAllCriteriaForSelect()" [value]="criterion.key">{{criterion.value}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="icons-container">
                    <i class="icon-cestino grey-color float-right" (click)="removeCriteria(i)"></i>
                  </div>
                </ng-container>
              </div>
              <button type="button" class="col-12 big-button" mat-raised-button (click)="addCriteria()">
                <i class="icon-big_add"></i>
                Add criteria
              </button>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>


  <!-- Blocco 6 -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index">6</span>
              Constraint assignment
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="container px-0">

              <!-- TODO_MICHELE: testo da farci dire da Enzo -->
              <!-- <div class="box-info mb-4">
                <i class="icon-warning-triangle float-left col-2 flex-column big-icon"></i>
                <p class="description col-10">Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              </div> -->

              <div class="row mx-0 row-vincolo" *ngFor="let constraint of model.constraints; let i = index">
                <ng-container>
                  <mat-form-field class="col-6 col-lg-3 select-custom-button" appearance="fill">
                    <mat-label class="purple">Binding criteria</mat-label>
                    <mat-select name="constraint.criteria_1_order{{i}}" [(ngModel)]="constraint.criteria_1_order" required>
                      <mat-option value="" selected></mat-option>
                      <mat-option *ngFor="let criterio of model.criteria; let j = index" [value]="j">{{j+1}}</mat-option>
                    </mat-select>
                    <span class="label-bottom" *ngIf="model.criteria[constraint.criteria_1_order]">{{getCriteriaService().getCriteriaTypeValueByKey(model.criteria[constraint.criteria_1_order].typology)}}</span>
                  </mat-form-field>
                  <mat-form-field class="col-6 col-lg-3 select-custom-button" appearance="fill">
                    <mat-label class="purple">Constrained criteria</mat-label>
                    <mat-select name="constraint.criteria_2_order{{i}}" [(ngModel)]="constraint.criteria_2_order" required>
                      <mat-option value="" selected></mat-option>
                      <mat-option *ngFor="let criterio of model.criteria let z = index" [value]="z">{{z+1}}</mat-option>
                    </mat-select>
                    <span class="label-bottom" *ngIf="model.criteria[constraint.criteria_2_order]">{{getCriteriaService().getCriteriaTypeValueByKey(model.criteria[constraint.criteria_2_order].typology)}}</span>
                  </mat-form-field>
                  <div class="col-11 col-lg-5 box flex-column pb-0 pt-3 px-3">
                    <mat-form-field>
                      <mat-label>Description</mat-label>
                      <textarea class="description" matInput rows="3" name="constraint.description{{i}}" [(ngModel)]="constraint.description" placeholder="Spiegare perché i vincoli sono correlati ed inserire la formula della modifica del vincolo se necessaria" required></textarea>
                    </mat-form-field>
                  </div>
                  <div class="col-1 icons-container">
                    <i class="icon-cestino grey-color"></i>
                  </div>
                </ng-container>
              </div>

              <button type="button" class="col-12 big-button" [disabled]="model.criteria.length < 2" mat-raised-button (click)="addConstraint()">
                <i class="icon-big_add"></i>
                Add constraints
              </button>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>


  <!-- Blocco 7 -->
  <div class="accordion-container container-fluid px-0">
    <div class="container-fluid px-0">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header class="container">
            <mat-panel-title>
              <span class="index">7</span>
              Team
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row py-4 two-radio-box mt-0">
            <div class="container">
              <p class="title">Do you want to enter the roles that will have to participate in this type of decision?</p>
              <mat-radio-group class="col-12 mat-radio-box px-0" aria-label="Select an option" name="option_role" [(ngModel)]="model.options_role">
                <mat-grid-list cols="12" rowHeight="100px">
                  <ng-container>
                    <mat-grid-tile colspan="6" (click)="optionRoleOnClick(true)">
                      <mat-radio-button [value]="true" class="col-12">Yes</mat-radio-button>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="6" (click)="optionRoleOnClick(false)">
                      <mat-radio-button [value]="false" class="col-12">No</mat-radio-button>
                    </mat-grid-tile>
                  </ng-container>
                </mat-grid-list>
              </mat-radio-group>
            </div>
          </div>

          <div class="row py-4 two-radio-box mt-0" *ngIf="model.options_role">
            <div class="container">
              <p class="title">Do you want the roles entered to be mandatory or just a suggestion?</p>
              <mat-radio-group class="col-12 mat-radio-box px-0" aria-label="Select an option" name="option_role_required" [(ngModel)]="model.options_role_required">
                <mat-grid-list cols="12" rowHeight="100px">
                  <ng-container>
                    <mat-grid-tile colspan="6">
                      <mat-radio-button [value]="true" (click)="optionRoleRequiredOnClick(true)" class="col-12">Yes</mat-radio-button>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="6">
                      <mat-radio-button [value]="false" (click)="optionRoleRequiredOnClick(false)" class="col-12">No</mat-radio-button>
                    </mat-grid-tile>
                  </ng-container>
                </mat-grid-list>
              </mat-radio-group>
            </div>
          </div>

          <div class="container py-4" *ngIf="question_role_satisfied">

            <div class="col-12 box box-two-row" *ngFor="let role of model.roles; let i = index">
              <ng-container>
                <div class="row">
                  <mat-form-field class="select-primary col-5 col-lg-3 px-1" appearance="fill">
                    <mat-label>Professional role</mat-label>
                    <mat-select #teamProfessionalInput name="professional_role{{i}}" [(ngModel)]="role.orgnization_professional_role_id">
                      <mat-option value="" selected></mat-option>
                      <mat-option *ngFor="let professionalRole of professionalRoles" [value]=professionalRole.id>{{professionalRole.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="select-primary col-5 col-lg-3 px-1" appearance="fill">
                    <mat-label>Role in the process</mat-label>
                    <mat-select name="process_role{{i}}" [(ngModel)]="role.process_role">
                      <mat-option value="" selected></mat-option>
                      <mat-option *ngFor="let processRole of process_roles" [value]="processRole.key">{{processRole.title}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="row">
                  <div class="toggle-container col-4" appearance="fill">
                    <mat-label>Enabled for full assessment</mat-label>
                    <div>
                      <span class="mr-2">No</span>
                      <mat-slide-toggle class="toggle" name="complete_process_enabled{{i}}" [(ngModel)]="role.complete_process_enabled" >Yes</mat-slide-toggle>
                    </div>
                  </div>
                  <div class="toggle-container col-3" appearance="fill">
                    <mat-label>Authorized to Review</mat-label>
                    <div>
                      <span class="mr-2">No</span>
                      <mat-slide-toggle class="toggle" name="review_enabled{{i}}" [(ngModel)]="role.review_enabled" >Yes</mat-slide-toggle>
                    </div>
                  </div>
                  <div class="toggle-container col-4" appearance="fill">
                    <mat-label>Enabled to provide new proposals</mat-label>
                    <div>
                      <span class="mr-2">No</span>
                      <mat-slide-toggle class="toggle" name="provide_new_proposal_enabled{{i}}" [(ngModel)]="role.provide_new_proposal_enabled" >Yes</mat-slide-toggle>
                    </div>
                  </div>
                </div>

                <div class="icons-container" (click)="removeRole(i)">
                  <i class="icon-cestino grey-color float-right"></i>
                </div>
              </ng-container>
            </div>

            <button type="button" class="col-12 big-button" mat-raised-button (click)="addRole()">
              <i class="icon-Add"></i>
              Add role
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <div class="buttons-container container py-4">
    <button type="button" class="button-primary float-left" mat-button>
      <i class="icon-Backward-arrow-small"></i>
      Back
    </button>
    <button type="submit" class="button-primary float-right" mat-button (click)="onSubmit()">
      <i class="icon-Forward-arrow-small"></i>
      Save
    </button>
  </div>
</form>
