import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-modal-network-analisys',
  templateUrl: './modal-network-analisys.component.html',
  styleUrls: ['./modal-network-analisys.component.scss']
})
export class ModalNetworkAnalisysComponent implements OnInit {

  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: {}) { }

  loading = false;
  imgSrc = null;

  ngOnInit(): void {
    this.imgSrc = '../../../assets/img/network-analisys-' + this.data + '.jpg';
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

  closeDialogue() {
    this.dialog.closeAll();
  }

}
