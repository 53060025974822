import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import {filter, map, mergeMap} from 'rxjs/operators';
import { HeaderTitleService } from 'src/app/services/header-title.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private headerTitleService: HeaderTitleService
  ) {}

  pageTitle: string = "";

  ngOnInit(): void {

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
      .subscribe((event) => {
        console.log(event['title']);

        this.headerTitleService.setTitle(event['title']);
      });

    //  aggiorna il titolo della pagina
    this.headerTitleService.title.subscribe(updatedTitle => {
      this.pageTitle = updatedTitle;
    });
  }
}
