<div class="container-fluid box-sub-header">
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-6 px-2">
          <div class="box">
            <span> <i class="icon-Add"></i> &nbsp; Create a Decision Management Plan</span>
          </div>
        </div>
        <div class="col-6 px-2">
          <div class="box">
            <span> <i class="icon-Add"></i> &nbsp; Manage DMPs</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 px-2">
          <div class="box" [routerLink]="['/decision']" routerLinkActive="router-link-active">
            <span> <i class="icon-Add"></i> &nbsp; New decision-making</span>
          </div>
        </div>
        <div class="col-6 px-2">
          <div class="box" [routerLink]="['/decision/2/show']">
            <span> <i class="icon-Add"></i> &nbsp; Manage a decision</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 px-2">
          <div class="box" [routerLink]="['/framework']">
            <span> <i class="icon-Add"></i> &nbsp; Create a decision framework</span>
          </div>
        </div>
        <div class="col-6 px-2">
          <div class="box">
            <span> <i class="icon-Add"></i> &nbsp; Manage decision schemas</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 px-2">
          <div class="box">
            <span> <i class="icon-Add"></i> &nbsp; Manage organizations</span>
          </div>
        </div>
        <div class="col-6 px-2">
          <div class="box">
            <span> <i class="icon-Add"></i> &nbsp; Task management</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 px-2">
          <div class="box">
            <span> <i class="icon-Add"></i> &nbsp; Learn more about Butterfly</span>
          </div>
        </div>
        <div class="col-6 px-2">
          <div class="box">
            <span> <i class="icon-Add"></i> &nbsp; Training path</span>
          </div>
        </div>
      </div>

  </div>
</div>

<!-- <ng-container>
  <div class="col-auto box-index">
    {{i + 1}}
  </div>
  <mat-form-field class="input-primary col-3 pt-2">
    <input matInput name="criteria_description{{i}}" [(ngModel)]="criterio.title" placeholder="Description" required>
  </mat-form-field>
  <mat-form-field class="select-primary col-3 px-0" appearance="fill">
    <mat-label>Criterio del dato</mat-label>
    <mat-select name="criteria_typology{{i}}" [(ngModel)]="criterio.typology" required>
      <mat-option value="" selected></mat-option>
      <mat-option *ngFor="let criteriaType of getCriteriaService().getAllCriteriaTypeForSelect()" [value]="criteriaType.key">{{criteriaType.value}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="getCriteriaService().criteriaIsQuantitative(criterio.typology)" class="select-primary col-3 px-0" appearance="fill">
    <mat-label>Unità di misura</mat-label>
    <mat-select name="criteria_unit{{i}}" [(ngModel)]="criterio.measure_unit" required>
      <mat-option value="" selected></mat-option>
      <mat-option *ngFor="let unit of getUnitsService().getAllUnitsForSelect()" [value]="unit.key">{{unit.value}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="select-primary col-3 px-0" appearance="fill">
    <mat-label>Criterio di classificazione</mat-label>
    <mat-select name="criteria_evaluation{{i}}" [(ngModel)]="criterio.evaluation" required>
      <mat-option value="" selected></mat-option>
      <mat-option *ngFor="let criterion of getCriteriaService().getAllCriteriaForSelect()" [value]="criterion.key">{{criterion.value}}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="icons-container">
    <i class="icon-cestino grey-color float-right" (click)="removeCriteria(i)"></i>
  </div>
</ng-container> -->
