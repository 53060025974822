<div class="container-fluid box-sub-header py-3 pt-lg-5">
    <div class="container">
        <mat-form-field class="col-12 col-lg-6 select-custom-button" appearance="fill">
            <mat-label>Organizzazione</mat-label>
            <mat-select name="organizzation">
                <mat-option value="Nome organizzazione" selected>Nome organizzazione</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="col-12 col-lg-6 datepicker-custom-button" appearance="fill">
            <mat-label>Data di consegna</mat-label>
            <input matInput [matDatepicker]="dp">
            <mat-datepicker-toggle matSuffix [for]="dp">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #dp></mat-datepicker>
        </mat-form-field>
    </div>
</div>

<!-- Blocco 1 -->
<div class="container-fluid accordion-container px-0 px-md-3">
    <div class="container">
        <mat-accordion class="accordion" multi>
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="index">1</span>
                        Targets
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">


                    <div class="col-12 px-2">
                        <div class="box px-0 box-two-row border-0 bg-transparent">
                            <mat-label class="box-title px-3">Primari</mat-label>
                            <mat-form-field class="input-primary col-12 col-lg-6">
                                <input matInput placeholder="Titolo">
                            </mat-form-field>
                            <mat-form-field class="datepicker-primary col-7 col-lg-6 px-1" appearance="fill">
                                <mat-label>Data</mat-label>
                                <input matInput [matDatepicker]="dp_primary" (focus)="dp_primary.open()">
                                <mat-datepicker-toggle matSuffix [for]="dp_primary">
                                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #dp_primary></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field class="select-primary col-5 col-lg-6 px-1" appearance="fill">
                                <mat-label>Unità</mat-label>
                                <mat-select>
                                    <mat-option value="" selected></mat-option>
                                    <mat-option value="test02">Tempo</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="select-primary col-12 col-lg-6 px-1 mt-2" appearance="fill">
                                <mat-label>Valore Obiettivo</mat-label>
                                <mat-select>
                                    <mat-option value="" selected></mat-option>
                                    <mat-option value="test02">Il più basso</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-12 col-lg-6 px-0 mb-0">
                        <div class="box py-0 px-2 bg-transparent border-0 mb-0">
                            <mat-label class="box-title px-3 mb-0">Secondari</mat-label>
                        </div>
                    </div>

                    <div class="container-fluid accordion-container mt-3 accordion-inner rounded-0 bg-white arrow-left">
                        <div class="container">
                            <mat-accordion class="accordion" multi [togglePosition]="'before'">
                                <mat-expansion-panel class="px-0" expanded>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <span>Rispetto ai tempi</span>
                                            <i class="icon-circle_info d-flex"></i>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <mat-form-field class="datepicker-primary col-7 col-lg-6 px-1" appearance="fill">
                                        <mat-label>Data</mat-label>
                                        <input matInput [matDatepicker]="dp_secondary" (focus)="dp_secondary.open()">
                                        <mat-datepicker-toggle matSuffix [for]="dp_secondary">
                                            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #dp_secondary></mat-datepicker>
                                    </mat-form-field>

                                    <mat-form-field class="select-primary col-5 col-lg-6 px-1" appearance="fill">
                                        <mat-label>Unità</mat-label>
                                        <mat-select>
                                            <mat-option value="" selected></mat-option>
                                            <mat-option value="test02">Tempo</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field class="select-primary col-12 col-lg-6 px-1 mt-2" appearance="fill">
                                        <mat-label>Valore Obiettivo</mat-label>
                                        <mat-select>
                                            <mat-option value="" selected></mat-option>
                                            <mat-option value="test02">Il più basso</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field class="select-primary col-12 col-lg-6 px-1 mt-2" appearance="fill">
                                        <mat-label>Valore assegnato</mat-label>
                                        <mat-select>
                                            <mat-option value="" selected></mat-option>
                                            <mat-option value="test02">3</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>

                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>


<!-- Blocco 2 -->
<div class="container-fluid accordion-container px-0 px-md-3">
    <div class="container">
        <mat-accordion class="accordion" multi>
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="index">2</span>
                        Valutazioni
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">

                    <div class="col-12 col-lg-6 px-0 mb-0">
                        <div class="box py-0 px-2 bg-transparent border-0 mb-0">
                            <mat-label class="box-title px-3 mb-0">Criteri</mat-label>
                        </div>
                    </div>

                    <div class="container-fluid accordion-container mt-3 accordion-inner rounded-0 bg-white arrow-left">
                        <div class="container">
                            <mat-accordion class="accordion" multi [togglePosition]="'before'">
                                <mat-expansion-panel class="px-0" expanded>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <span>Storicità del rapposto con l'azienda</span>
                                            <i class="icon-circle_info d-flex"></i>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div class="row">
                                        <small class="col-12">Valutazioni</small>
                                        <div class="col-10 my-1">Storicità del rapporto con l’azienda</div>
                                        <div class="col-2">3</div>
                                        <div class="col-10 my-1">Valore dell’offerta</div>
                                        <div class="col-2">3</div>
                                        <div class="col-10 my-1">Valutazione del controllo della qualità</div>
                                        <div class="col-2">3</div>
                                        <div class="col-10 my-1">Descrizione aziendale</div>
                                        <div class="col-2">3</div>
                                        <div class="col-10 my-1">Stima dei tempi di consegna</div>
                                        <div class="col-2">3</div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>

                    <div class="col-12 px-2">
                        <div class="box px-0 box-two-row border-0 bg-transparent">
                            <mat-label class="box-title px-3">Risposte</mat-label>
                            <div class="row px-5">
                                <small class="col-12">Valutazioni</small>
                                <div class="col-10 my-1">Storicità del rapporto con l’azienda</div>
                                <div class="col-2">3</div>
                                <div class="col-10 my-1">Valore dell’offerta</div>
                                <div class="col-2">3</div>
                                <div class="col-10 my-1">Valutazione del controllo della qualità</div>
                                <div class="col-2">3</div>
                                <div class="col-10 my-1">Descrizione aziendale</div>
                                <div class="col-2">3</div>
                                <div class="col-10 my-1">Stima dei tempi di consegna</div>
                                <div class="col-2">3</div>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center w-100 mt-0 mb-3 mx-auto">
                        <button class="px-5" mat-flat-button color="primary">Modifica il questionario</button>
                    </div>

                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
