import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FrameworkComponent } from './pages/framework/framework.component';
import { NewDecisionTableComponent } from './components/decision-table/new-decision-table/new-decision-table.component';
import { ShowDecisionTableComponent } from './components/decision-table/show-decision-table/show-decision-table.component';
import { DecisionTableDashboardComponent } from './components/decision-table/decision-table-dashboard/decision-table-dashboard.component';
import { NewProposalComponent } from './components/proposals/new-proposal/new-proposal.component';
import { NewProposalBlankComponent } from './components/proposals/new-proposal-blank/new-proposal-blank.component';
import { ResponsesSummaryComponent } from './pages/responses/responses-summary/responses-summary.component';
import { ResponsesEvaluationComponent } from './pages/responses/responses-evaluation/responses-evaluation.component';
import { ResponsesWeightTargetsComponent } from './pages/responses/responses-weight-targets/responses-weight-targets.component';
import { ResponsesWeightCriteriaComponent } from './pages/responses/responses-weight-criteria/responses-weight-criteria.component';
import { ResponsesProposalReviewComponent } from './pages/responses/responses-proposal-review/responses-proposal-review.component';
import { DecisionTableComponent } from './pages/decision-table/decision-table.component';
import { HomeComponent } from './pages/home/home.component';
import {TaskManagementComponent} from "./pages/task-management/task-management.component";
import {ProjectDetailsComponent} from "./components/projects/project-details/project-details.component";


const routes: Routes = [

  //  home page
  { path: '', component: HomeComponent, data: {title: 'Make better decision, faster, safe'} },

  //  framework
  {
    path: 'framework', data: { title: '' },
    children: [
      { path: '', component: FrameworkComponent, data: {title: 'Framework creation'} },
      { path: ':id_framework', component: FrameworkComponent, data: {title: 'Framework'} }
    ]
  },

  //  tavolo decisionale
  {
    path: 'decision', data: { title: '' },
    children: [
      // { path: '', component: DecisionTableComponent, data: { title: 'Tavolo decisionale' } },
      { path: '', component: NewDecisionTableComponent, data: { title: 'New decision' } },
      { path: ':id_decision', data: { title: 'Manage a decision'},
        children: [
          { path: '', component: ShowDecisionTableComponent, data: { title: 'Manage a decision'} },
          { path: 'show', component: ShowDecisionTableComponent, data: { title: 'Manage a decision'} },
          { path: 'dashboard', component: DecisionTableDashboardComponent, data: { title: 'Dashboard'} },
          { path: 'task-management', component: TaskManagementComponent, data: { title: 'Task Management'} },
        ]
      }
    ]
  },

  {
    path: 'projects', data: { title: '' },
    children: [
      { path: ':id_project', data: { title: 'Manage a project'},
        children: [
          { path: '', component: ProjectDetailsComponent, data: { title: 'Manage a project'} },
        ]
      }
    ]
  },

  //  gestione proposte in un tavolo decisionale
  //  TEST route
  { path: 'add-proposal', component: NewProposalComponent, data: {title: 'Add new proposal'} },
  { path: 'add-proposal-blank', component: NewProposalBlankComponent, data: {title: 'Modify and add proposal'} },

  //  risposte degli esperti
  //  TEST route
  { path: 'responses-summary', component: ResponsesSummaryComponent, data: {title: 'Framework creation'} },
  { path: 'responses-evaluation', component: ResponsesEvaluationComponent, data: {title: 'Framework creation'} },
  { path: 'responses-weight-target', component: ResponsesWeightTargetsComponent, data: {title: 'Framework creation'} },
  { path: 'responses-weight-criteria', component: ResponsesWeightCriteriaComponent, data: {title: 'Framework creation'} },
  { path: 'responses-proposal-review', component: ResponsesProposalReviewComponent, data: {title: 'Framework creation'} }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
