<form #form="ngForm" (keydown.enter)="$event.preventDefault()" novalidate>

  <!-- Fase 1 -->
  <ng-container *ngIf="step == 1">
    <!-- Blocco 1 - Creazione Tavolo - Fase 1 -->
    <div class="container-fluid accordion-container">
      <div class="container">
        <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="index">1</span>
                Process name
              </mat-panel-title>
            </mat-expansion-panel-header>
              <div class="row">
                <mat-form-field class="col-12 col-lg-6 input-big">
                  <input matInput name="framework_name" [(ngModel)]="model.decision_name" placeholder="Enter title" required>
                </mat-form-field>
                <mat-form-field class="col-6 col-lg-3 select-custom-button py-4" appearance="fill">
                  <mat-label>Organization</mat-label>
                  <mat-select name="organizzation" [(ngModel)]="model.organization_id" required>
                    <mat-option value="" selected></mat-option>
                    <mat-option *ngFor="let organizzation of userOrganizzation" [value]="organizzation.id">{{organizzation.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-6 col-lg-3 datepicker-custom-button py-4" appearance="fill">
                  <mat-label>Delivery date</mat-label>
                  <input matInput [matDatepicker]="dp_delivery_date" name="delivery_date" (focus)="dp_delivery_date.open()" class="ng-trim-ignore" [(ngModel)]="model.delivery_date">
                  <mat-datepicker-toggle matSuffix [for]="dp_delivery_date">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #dp_delivery_date></mat-datepicker>
                </mat-form-field>
              </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <!-- Blocco 2 - Creazione Tavolo - Fase 1 -->
    <div class="accordion-container container-fluid px-0">
      <div class="container-fluid px-0">
        <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header class="container">
              <mat-panel-title>
                <span class="index">2</span>
                Framework name
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="container px-4">
              <div class="row">
                <mat-form-field class="col-3 select-custom-button py-2" appearance="fill">
                  <mat-label>By sector</mat-label>
                  <mat-select #sectorInput name="searchFilterSecor" [(ngModel)]="searchModel.sector_key" (selectionChange)="onChangeSearchFilter()">
                    <mat-option value="" selected></mat-option>
                    <mat-option *ngFor="let sector of getWorkingSectorService().getAllSectorsForSelect()" [value]=sector.key>{{sector.value}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-3 select-custom-button py-2" appearance="fill">
                  <mat-label>By Sub-sector</mat-label>
                  <mat-select name="searchFilterSubsecor" [(ngModel)]="searchModel.subsector_key" [disabled]="!sectorInput.value" (selectionChange)="onChangeSearchFilter()">
                    <mat-option value="" selected></mat-option>
                    <mat-option *ngFor="let subsector of getSubsectorsArrayFiltered(sectorInput.value)" [value]="subsector.key">{{subsector.value}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-6 select-custom-button py-2" appearance="fill">
                  <mat-label>For Methodology of analysis</mat-label>
                  <mat-select name="searchFilterAnalysisType" [(ngModel)]="searchModel.analysis_type" (selectionChange)="onChangeSearchFilter()">
                    <mat-option value="" selected></mat-option>
                    <mat-option *ngFor="let analysis of getAnalysisMethodsService().getAllAnalysisMethods()" [value]="analysis.type">{{analysis.title}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="row" *ngIf="searchDone">
                <mat-grid-list class="col-12 toogle-row-list" cols="12" rowHeight="130px">
                  <ng-container *ngFor="let framework of frameworkFilteredList; let i = index">
                    <ng-container>
                      <mat-grid-tile colspan="3" (click)="onClickFramework(i)" [ngClass]="framework?.framework_id == frameworkSelected?.framework_id ? 'selected' : ''">
                          <div class="box">
                            <mat-label>{{framework.title}}</mat-label>
                            <mat-slide-toggle style="pointer-events: none;" class="toggle py-2" [checked]="framework?.framework_id == frameworkSelected?.framework_id" ></mat-slide-toggle>
                          </div>
                      </mat-grid-tile>
                    </ng-container>
                  </ng-container>
                    <mat-grid-tile colspan="3">
                      <div class="box-button">
                        <p class="my-0">
                          <i class="icon-Add mx-2"></i>
                          Add framework
                        </p>
                      </div>
                  </mat-grid-tile>
                </mat-grid-list>
              </div>
            </div>

            <div class="container-fluid box-full-width purple" *ngIf="frameworkSelected?.framework_id">
              <div class="container px-4">
                <div class="row">
                  <mat-form-field class="input-primary col-6">
                    <input matInput name="frameworkSelected_title" placeholder="Nome" [ngModel]="frameworkSelected.title" readonly>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field class="input-primary col-6">
                    <input matInput name="frameworkSelected_metodo" placeholder="Metodo" [ngModel]="frameworkSelected.method" readonly>
                  </mat-form-field>
                  <mat-form-field class="input-primary col-6">
                    <input matInput name="frameworkSelected_settore" placeholder="Settore" [ngModel]="frameworkSelected.sector" readonly>
                  </mat-form-field>
                </div>
                <div class="row px-3 my-4">
                  <section class="mat-typography">
                    <h4 class="font-weight-bold mb-1">Scope</h4>
                    <p>{{frameworkSelected.purpose_description}}</p>
                  </section>
                </div>
                <div class="row">
                  <mat-form-field class="input-primary col-3" *ngFor="let role of frameworkSelected.roles; let i = index">
                    <ng-container>
                      <input matInput name="frameworkSelected_role{{i}}" placeholder="Ruoli" [ngModel]="role" readonly>
                    </ng-container>
                  </mat-form-field>
                </div>
              </div>
            </div>

          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </ng-container>

  <!-- Fase 2 -->
  <ng-container *ngIf="step == 2">
    <!-- Blocco 1 - Creazione Tavolo -->
    <div class="container-fluid accordion-container">
      <div class="container">
        <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="index">1</span>
                Targets
              </mat-panel-title>
            </mat-expansion-panel-header>
              <div class="row">
                <div class="col-12 px-2" *ngIf="modelStartDecision.targets.primary">
                  <div class="box px-0 box-two-row">
                    <mat-label class="box-title px-3">Primary</mat-label>
                    <mat-form-field class="input-primary col-6">
                      <input matInput name="proposal_target_primary_title" [ngModel]="modelStartDecision.targets.primary.title" placeholder="Titolo" readonly>
                    </mat-form-field>
                    <mat-form-field class="datepicker-primary col-3" appearance="fill">
                      <mat-label>Data</mat-label>
                      <input matInput [matDatepicker]="dp_primary" (focus)="dp_primary.open()" name="proposal_target_primary_data" class="ng-trim-ignore" [(ngModel)]="modelStartDecision.targets.primary.data" required>
                        <mat-datepicker-toggle matSuffix [for]="dp_primary">
                          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #dp_primary></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="select-primary col-3" appearance="fill">
                      <mat-label>Unità di misura</mat-label>
                      <mat-select name="proposal_target_primary_criteria_unit" [(ngModel)]="modelStartDecision.targets.primary.measure_unit" required>
                        <mat-option value="" selected></mat-option>
                        <mat-option *ngFor="let unit of getUnitsService().getAllUnitsForSelect()" [value]="unit.key">{{unit.value}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="input-primary col-6">
                      <input matInput name="proposal_target_primary_evaluation_criteria" placeholder="Valore obiettivo" [ngModel]="getCriteriaService().getCriteriaValueByKey(modelStartDecision.targets.primary.evaluation_criteria)" readonly>
                    </mat-form-field>
                  </div>
                </div>

                <div class="col-6 px-2" *ngFor="let targetSecondary of modelStartDecision.targets.secondary; let i = index">
                  <ng-container>
                    <div class="box px-0 box-two-row">
                      <mat-label class="box-title px-3">Secondary</mat-label>
                      <mat-form-field class="input-primary col-7">
                        <input matInput name="proposal_target_secondary_title{{i}}" [ngModel]="targetSecondary.title" placeholder="Titolo" readonly>
                      </mat-form-field>
                      <mat-form-field class="datepicker-primary col-5" appearance="fill">
                        <mat-label>Data</mat-label>
                        <input matInput [matDatepicker]="dp_secondary" (focus)="dp_secondary.open()" name="proposal_target_secondary_data{{i}}" class="ng-trim-ignore" [(ngModel)]="targetSecondary.data" required>
                          <mat-datepicker-toggle matSuffix [for]="dp_secondary">
                            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                          </mat-datepicker-toggle>
                          <mat-datepicker #dp_secondary></mat-datepicker>
                      </mat-form-field>
                      <mat-form-field class="input-primary col-7">
                        <input matInput name="proposal_target_secondary_evaluation_criteria{{i}}" placeholder="Valore obiettivo" [ngModel]="getCriteriaService().getCriteriaValueByKey(targetSecondary.evaluation_criteria)">
                      </mat-form-field>
                      <mat-form-field class="select-primary col-5" appearance="fill">
                        <mat-label>Unità</mat-label>
                        <mat-select name="proposal_target_secondary_criteria_unit{{i}}" [(ngModel)]="targetSecondary.measure_unit" required>
                          <mat-option value="" selected></mat-option>
                          <mat-option *ngFor="let unit of getUnitsService().getAllUnitsForSelect()" [value]="unit.key">{{unit.value}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <!-- <i class="icon-cestino grey-color right-bottom" ></i> -->
                    </div>
                  </ng-container>
                </div>

                <!-- <div class="col-6 px-2">
                  <div class="box-button">
                    <p class="my-0">
                      <i class="icon-Add mx-2"></i>
                      Aggiungi obiettivo secondario
                    </p>
                  </div>
                </div> -->
              </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <!-- Blocco 2 - Creazione Tavolo -->
    <div class="container-fluid accordion-container">
      <div class="container">
        <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="index">2</span>
                Proposal
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="container px-0">
              <div *ngFor="let proposal of modelStartDecision.proposals; let i = index">
                <ng-container>
                  <div class="row">
                    <mat-form-field class="col-12 input-big">
                      <input matInput name="proposal_name{{i}}" [(ngModel)]="proposal.title" placeholder="Inserisci il nome della proposal" required>
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <div class="col-12 box" *ngFor="let criterio of proposal.criteria; let j = index">
                      <ng-container>
                        <div class="col-auto box-index">
                          {{j + 1}}
                        </div>
                        <mat-form-field class="input-primary col-3 mt-2">
                          <input matInput name="criteria_description{{i}}_{{j}}" [(ngModel)]="criterio.title" placeholder="Description" readonly>
                        </mat-form-field>
                        <mat-form-field class="input-primary col-3 mt-2">
                          <mat-label>Criterio del dato</mat-label>
                          <input matInput name="criteria_typology{{i}}_{{j}}" [ngModel]="getCriteriaService().getCriteriaTypeValueByKey(criterio.typology)" readonly>
                        </mat-form-field>
                        <mat-form-field class="input-primary col-3 mt-2">
                          <input matInput name="criteria_value{{i}}_{{j}}"
                          [placeholder]="getCriteriaService().criteriaIsQuantitative(criterio.typology) ? 'Inserire valore numerico' : 'Add description'"
                          [type]="getCriteriaService().criteriaIsQuantitative(criterio.typology) ? 'number' : 'text'"
                          [(ngModel)]="criterio.value" required
                          >
                          <!-- pattern="^\d*(\d{0})?$" -->
                        </mat-form-field>
                        <mat-form-field *ngIf="getCriteriaService().criteriaIsQuantitative(criterio.typology)" class="input-primary col-2 mt-2">
                          <mat-label>Unità di misura</mat-label>
                          <input matInput name="criteria_unit{{i}}_{{j}}" [ngModel]="getUnitsService().getUnitValueByKey(criterio.measure_unit)" readonly>
                        </mat-form-field>
                        <!-- <div class="icons-container">
                          <i class="icon-cestino grey-color float-right"></i>
                          <i class="icon-Yes float-right"></i>
                        </div> -->
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="row">
                <button type="button" class="col-12 big-button mb-3" mat-raised-button (click)="addNewProposal()">
                  <i class="icon-Add"></i>
                  Add proposal
                </button>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <!-- Blocco 3 - Creazione Tavolo -->
    <div class="accordion-container container-fluid px-0">
      <div class="container-fluid px-0">
        <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header class="container">
              <mat-panel-title>
                <span class="index">3</span>
                Modifica delle scale di valutazione
              </mat-panel-title>
            </mat-expansion-panel-header>

            <!-- grey section radio -->
            <div class="container py-4 px-0">
              <div class="box-info mb-4">
                <i class="icon-circle_info float-left col-2 flex-column"></i>
                <p class="description col-10">Le scale definite in questa sezione varranno per tutta la durata del processo.</p>
              </div>
              <div class="row py-4 two-radio-box mt-0 bg-transparent border-bottom-0">
                <div class="container px-0">
                  <p class="title">Vuoi modificare la scala di valutazione del processo?</p>
                  <mat-radio-group class="col-12 mat-radio-box px-0" aria-label="Select an option">
                    <mat-grid-list cols="12" rowHeight="100px">
                      <ng-container>
                        <mat-radio-group>
                          <mat-grid-tile colspan="6" (click)="change_rating_scale = true">
                            <mat-radio-button name="change_rating_scale_yes" [value]="true" [checked]="change_rating_scale == true" class="col-12">Si</mat-radio-button>
                          </mat-grid-tile>
                          <mat-grid-tile colspan="6" (click)="change_rating_scale = false">
                            <mat-radio-button name="change_rating_scale_no" [value]="false" [checked]="change_rating_scale == false" class="col-12">No</mat-radio-button>
                          </mat-grid-tile>
                        </mat-radio-group>
                      </ng-container>
                    </mat-grid-list>
                  </mat-radio-group>
                </div>
              </div>
            </div>

            <!-- white section (Blocco Scala)-->
            <ng-container *ngIf="change_rating_scale == true">
              <div class="container-fluid box-full-width white" *ngFor="let rating_scale of modelStartDecision.rating_scales; let i = index">
                <ng-container>
                  <!-- White row titolo e progress bar -->
                  <div class="container pb-2">
                    <p class="big-title mb-4">Scala di valutazione da {{rating_scale.min_value}} a {{rating_scale.max_value}}</p>
                    <div class="row row-progress-bar">
                      <div class="col-1 icon-container text-right"><i class="icon-grid-interface"></i></div>
                      <div class="col-10">
                        <mat-progress-bar class="progress-bar-primary my-2" mode="determinate" value="60" fill="0"></mat-progress-bar>
                      </div>
                      <div class="col-1 icon-container">
                          <mat-checkbox name="rating_scale_selected{{i}}" [checked]="rating_scale.selected" [(ngModel)]="rating_scale.selected"></mat-checkbox>
                      </div>
                    </div>
                  </div>
                  <!-- progress bar settings -->
                  <div class="box-shadow-external py-4 box-full-width-inner my-4">
                    <div class="container">
                      <mat-label class="label-box ml-2 font-weight-bold">Setup scala di valutazione</mat-label>
                      <div class="row mt-1">
                        <mat-form-field class="select-primary col-4" appearance="fill">
                          <mat-label>Valore minimo</mat-label>
                          <mat-select name="rating_scale_min{{i}}" [(ngModel)]="rating_scale.min_value" required>
                            <mat-option [value]="number+1" [disabled]="rating_scale.max_value && number >= rating_scale.max_value" *ngFor="let number of createArrayOfNumber(0, 100)">{{number+1}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field class="select-primary col-4" appearance="fill">
                          <mat-label>Valore massimo</mat-label>
                          <mat-select name="rating_scale_max{{i}}" [(ngModel)]="rating_scale.max_value" required>
                            <mat-option [value]="number+1" [disabled]="number < rating_scale.min_value" *ngFor="let number of createArrayOfNumber(0, 100)">{{number+1}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field class="select-primary col-4" appearance="fill">
                          <mat-label>Valore step</mat-label>
                          <mat-select name="rating_scale_step{{i}}" [(ngModel)]="rating_scale.step" required>
                            <mat-option [value]="number" [disabled]="rating_scale.max_value && rating_scale.max_value % number !== 0" *ngFor="let number of [1,2,5,10]">{{number}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <!-- Text Area -->
                  <div class="container pt-4">
                    <div class="col-12 box flex-column mb-0 pb-0 pt-3 px-3">
                      <mat-form-field>
                        <mat-label>Descrizione scala</mat-label>
                        <textarea class="description" name="rating_scale_description{{i}}" matInput rows="3" [(ngModel)]="rating_scale.description" placeholder="Inserire descrizione della scala" required></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>

            <!-- Button Area -->
            <div class="container-fluid box-full-width white pt-2 mbn-16" *ngIf="change_rating_scale == true">
              <div class="container">
                <div class="row">
                  <button class="col-12 big-button mb-3" mat-raised-button (click)="addNewRatingScale()">
                    <i class="icon-Add"></i>
                    Aggiungi scala
                  </button>
                </div>
              </div>
            </div>

          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <!-- Blocco 4 - Creazione Tavolo -->
    <div class="container-fluid accordion-container">
      <div class="container">
        <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="index">4</span>
                Invita il team
              </mat-panel-title>
            </mat-expansion-panel-header>
              <div class="row">

                <div class="col-12 px-0" *ngFor="let team_member of modelStartDecision.team_members; let i = index">
                  <ng-container>
                    <div class="box px-0 box-two-row">

                      <mat-form-field class="input-primary col-5">
                        <input matInput name="team_member_name{{i}}" [(ngModel)]="team_member.username" placeholder="Nome Cognome" required>
                      </mat-form-field>
                      <mat-form-field class="select-primary col-3" appearance="fill">
                        <mat-label>Ruolo professionale</mat-label>
                        <mat-select name="team_member_professional_role{{i}}" [(ngModel)]="team_member.orgnization_professional_role_id">
                          <mat-option value="" selected></mat-option>
                          <mat-option *ngFor="let professionalRole of professionalRoles" [value]=professionalRole.id>{{professionalRole.name}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="select-primary col-3" appearance="fill">
                        <mat-label>Ruolo</mat-label>
                        <mat-select name="team_member_process_role{{i}}" [(ngModel)]="team_member.process_role">
                          <mat-option value="" selected></mat-option>
                          <mat-option *ngFor="let processRole of process_roles" [value]="processRole.key">{{processRole.title}}</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <div class="my-4">
                        <mat-form-field class="input-primary col-5">
                          <input matInput name="team_member_email{{i}}" [(ngModel)]="team_member.email" placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required>
                        </mat-form-field>
                        <div class="toggle-container col-3 d-inline-block" appearance="fill">
                          <mat-label>Abilitato a fornire nuove proposal</mat-label>
                          <div>
                            <span class="mr-2">No</span>
                            <mat-slide-toggle class="toggle" name="team_member_provide_new_proposal_enabled{{i}}" [(ngModel)]="team_member.provide_new_proposal_enabled">Si</mat-slide-toggle>
                          </div>
                        </div>
                        <div class="toggle-container col-3 d-inline-block" appearance="fill">
                          <mat-label>Abilitato alla revisione</mat-label>
                          <div>
                            <span class="mr-2">No</span>
                            <mat-slide-toggle class="toggle" name="team_member_review_enabled{{i}}" [(ngModel)]="team_member.review_enabled">Si</mat-slide-toggle>
                          </div>
                        </div>
                      </div>

                      <div class="my-4">
                        <div class="toggle-container col-5 d-inline-block" appearance="fill">
                          <mat-label>Abilitato alla valutazione completa</mat-label>
                          <div>
                            <span class="mr-2">No</span>
                            <mat-slide-toggle class="toggle" name="team_member_complete_process_enabled{{i}}" [(ngModel)]="team_member.complete_process_enabled">Si</mat-slide-toggle>
                          </div>
                        </div>
                        <mat-form-field class="select-primary col-3" appearance="fill">
                          <mat-label>Sezioni abilitate</mat-label>
                          <mat-select>
                            <mat-option value=""></mat-option>
                            <mat-option value="test01">1</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <i class="icon-Menu abs-tr" (click)="removeTeamMember(i)"></i>
                    </div>
                  </ng-container>
                </div>


                <button class="col-12 big-button mb-3" mat-raised-button (click)="addNewTeamMember()">
                  <i class="icon-Add"></i>
                  Aggiungi membro al team
                </button>
              </div>

          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </ng-container>


  <div class="buttons-container container py-4">
    <button class="button-primary float-left" mat-button>
      <i class="icon-Backward-arrow-small"></i>
      Back
    </button>
    <button type="submit" class="button-primary float-right" mat-button (click)="onSubmit(form)">
      <i class="icon-Forward-arrow-small"></i>
      Save
    </button>
  </div>
</form>
