import { Injectable } from '@angular/core';
import { BackendService } from './general/backend.service';

@Injectable({
  providedIn: 'root'
})
export class FrameworkService {

  constructor(private backend: BackendService) { }





  /**
   * Salva un framework completo
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public save(model: any, success_callback = (data: any) => { }, error_callback = (error: any) => { } ) {

    this.backend.post('framework', model).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    });

  }
}
