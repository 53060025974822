import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ScriptLoaderService } from 'angular-google-charts';
import { CriteriaService } from 'src/app/services/config/criteria.service';
@Component({
  selector: 'app-new-proposal',
  templateUrl: './new-proposal.component.html',
  styleUrls: ['./new-proposal.component.scss']
})
export class NewProposalComponent implements OnInit {

  @Input() proposal: any;
  @Output() proposalSavedEvent = new EventEmitter();

  currentProposal: any = {
    framework: {
      primary_target: { evaluation_criteria: null, title: "", type: "primary" },
      secondary_targets: [],
      criteria: []
    }
  };

  showPrivateInfo = false;

  displayedColumns: string[] = ['name', 'sentiment', 'feedback', 'truefalse', 'confidence', 'evidence'];
  displayedColumnsEvidence: string[] = ['type', 'name', 'link'];

  chartOptionsContradiction = {
    height: 200,
    enableInteractivity: false,
    // legend: {position: 'none'},
    displayAnnotations: true,
    hAxis: {
      textPosition: 'none',
      maxValue: 100,
      gridlines: {
        count: 0,
        color: 'transparent',
        format: 'percent'
      }
    },
    animation: {
      startup: true
    }
  };
  chartOptionsFeedback = {
    height: 200,
    pieHole: 0.4,
    enableInteractivity: false,
    pieSliceText: 'none',
    slices: {
      0: { color: '#00bc1d' },
      1: { color: '#ff0000' },
      2: { color: '#e5e3e3' }
    },
    // legend: 'left',
    animation: {
      startup: true
    }
  };
  constructor(private criteriaService: CriteriaService, private loaderService: ScriptLoaderService) { }

  ngOnInit(): void {

    console.log(this.proposal);

    if (this.proposal) {
      this.currentProposal = JSON.parse(JSON.stringify(this.proposal));
    }

    //  carico i grafici
    this.loaderService.loadChartPackages('corechart').subscribe(() => {

      this.currentProposal.framework.criteria.forEach(criterio => {
        this.drawChartContraddition(criterio);
      });

      this.currentProposal.framework.criteria.forEach(criterio => {
        this.drawChartFeedback(criterio);
      });

      this.drawChartTarget(this.currentProposal.framework.secondary_targets);
    });

  }


  /** Disegna i grafici di contraddizione e certezza */
  drawChartContraddition(criterio: any) {

    if(this.currentProposal.framework.analysis_type != 2) return;

    let data = google.visualization.arrayToDataTable([
      ["Element", "Density", { role: "style" } ],
      ["Degree of certainty", {v: criterio.chartData.certainty, f: criterio.chartData.certainty+'%'}, "#1b61a1"],
      ["Degree of contradiction", {v: criterio.chartData.contradition, f: criterio.chartData.contradition+'%'}, "#ff0000"]
    ]);

    let view = new google.visualization.DataView(data);
    view.setColumns([0, 1, { calc: "stringify", sourceColumn: 1, type: "string", role: "annotation" },2]);

    let chart = new google.visualization.BarChart(document.getElementById("chart_div_"+criterio.title));
    chart.draw(view, this.chartOptionsContradiction);
  }

  /** Disegna i grafici di contraddizione e certezza */
  drawChartFeedback(criterio: any) {

    if(this.currentProposal.framework.analysis_type != 2) return;

    let data = google.visualization.arrayToDataTable([
      ["Element", "Density"],
      ["Agree", criterio.chartData.agree],
      ["Disagree", criterio.chartData.disagree],
      ["Abstained", criterio.chartData.none]
    ]);

    let view = new google.visualization.DataView(data);

    let chart = new google.visualization.PieChart(document.getElementById("chart_feedback_div_"+criterio.title));
    chart.draw(view, this.chartOptionsFeedback);
  }

  /** Disegno i grafici dei target */
  drawChartTarget(secondaryTargets: any) {

    if(this.currentProposal.framework.analysis_type != 1) return;

    let arrayData = [['Target', '', { role: 'style' }, { role: 'annotation' }]];
    secondaryTargets.forEach(target => {
      arrayData.push([target.title, {v: target.chartData.value, f: target.chartData.value}, '#DCDEFA', target.chartData.value ]);
    });

    //  serto il colore
    secondaryTargets.forEach(target => {

      let currentArrayData = JSON.parse(JSON.stringify(arrayData));
      let index = currentArrayData.findIndex(x => x[0] == target.title);
      if (index >= 0) {}
        currentArrayData[index][2] = '#9169DB';

      let data = google.visualization.arrayToDataTable(currentArrayData);
      let view = new google.visualization.DataView(data);
      let chart = new google.visualization.BarChart(document.getElementById("chart_div_target_"+target.title));
      chart.draw(view, this.chartOptionsFeedback);
    });

  }



  saveAndBack(){
    this.proposalSavedEvent.emit();
  }

  saveAndAdd(){
    this.proposalSavedEvent.emit();
  }



  getCriteriaService() {
    return this.criteriaService;
  }


  toggleAnonymousProposalInfo() {
    this.showPrivateInfo = !this.showPrivateInfo;
  }

}
