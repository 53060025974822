import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-responses-proposal-review',
  templateUrl: './responses-proposal-review.component.html',
  styleUrls: ['./responses-proposal-review.component.scss']
})
export class ResponsesProposalReviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
