
<!-- Blocco 1 - Creazione Tavolo -->
<div class="container-fluid accordion-container">
    <div class="container">
        <mat-accordion class="accordion" multi>
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="index">1</span>
                        Targets
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                    <div class="col-12 px-2">
                        <div class="box px-0 box-two-row">
                            <mat-label class="box-title px-3">Primary</mat-label>
                            <mat-form-field class="input-primary col-6">
                                <input matInput placeholder="Titolo">
                            </mat-form-field>
                            <mat-form-field class="datepicker-primary col-3" appearance="fill">
                                <mat-label>Data</mat-label>
                                <input matInput [matDatepicker]="dp_start">
                                <mat-datepicker-toggle matSuffix [for]="dp_start">
                                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #dp_start></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field class="select-primary col-3" appearance="fill">
                                <mat-label>Unità</mat-label>
                                <mat-select>
                                    <mat-option value="" selected></mat-option>
                                    <mat-option value="test01">test 01</mat-option>
                                    <mat-option value="test02">test 02</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="input-primary col-6">
                                <input matInput placeholder="Valore obiettivo">
                            </mat-form-field>
                            <i class="icon-circle_info top-right grey-color"></i>
                        </div>
                    </div>

                    <!-- <div class="col-6 px-2">
                        <div class="box px-0 box-two-row">
                            <mat-label class="box-title px-3">Secondary</mat-label>
                            <mat-form-field class="input-primary col-7">
                                <input matInput placeholder="Titolo">
                            </mat-form-field>
                            <mat-form-field class="datepicker-primary col-5" appearance="fill">
                                <mat-label>Data</mat-label>
                                <input matInput [matDatepicker]="dp_end">
                                <mat-datepicker-toggle matSuffix [for]="dp_end">
                                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #dp_end></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field class="input-primary col-7">
                                <input matInput placeholder="Valore obiettivo">
                            </mat-form-field>
                            <mat-form-field class="select-primary col-5" appearance="fill">
                                <mat-label>Unità</mat-label>
                                <mat-select>
                                    <mat-option value="" selected></mat-option>
                                    <mat-option value="test01">test 01</mat-option>
                                    <mat-option value="test02">test 02</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <i class="icon-circle_info top-right grey-color"></i>
                        </div>
                    </div> -->

                    <!-- <div class="col-6 px-2">
                        <div class="box px-0 box-two-row">
                            <mat-label class="box-title px-3">Secondary</mat-label>
                            <mat-form-field class="input-primary col-7">
                                <input matInput placeholder="Titolo">
                            </mat-form-field>
                            <mat-form-field class="datepicker-primary col-5" appearance="fill">
                                <mat-label>Data</mat-label>
                                <input matInput [matDatepicker]="dp_end">
                                <mat-datepicker-toggle matSuffix [for]="dp_end">
                                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #dp_end></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field class="input-primary col-7">
                                <input matInput placeholder="Valore obiettivo">
                            </mat-form-field>
                            <mat-form-field class="select-primary col-5" appearance="fill">
                                <mat-label>Unità</mat-label>
                                <mat-select>
                                    <mat-option value="" selected></mat-option>
                                    <mat-option value="test01">test 01</mat-option>
                                    <mat-option value="test02">test 02</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <i class="icon-circle_info top-right grey-color"></i>
                        </div>
                    </div> -->

                    <!-- <div class="col-6 px-2">
                        <div class="box px-0 box-two-row">
                            <mat-label class="box-title px-3">Secondary</mat-label>
                            <mat-form-field class="input-primary col-7">
                                <input matInput placeholder="Titolo">
                            </mat-form-field>
                            <mat-form-field class="datepicker-primary col-5" appearance="fill">
                                <mat-label>Data</mat-label>
                                <input matInput [matDatepicker]="dp_end">
                                <mat-datepicker-toggle matSuffix [for]="dp_end">
                                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #dp_end></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field class="input-primary col-7">
                                <input matInput placeholder="Valore obiettivo">
                            </mat-form-field>
                            <mat-form-field class="select-primary col-5" appearance="fill">
                                <mat-label>Unità</mat-label>
                                <mat-select>
                                    <mat-option value="" selected></mat-option>
                                    <mat-option value="test01">test 01</mat-option>
                                    <mat-option value="test02">test 02</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <i class="icon-circle_info top-right grey-color"></i>
                        </div>
                    </div> -->
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>

<!-- Blocco 2 - Creazione Tavolo -->
<div class="container-fluid accordion-container">
    <div class="container">
        <mat-accordion class="accordion" multi>
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="index">2</span>
                        Proposal
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="container px-0">
                    <div class="row px-2">
                        <div class="col-12 box">
                            <div class="col-auto box-index">
                                1
                            </div>
                            <mat-form-field class="input-primary col-3 mt-2">
                                <input matInput placeholder="Description">
                            </mat-form-field>
                            <mat-form-field class="select-primary col-3 px-0" appearance="fill">
                                <mat-label>Tipologia del dato</mat-label>
                                <mat-select>
                                    <mat-option value="" selected></mat-option>
                                    <mat-option value="test01">test 01</mat-option>
                                    <mat-option value="test02">test 02</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="input-primary col-3 mt-2">
                                <input matInput placeholder="Inserire valore numerico">
                            </mat-form-field>
                            <mat-form-field class="select-primary col-2 px-0" appearance="fill">
                                <mat-label>Anni</mat-label>
                                <mat-select>
                                    <mat-option value="" selected></mat-option>
                                    <mat-option value="test01">test 01</mat-option>
                                    <mat-option value="test02">test 02</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
