import { Injectable} from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor() { }

  private toastEvent = new Subject<any>();
  public onMessage(): Observable<any> {
    return this.toastEvent.asObservable();
}


  private showToast(title: string, message: string, type: string = 'bare') {
    this.toastEvent.next(
      {
        title: title,
        content: message,
        type: type,
      }
    );
  }

  public simple(title: string, message: string = null) {
    this.showToast(title, message, 'simple');
  }

  public success(title: string, message: string = null) {
    this.showToast(title, message, 'success');
  }

  public error(title: string, message: string = null) {
    this.showToast(title, message, 'error');
  }

  public alert(title: string, message: string = null) {
    this.showToast(title, message, 'alert');
  }

  public warn(title: string, message: string = null) {
    this.showToast(title, message, 'warn');
  }

  public info(title: string, message: string = null) {
    this.showToast(title, message, 'info');
  }


}
