<div class="container-fluid box-sub-header py-3 pt-lg-5">
    <div class="container">
        <mat-form-field class="col-12 col-lg-6 select-custom-button" appearance="fill">
            <mat-label>Organizzazione</mat-label>
            <mat-select name="organizzation">
                <mat-option value="Nome organizzazione" selected>Nome organizzazione</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="col-12 col-lg-6 datepicker-custom-button" appearance="fill">
            <mat-label>Data di consegna</mat-label>
            <input matInput [matDatepicker]="dp">
            <mat-datepicker-toggle matSuffix [for]="dp">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #dp></mat-datepicker>
        </mat-form-field>
    </div>
</div>

<div class="container-fluid my-1 bg-white">
    <div class="row container mx-auto">
        <div class="stepper-container col-12 my-4 pb-4">
            <div class="timeline">
                <div class="step-container">
                    <div class="step">1</div>
                </div>
                <div class="step-container current">
                    <div class="step">2</div>
                </div>
                <div class="step-container">
                    <div class="step">3</div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Blocco 2 -->
<div class="container-fluid accordion-container px-0 px-md-3">
    <div class="container">
        <mat-accordion class="accordion" multi>
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="index">2</span>
                        Valutazione
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">

                    <div class="col-12 col-lg-6 px-0 mb-0">
                        <div class="box py-0 px-2 bg-transparent border-0 mb-0">
                            <mat-label class="box-title px-3 mb-0">Criteri rispetto agli obiettivi secondari</mat-label>
                        </div>
                    </div>

                    <div class="container-fluid accordion-container mt-3 accordion-inner rounded-0 bg-white arrow-left">
                        <div class="container">
                            <mat-accordion class="accordion" multi [togglePosition]="'before'">
                                <mat-expansion-panel class="px-0" expanded>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <span>Rispetto ai tempi</span>
                                            <i class="icon-circle_info d-flex"></i>
                                            <i class="icon-checkmark abs-tr orange-color mx-0"></i>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row border-bottom-grey pt-0 pb-4 my-3">
                                        <small class="col-12">Valutazione</small>
                                        <div class="col-12">Rispetto dei tempi</div>
                                        <mat-slider class="w-100 mt-2 tricolor" min="1" max="10" step="1" value="3" color="primary"></mat-slider>
                                        <div class="d-flex justify-content-between w-100 mb-2 px-2">
                                            <small class="float-left">Poco importante</small>
                                            <small class="float-right">Molto importante</small>
                                        </div>
                                    </div>

                                    <div class="row border-bottom-grey pt-4 pb-4 my-3">
                                        <small class="col-12">Valutazione</small>
                                        <div class="col-12">Rispetto dei tempi</div>
                                        <mat-slider class="w-100 mt-2 tricolor" min="1" max="10" step="1" value="3" color="primary"></mat-slider>
                                        <div class="d-flex justify-content-between w-100 mb-2 px-2">
                                            <small class="float-left">Poco importante</small>
                                            <small class="float-right">Molto importante</small>
                                        </div>
                                    </div>

                                    <div class="row pt-4 pb-0 my-3">
                                        <small class="col-12">Valutazione</small>
                                        <div class="col-12">Rispetto dei tempi</div>
                                        <mat-slider class="w-100 mt-2 tricolor" min="1" max="10" step="1" value="3" color="primary"></mat-slider>
                                        <div class="d-flex justify-content-between w-100 mb-2 px-2">
                                            <small class="float-left">Poco importante</small>
                                            <small class="float-right">Molto importante</small>
                                        </div>
                                    </div>

                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>

                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>


<div class="container-fluid mt-4">
    <div class="row justify-content-center w-100 mt-0 mb-3 mx-auto">
        <button class="px-5" mat-flat-button color="primary">Salva in bozze</button>
    </div>
</div>


<!-- Buttons -->
<div class="buttons-container container py-4">
    <button class="button-primary float-left" mat-button>
        <i class="icon-Backward-arrow-small"></i>
        Go back
    </button>
    <button class="button-primary float-right" mat-button>
        <i class="icon-Forward-arrow-small"></i>
        Save and add proposal
    </button>
</div>
