<form (keydown.enter)="$event.preventDefault()" novalidate>
    <!-- Blocco 1 - Obiettivi -->
    <div class="container-fluid accordion-container">
        <div class="container">
            <mat-accordion class="accordion" multi>
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class="index">1</span>
                            Targets
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="row pl-4 header-title">
                        <!-- <p class="mb-0 mr-2">Time taken to compile</p> -->
                        <!-- <span class="main-color text-uppercase">1 ORA</span> -->
                    </div>

                    <div class="row">
                        <div class="col-12 px-2">
                            <div class="box px-0 box-two-row">
                                <mat-label class="box-title px-3">Primary</mat-label>
                                <mat-form-field class="input-primary col-6">
                                    <input matInput name="primary_target" [(ngModel)]="currentProposal?.framework?.primary_target.title" readonly>
                                </mat-form-field>
                                <mat-form-field class="select-primary col-6" appearance="fill">
                                    <mat-label>Evaluation criterion</mat-label>
                                    <mat-select name="primary_target_evaluation" [(ngModel)]="currentProposal?.framework?.primary_target.evaluation_criteria" disabled>
                                        <mat-option value="" selected></mat-option>
                                        <mat-option *ngFor="let criterion of getCriteriaService().getAllCriteriaForSelect()" [value]="criterion.key">{{criterion.value}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <i class="icon-circle_info top-right grey-color"></i>
                            </div>
                        </div>

                        <div class="col-6 px-2" *ngFor="let target of currentProposal?.framework?.secondary_targets; let i = index">
                            <ng-container>
                                <div class="box px-0 box-two-row">
                                        <mat-label class="box-title px-3">Secondary</mat-label>
                                        <mat-form-field class="input-primary col-6">
                                            <input matInput name="secondary_targets{{i}}" [(ngModel)]="target.title" readonly>
                                        </mat-form-field>
                                        <mat-form-field class="select-primary col-6" appearance="fill">
                                        <mat-label>Evaluation criterion</mat-label>
                                        <mat-select name="secondary_target_evaluation{{i}}" [(ngModel)]="target.evaluation_criteria" required>
                                            <mat-option value="" selected></mat-option>
                                        <mat-option *ngFor="let criterion of getCriteriaService().getAllCriteriaForSelect()" [value]="criterion.key">{{criterion.value}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div class="col-12" *ngIf="target.chartData">
                                        <!-- <p class="text-center py-5">...grafico...</p> -->
                                        <!-- <p class="text-center py-5" style="font-size: 50px;">{{target.chartData.value}}</p> -->
                                        <div *ngIf="currentProposal.framework.analysis_type == 1" style="width: 100%;" id="chart_div_target_{{target.title}}"></div>
                                        <!-- <google-chart
                                          [type]="'BarChart'"
                                          [data]="[['Cost', {v: 167.53, f: '167.53'}],['Delivery', {v: 252.38, f: '252.38'}],['Quality', {v: 164.94, f: '164.94'}], ['Risk', {v: 223.9, f: '223.9'}]]"
                                          [options]="{colors: ['#e6693e']}">
                                        </google-chart> -->
                                    </div>
                                    <i class="icon-circle_info top-right grey-color"></i>
                                </div>
                            </ng-container>
                        </div>

                        <!-- Text Area -->
                        <!-- <div class="col-12 px-2">
                            <div class="box flex-column mb-0 pb-0 pt-3 px-3">
                                <mat-form-field>
                                    <mat-label>Feedback</mat-label>
                                    <textarea class="description" matInput rows="3" name=""
                                        placeholder="Inserire feedback"></textarea>
                                </mat-form-field>
                            </div>
                        </div> -->
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>

    <!-- Blocco 2 - Criteri -->
    <div class="container-fluid accordion-container">
        <div class="container">
            <mat-accordion class="accordion" multi>
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class="index">2</span>
                            Criteria
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="row pl-4 header-title">
                        <!-- <p class="mb-0 mr-2">Time taken to compile</p> -->
                        <!-- <span class="main-color text-uppercase">1 ORA</span> -->
                    </div>

                    <div class="container-fluid accordion-container mt-3 accordion-inner" *ngFor="let criterio of currentProposal?.framework?.criteria; let i = index">
                        <ng-container>
                        <div class="container">
                            <mat-accordion class="accordion" multi>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                          <mat-icon style="color: yellow;" *ngIf="criterio?.indicators.contradiction_alert" aria-hidden="false">report_problem</mat-icon> &nbsp;
                                            {{criterio.title}}
                                            <i class="icon-circle_info"></i>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                      <div class="ml-auto my-3">
                                        <button class="size-small text-white" mat-flat-button color="secondary">Modify</button>
                                      </div>
                                    </div>
                                    <!-- Text Area -->
                                    <div class="col-12 px-2">
                                      <div class="box flex-column my-0 pb-0 pt-3 px-3">
                                        <mat-form-field>
                                            <mat-label>Description</mat-label>
                                            <textarea class="description" matInput resizeToFitContent="true" name="criterio_description{{i}}"
                                                placeholder="Add description" [(ngModel)]="criterio.description" disabled></textarea>
                                        </mat-form-field>
                                      </div>
                                        <!-- <div class="box flex-column my-0 pb-0 pt-3 px-3 bordered">
                                            <mat-form-field>
                                                <mat-label>Description</mat-label>
                                                <textarea class="description" matInput rows="2" name="criterio_description{{i}}"
                                                    placeholder="Add description"></textarea>
                                            </mat-form-field>
                                        </div> -->
                                    </div>

                                      <!-- Tabella Feedback-->
                                        <div class="col-12 px-2" *ngIf="criterio.evidenceData.length >0">
                                          <mat-table [dataSource]="criterio.evidenceData" style="width: 100%;" class="custom-height">

                                            <!-- Name Column -->
                                            <ng-container matColumnDef="type">
                                              <mat-header-cell *matHeaderCellDef style="flex: 0 0 10% !important">Evidence</mat-header-cell>
                                              <mat-cell class="custom-height" *matCellDef="let element" style="flex: 0 0 10% !important">
                                                <mat-icon>{{element.type}}</mat-icon>
                                              </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="name">
                                              <mat-header-cell *matHeaderCellDef style="flex: 0 0 80% !important"></mat-header-cell>
                                              <mat-cell class="custom-height" *matCellDef="let element" style="flex: 0 0 80% !important">
                                                <a [href]="element.link">{{element.name}}</a>
                                              </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="link">
                                              <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                              <mat-cell class="custom-height" *matCellDef="let element">
                                              </mat-cell>
                                            </ng-container>

                                            <mat-header-row *matHeaderRowDef="displayedColumnsEvidence"></mat-header-row>
                                            <mat-row *matRowDef="let row; columns: displayedColumnsEvidence;"></mat-row>
                                          </mat-table>
                                        </div>

                                        <!-- Text Area -->
                                        <!-- <div class="col-12 px-2">
                                            <div class="box flex-column my-0 pb-0 pt-3 px-3 bordered">
                                                <mat-form-field>
                                                    <mat-label>Description</mat-label>
                                                    <textarea class="description" matInput rows="2" name="criterio_description{{i}}"
                                                        placeholder="Add description"></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div> -->

                                    <div class="row">
                                        <div class="col-6">
                                          <!-- <p class="text-center py-5">...grafico...</p> -->
                                          <div *ngIf="currentProposal.framework.analysis_type == 2" style="width: 100%;" id="chart_div_{{criterio.title}}"></div>
                                          <div *ngIf="currentProposal.framework.analysis_type == 1">
                                            <p class="text-center" >Average of the degree of confidence</p>
                                            <p class="text-center py-2" style="font-size: 50px;" >{{criterio.chartData.avg_confidence}}</p>
                                          </div>
                                        </div>
                                        <div class="col-6">
                                          <!-- <p class="text-center py-5">...grafico...</p> -->
                                          <div *ngIf="currentProposal.framework.analysis_type == 2" style="width: 100%;" id="chart_feedback_div_{{criterio.title}}"></div>
                                          <div *ngIf="currentProposal.framework.analysis_type == 1">
                                            <p class="text-center" >Average of scores</p>
                                            <p class="text-center py-2" style="font-size: 50px;" >{{criterio.chartData.avg_load}}</p>
                                          </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <mat-form-field class="select-primary col-4" appearance="fill">
                                            <mat-label>Criteria</mat-label>
                                            <mat-select name="criterio_typology{{i}}" [(ngModel)]="criterio.typology" disabled>
                                                <mat-option value="" selected></mat-option>
                                                <mat-option *ngFor="let criteriaType of getCriteriaService().getAllCriteriaTypeForSelect()" [value]="criteriaType.key">{{criteriaType.value}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field class="input-primary col-4 mt-3">
                                            <mat-label>
                                                Critical level {{criterio.indicators.risk}}
                                                <i class="icon-circle_info grey-color ml-2"></i>
                                            </mat-label>
                                            <input matInput name="risk_suggestion{{i}}" [ngModel]="criterio.indicators.risk_suggestion" [ngClass]="criterio.indicators.risk_suggestion != 'Do not modify' ? 'text-danger': ''" readonly>
                                        </mat-form-field>
                                        <mat-form-field class="input-primary col-4 mt-3">
                                            <mat-label>
                                                Confidence level {{criterio.indicators.contradiction}}
                                                <i class="icon-circle_info grey-color ml-2"></i>
                                            </mat-label>
                                            <input matInput name="contradiction_suggestion{{i}}" [ngModel]="criterio.indicators.contradiction_suggestion" readonly>
                                        </mat-form-field>
                                    </div>

                                    <div class="row">

                                      <!-- Tabella Feedback-->
                                        <div class="col-12 px-2" *ngIf="criterio.feedbackData">
                                          <mat-table [dataSource]="criterio.feedbackData" style="width: 100%;">

                                            <!-- Name Column -->
                                            <ng-container matColumnDef="name">
                                              <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
                                              <mat-cell *matCellDef="let element; let i = index">
              <span
                *ngIf="showPrivateInfo; else prova">{{ element.name }}</span>
                                                <ng-template #prova>
                                                  <span>User {{ i + 1 }}</span>
                                                </ng-template>
                                              </mat-cell>

                                            </ng-container>


                                            <!-- Sentiment Column -->
                                            <ng-container matColumnDef="sentiment">
                                              <mat-header-cell *matHeaderCellDef style="justify-content:center">Sentiment</mat-header-cell>
                                              <mat-cell *matCellDef="let element" style="justify-content:center">
                                                <!-- {{element.sentiment}}  -->
                                                <mat-icon aria-hidden="false" *ngIf="element.sentiment == 0.5">sentiment_neutral</mat-icon>
                                                <mat-icon aria-hidden="false" style="color: #00bc1d;" *ngIf="element.sentiment > 0.5">sentiment_satisfied_alt</mat-icon>
                                                <mat-icon aria-hidden="false" style="color: #ff0000;" *ngIf="element.sentiment < 0.5">sentiment_very_dissatisfied</mat-icon>
                                              </mat-cell>
                                            </ng-container>

                                            <!-- Feedback Column -->
                                            <ng-container matColumnDef="feedback">
                                              <mat-header-cell *matHeaderCellDef style="flex: 0 0 50% !important">Feedback </mat-header-cell>
                                              <mat-cell *matCellDef="let element" style="flex: 0 0 50% !important"> {{element.feedback}} </mat-cell>
                                            </ng-container>

                                            <!-- True False Column -->
                                            <ng-container matColumnDef="truefalse" *ngIf="currentProposal.framework.analysis_type == 2">
                                              <mat-header-cell *matHeaderCellDef>True/False</mat-header-cell>
                                              <mat-cell *matCellDef="let element">
                                                <mat-chip-list>
                                                  <mat-chip *ngIf="element.truefalse" style="justify-content:center; background-color: #00bc1d;" class="text-white">True</mat-chip>
                                                  <mat-chip *ngIf="!element.truefalse" style="justify-content:center; background-color: #ff0000;" class="text-white">False</mat-chip>
                                                </mat-chip-list>
                                              </mat-cell>
                                            </ng-container>

                                            <!-- Score Column -->
                                            <ng-container matColumnDef="truefalse" *ngIf="currentProposal.framework.analysis_type == 1">
                                              <mat-header-cell *matHeaderCellDef>Value</mat-header-cell>
                                              <mat-cell *matCellDef="let element">
                                                <mat-chip-list>
                                                  <mat-chip *ngIf="element.load" style="justify-content:center;">{{element.load}}</mat-chip>
                                                </mat-chip-list>
                                              </mat-cell>
                                            </ng-container>

                                            <!-- Confidence Column -->
                                            <ng-container matColumnDef="confidence">
                                              <mat-header-cell *matHeaderCellDef style="justify-content:center">Confidence</mat-header-cell>
                                              <mat-cell *matCellDef="let element" style="justify-content:center">
                                                <mat-chip-list><mat-chip>{{element.confidence}}</mat-chip></mat-chip-list>
                                              </mat-cell>
                                            </ng-container>

                                            <!-- evidence Column -->
                                            <ng-container matColumnDef="evidence">
                                              <mat-header-cell *matHeaderCellDef style="justify-content:center">Evidence</mat-header-cell>
                                              <mat-cell *matCellDef="let element" style="justify-content:center">
                                                <mat-chip-list><mat-chip>{{element.evidence}}</mat-chip></mat-chip-list>
                                              </mat-cell>
                                            </ng-container>

                                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                                          </mat-table>
                                        </div>

                                        <!-- Text Area -->
                                        <!-- <div class="col-12 px-2">
                                            <div class="box flex-column my-0 pb-0 pt-3 px-3 bordered">
                                                <mat-form-field>
                                                    <mat-label>Description</mat-label>
                                                    <textarea class="description" matInput rows="2" name="criterio_description{{i}}"
                                                        placeholder="Add description"></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div> -->
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                        </ng-container>
                    </div>

                    <!-- <div class="container-fluid accordion-container mt-3 accordion-inner">
                        <div class="container">
                            <mat-accordion class="accordion" multi>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            Valore dell'offerta
                                            <i class="icon-circle_info"></i>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-6">
                                            <p class="text-center py-5">...grafico...</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-center py-5">...grafico...</p>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <mat-form-field class="select-primary col-4" appearance="fill">
                                            <mat-label>Criterio</mat-label>
                                            <mat-select required>
                                                <mat-option value="" selected></mat-option>
                                                <mat-option value="">Quantitativo</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field class="input-primary col-4 mt-3">
                                            <mat-label>
                                                Critical level Alto
                                                <i class="icon-circle_info grey-color ml-2"></i>
                                            </mat-label>
                                            <input matInput value="Suggerimento di modifica">
                                        </mat-form-field>
                                        <mat-form-field class="input-primary col-4 mt-3">
                                            <mat-label>
                                                Livello di confidenza basso
                                                <i class="icon-circle_info grey-color ml-2"></i>
                                            </mat-label>
                                            <input matInput value="Allegare più evidenze">
                                        </mat-form-field>
                                    </div>

                                    <div class="row">

                                        <div class="ml-auto my-3">
                                            <button class="size-small" mat-flat-button color="secondary">Modifica</button>
                                        </div>
                                        <div class="col-12 px-2">
                                            <div class="box flex-column my-0 pb-0 pt-3 px-3 bordered">
                                                <mat-form-field>
                                                    <mat-label>Feedback</mat-label>
                                                    <textarea class="description" matInput rows="3" name=""
                                                        placeholder="Inserire feedback"></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div> -->

                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>

    <!-- Blocco 3 - Livello confidenza -->
    <!-- <div class="container-fluid accordion-container">
        <div class="container">
            <mat-accordion class="accordion" multi>
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class="index">3</span>
                            Livello confidenza
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="row pl-4 header-title">
                        <p class="mb-0 mr-2">Time taken to compile</p>
                        <span class="main-color text-uppercase">1 ORA</span>
                    </div>

                    <div class="row">
                        <div class="col-12 px-2">
                            <div class="box px-0 box-two-row">
                                <div class="col-12">
                                    <p class="text-center py-5">...grafico...</p>
                                </div>
                                <div class="box-info mb-4 mx-4">
                                    <i class="icon-warning-triangle float-left col-2 flex-column big-icon grey-color"></i>
                                    <p class="description col-10">Il sistema dovrebbe indicare i punti critici e mostrarli in uno schema tabellare a supporto del grafico precedente</p>
                                </div>
                                <div class="col-12 px-2">
                                    <div class="box flex-column mb-0 pb-0 pt-3 px-3 border-0">
                                        <mat-form-field>
                                            <mat-label>Feedback</mat-label>
                                            <textarea class="description" matInput rows="3" name=""
                                                placeholder="Inserire feedback"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div> -->


    <!-- Buttons -->
    <div class="buttons-container container py-4">
        <button class="button-primary float-left" mat-button (click)="saveAndBack()">
            <i class="icon-Backward-arrow-small"></i>
            Go back
        </button>
        <button class="button-primary float-right" mat-button (click)="saveAndAdd()">
            <i class="icon-Forward-arrow-small"></i>
            Save and add proposal
        </button>
    </div>
</form>

<div id="anonymize-button" (click)="toggleAnonymousProposalInfo()">
  <span *ngIf="showPrivateInfo">Hide private data</span>
  <span *ngIf="!showPrivateInfo">Show private data</span>
</div>
