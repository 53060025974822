<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>Add proposal</span>
    <span class="modal-toolbar-spacer"></span>
    <button mat-icon-button>
      <mat-icon (click)="closeDialogue()">close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<app-new-proposal-blank *ngIf="newProposal" [proposal]="proposalData" (proposalSavedEvent)="closeDialogue()" ></app-new-proposal-blank>
<app-new-proposal *ngIf="!newProposal" [proposal]="proposalData" (proposalSavedEvent)="closeDialogue()"></app-new-proposal>
