import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Decisions Frontend';

  constructor(private router: Router) { }

  ngOnInit(){

    //  fix per impostare lo scale del viewport solo se il dispositivo ha una risoluzione inferiore a 768
    if (window.innerWidth < 768) {
      let viewport = document.querySelector("meta[name=viewport]");
      let content = "width=device-width, initial-scale=1.0";
      if (!viewport) {
        viewport = document.createElement('meta');
        viewport['name'] = "viewport";
        viewport.setAttribute('content', content);
        document.getElementsByTagName('head')[0].appendChild(viewport);
      } else {
        content = "width=768, initial-scale=2.0";
        viewport.setAttribute('content', content);
      }
    }


    // this.router.navigate(['/']);
    // this.router.navigate(['decision']);
    // this.router.navigate(['decision/show']);
    // this.router.navigate(['decision/dashboard']);

    // this.router.navigate(['framework']);
    //this.router.navigate(['add-proposal']);
    //this.router.navigate(['add-proposal-blank']);
    //this.router.navigate(['responses-summary']);
    //this.router.navigate(['responses-weight-target']);
    //this.router.navigate(['responses-weight-criteria']);
    //this.router.navigate(['responses-evaluation']);
    //this.router.navigate(['responses-proposal-review']);
  }
}
