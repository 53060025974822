import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class CriteriaService {

  private _criteria: any = [
    { type: "single" , key: "HIGHESTISTHEBEST", value: 'The higher value is the better'},
    { type: "single" , key: "LOWESTISTHEBEST", value: 'The lowest value is the best'},
    // { type: "bytarget", key: "THECLOSESTTO", value: 'Il più vicino è il migliore'},
  ];

  private _criteriaType: any = [
    { key: "QUANTITATIVE", value: 'Quantitative' },
    { key: "QUALITATIVE", value: 'Qualitative' },
  ]

  constructor() { }


  public criteriaIsQuantitative(criteriaKey: string) {

    if (criteriaKey && criteriaKey == 'QUANTITATIVE')
        return true;

    return false;

  }


  getValueByKey(container, key) {

    let found = "";

    if (key != null && typeof key != "undefined") {
      container.forEach(e => {
        if (e.key.toString().toLowerCase() == key.toString().toLowerCase())
          return found = e.value;
      });
    }

    return found;
  }


  get criteria() {
    return this._criteria.splice(0);
  }

  /**
   * Restituisce tutto l'array per popolare una select
   */
  public getAllCriteriaForSelect() {
    return this._criteria.slice(0);
  }

  public getCriteriaValueByKey(key: string) {

    if (key == null || typeof key == "undefined")
      return '';

    return this.getValueByKey(this._criteria, key);
  }








  get criteriaType() {
    return this._criteriaType.splice(0);
  }

  /**
   * Restituisce tutto l'array per popolare una select
   */
  public getAllCriteriaTypeForSelect() {
    return this._criteriaType.slice(0);
  }


  public getCriteriaTypeValueByKey(key: string) {

    if (key == null || typeof key == "undefined")
      return '';

    return this.getValueByKey(this._criteriaType, key);
  }

}
