import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _organizzations: any = [
    {
      id: 3,
      name: "AOICO"
    }
  ]

  private _professionalRoles: any = [
    {
      id: 1,
      name: "Marketing specialist",

    }
  ];

  constructor() { }


  get organizzations() {
    return this._organizzations.splice(0);
  }

  get professionalRoles() {
    return this._professionalRoles.splice(0);
  }

  /**
   * Restituisce tutto l'array per popolare una select
   */
  public getAllOrganizzations() {
    return this._organizzations.slice(0);
  }

  /**
   * Restituisce tutto l'array per popolare una select
   */
  public getAllProfessionalRoles() {
    return this._professionalRoles.slice(0);
  }

}
