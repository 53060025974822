<mat-toolbar class="container-fluid">
    <div class="links">
        <button mat-button>Terms & Conditions</button>
        <span class="divider"></span>
        <button mat-button>Privacy Policy</button>
    </div>
    <div>© 2020 Butterfly. All rights reserved.</div>
    <div class="logo-container">
        <span>powered by</span>
        <img class="logo" src="../assets/img/butterfly-logo.png"/>
    </div>
</mat-toolbar> 


