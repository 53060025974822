import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-new-proposal-blank',
  templateUrl: './new-proposal-blank.component.html',
  styleUrls: ['./new-proposal-blank.component.scss']
})
export class NewProposalBlankComponent implements OnInit {

  @Input() proposal: any;
  @Output() proposalSavedEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {

    console.log(this.proposal);
  }

}
