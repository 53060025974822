import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DemoService {

  constructor() { }

  get casoSarcomatoidFramework() {
    return JSON.parse(JSON.stringify(this._casoSarcomatoidFramework));
  }

  get casoSarcomatoid() {
    return JSON.parse(JSON.stringify(this._casoSarcomatoid));
  }


  get casoSarcomatoidDashboard() {
    return this._casoSarcomatoidDashboard;
  }

  get projectDemos() {
    return this._projectDemos;
  }




























  get casoYamalFramework() {
    return JSON.parse(JSON.stringify(this._casoYamalFramework));
  }




  get casoYamal() {
    return JSON.parse(JSON.stringify(this._casoYamal));
  }





  get casoYamalDashboard() {
    return this._casoYamalDashboard;
  }

  get tasks() {
    return JSON.parse(JSON.stringify(this._tasks));
  }

  private _tasks: any = [
    {
      id: 20,
      created_at: '2024-03-04T21:15:34.000000Z',
      updated_at: '2024-03-04T21:15:34.000000Z',
      deleted_at: null,
      decision_management_plan_id: 197,
      created_by: 0,
      description: 'Condurre un\'analisi di mercato per identificare opportunità di espansione',
      action: 'Analisi di mercato',
      additional_text: 'research',
      start_date: '2024-03-07',
      end_date: '2024-03-15',
      assigned_user_name: 'Giulia Rossi',
      assigned_user_id: null,
      has_completed: false,
      item_id: null
    },
    {
      id: 21,
      created_at: '2024-03-04T21:15:34.000000Z',
      updated_at: '2024-03-04T21:15:34.000000Z',
      deleted_at: null,
      decision_management_plan_id: 198,
      created_by: 0,
      description: 'Sviluppare un piano di marketing per il lancio del nuovo prodotto',
      action: 'Pianificazione marketing',
      additional_text: 'product launch',
      start_date: '2024-03-10',
      end_date: '2024-03-20',
      assigned_user_name: 'Mario Bianchi',
      assigned_user_id: null,
      has_completed: false,
      item_id: null
    },
    {
      id: 22,
      created_at: '2024-03-04T21:15:34.000000Z',
      updated_at: '2024-03-04T21:15:34.000000Z',
      deleted_at: null,
      decision_management_plan_id: 199,
      created_by: 0,
      description: 'Organizzare una riunione con il team per discutere delle strategie di miglioramento',
      action: 'Riunione strategica',
      additional_text: 'team meeting',
      start_date: '2024-03-08',
      end_date: '2024-03-09',
      assigned_user_name: 'Laura Verdi',
      assigned_user_id: null,
      has_completed: false,
      item_id: null
    },
    {
      id: 23,
      created_at: '2024-03-04T21:15:34.000000Z',
      updated_at: '2024-03-04T21:15:34.000000Z',
      deleted_at: null,
      decision_management_plan_id: 200,
      created_by: 0,
      description: 'Valutare le prestazioni dei dipendenti e fornire feedback',
      action: 'Valutazione delle prestazioni',
      additional_text: 'employee feedback',
      start_date: '2024-03-11',
      end_date: '2024-03-13',
      assigned_user_name: 'Luigi Gialli',
      assigned_user_id: null,
      has_completed: false,
      item_id: null
    },
    {
      id: 24,
      created_at: '2024-03-04T21:15:34.000000Z',
      updated_at: '2024-03-04T21:15:34.000000Z',
      deleted_at: null,
      decision_management_plan_id: 201,
      created_by: 0,
      description: 'Rivedere e ottimizzare i processi di produzione per aumentare l\'efficienza',
      action: 'Ottimizzazione dei processi',
      additional_text: 'production efficiency',
      start_date: '2024-03-12',
      end_date: '2024-03-18',
      assigned_user_name: 'Carlo Neri',
      assigned_user_id: null,
      has_completed: false,
      item_id: null
    },
    {
      id: 25,
      created_at: '2024-03-04T21:15:34.000000Z',
      updated_at: '2024-03-04T21:15:34.000000Z',
      deleted_at: null,
      decision_management_plan_id: 202,
      created_by: 0,
      description: 'Condurre un\'indagine di soddisfazione clienti per migliorare i servizi',
      action: 'Indagine di soddisfazione',
      additional_text: 'customer satisfaction',
      start_date: '2024-03-09',
      end_date: '2024-03-14',
      assigned_user_name: 'Elena Marroni',
      assigned_user_id: null,
      has_completed: false,
      item_id: null
    },
    {
      id: 26,
      created_at: '2024-03-04T21:15:34.000000Z',
      updated_at: '2024-03-04T21:15:34.000000Z',
      deleted_at: null,
      decision_management_plan_id: 203,
      created_by: 0,
      description: 'Elaborare un rapporto sulle tendenze di mercato nel settore tecnologico',
      action: 'Analisi delle tendenze di mercato',
      additional_text: 'tech industry',
      start_date: '2024-03-08',
      end_date: '2024-03-16',
      assigned_user_name: 'Giovanni Rosso',
      assigned_user_id: null,
      has_completed: false,
      item_id: null
    },
    {
      id: 27,
      created_at: '2024-03-04T21:15:34.000000Z',
      updated_at: '2024-03-04T21:15:34.000000Z',
      deleted_at: null,
      decision_management_plan_id: 204,
      created_by: 0,
      description: 'Pianificare e coordinare una campagna pubblicitaria su social media',
      action: 'Campagna pubblicitaria',
      additional_text: 'social media',
      start_date: '2024-03-10',
      end_date: '2024-03-21',
      assigned_user_name: 'Francesca Blu',
      assigned_user_id: null,
      has_completed: false,
      item_id: null
    }
  ];

  private _casoSarcomatoidFramework: any = {
    framework_name: 'Oncologia ORL',
    organization_id: 3,
    analysis_type: 2,
    sectors: [{sector_key: 'MEDICINE', subsector_key: 'OTORHINOLARYNGOLOGY'}],
    purpose_description: 'Analisi di un caso clinico di sarcomatoid larynx.',
    primary_target: {type: 'primary', title: 'Increase the patient\'s chances of recovery ', evaluation_criteria: 'HIGHESTISTHEBEST'},
    secondary_targets: [],
    criteria: [
      { order: 1, title: 'Anamnesis', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: '54 year old male patient \ n Heavy smoker, alcoholic drinker \ n Worsening dysphonia for over a year \ n Co-morbidities: \ n • arterial hypertension \ n • psoriasis \ n August 2016: emergency tracheostomy for dyspnea; contextual diagnostic micro laryngoscopy: hypoglottic glottic laryngeal neoformation which on biopsy examination results: poorly differentiated squamous cell carcinoma (spindle cell carcinoma) G3.',
        feedbackData: [
          { name: 'Maurizio Vigili', sentiment: 0.5, feedback: 'essential data clinical presentation ok- What were the general conditions? How many years a strong smoker', truefalse: true, confidence: 85, evidence: 'none' },
          { name: 'Francesco Cariti', sentiment: 0.8, feedback: 'shareable approach to both the urgency and the concomitant diagnostic need. Spindle cell frequent in heavy smokers and drinkers', truefalse: true, confidence: 100, evidence: 'none' },
          { name: 'Franco Ionna', sentiment: 0.2, feedback: 'I agree with the procedure as it was urgent. I would have done the same thing', truefalse: true, confidence: 90, evidence: 'none' },
        ],
        evidenceData: [],
        indicators: {
          risk: 'Low',
          risk_suggestion: 'Do not modify',
          contradiction: 'Low',
          contradiction_suggestion: '-',
          contradiction_alert: false
        },
        chartData:
          {
            certainty: 95,
            contradition: 3,
            agree: 3,
            disagree: 0,
            none: 0
          }
      },
      { order: 2, title: 'Staging', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: '•	Endoscopy: left glottic-hypoglottic carcinoma with extended cordo-commissural involvement in the hypoglottis; preserved laryngeal motility \ n • MRI and CT larynx and total body with contrast medium: left glottic-hypoglottic carcinoma, without involvement of the paraglottic space and / or laryngeal cartilage skeleton; no metastatic adenopathies; not second T or distant metastases',
        feedbackData: [
          { name: 'Maurizio Vigili', sentiment: 1.0, feedback: 'The detail of the hypoglottic extension in the plane passing through the upper border of the cricoid cartilage is missing. From the images unsafe cleavage from the thyroid cartilage', truefalse: true, confidence: 95, evidence: 'none' },
          { name: 'Francesco Cariti', sentiment: 1.0, feedback: 'Positive FB for choice of investigations and indication of the specific radiological points to be examined', truefalse: true, confidence: 100, evidence: 'none' },
          { name: 'Franco Ionna', sentiment: 1.0, feedback: 'OK for me! Having already done the biopsy examination, videolaryngoscope, MRI and CT are necessary and adequate for therapeutic planning. I would have limited neck and chest imaging tests', truefalse: true, confidence: 85, evidence: 'none' },
        ],
        evidenceData: [
          {
            type: 'crop_original',
            name: 'Allegato 1',
            link: 'asset/54/0'
          },
          {
            type: 'crop_original',
            name: 'Allegato 2',
            link: 'asset/55/0'
          },
          {
            type: 'crop_original',
            name: 'Allegato 3',
            link: 'asset/56/0'
          },
          {
            type: 'ondemand_video',
            name: 'Caso Clinico Dr. Ciniglio_valutazione preoperatoria',
            link: 'https://www.youtube.com/embed/Ibjy42pjEew'
          },
        ],
        indicators: {
          risk: 'Low',
          risk_suggestion: 'Do not modify',
          contradiction: 'Low',
          contradiction_suggestion: '-',
          contradiction_alert: false
        },
        chartData:
          {
            certainty: 95,
            contradition: 3,
            agree: 3,
            disagree: 0,
            none: 0
          }
      },
      { order: 3, title: 'Clinical stage', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'spindle cell carcinoma G3 of the glottic-hypoglottic larynx in stage cT2, cN0, cM0 - St.2 ° ',
        feedbackData: [
          { name: 'Maurizio Vigili', sentiment: 0.5, feedback: 'histological images are missing', truefalse: false, confidence: 75, evidence: 'none' },
          { name: 'Francesco Cariti', sentiment: 0.5, feedback: '', truefalse: true, confidence: 100, evidence: 'none' },
          { name: 'Franco Ionna', sentiment: 1.0, feedback: 'Complex histotype that can offer discussion points in relation to the therapeutic decision', truefalse: true, confidence: 92, evidence: 'none' },
        ],
        evidenceData: [],
        indicators: {
          risk: 'Medio',
          risk_suggestion: 'To be checked',
          contradiction: 'Low',
          contradiction_suggestion: '-',
          contradiction_alert: false
        },
        chartData:
          {
            certainty: 51,
            contradition: 12,
            agree: 2,
            disagree: 1,
            none: 0
          }
      },
      { order: 4, title: 'Clinical considerations', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: '1. Involvement of the glottic-hypoglottic subsites \ n 2. Histotype poorly responsive to chemotherapy \ n 3. Need to irradiate the median compartment and the mediastinum due to the presence of tracheostoma1',
        feedbackData: [
          { name: 'Maurizio Vigili', sentiment: 1.0, feedback: 'correct therapeutic strategy setting - for me the decision on complementary radiotherapy after definitive histological examination I agree on the surgical strategy', truefalse: true, confidence: 75, evidence: 'none' },
          { name: 'Francesco Cariti', sentiment: 0.5, feedback: 'Need to treat N over T due to the risk of occult metastases.', truefalse: true, confidence: 50, evidence: 'none' },
          { name: 'Franco Ionna', sentiment: 1.0, feedback: 'I would have discussed the case in Multidisciplinary to have greater strength and objectivity in the decision on the choice of treatment. Have an opinion on the possible use of biological drugs in association with RT even if I agree with the therapeutic choice as indicated in the following paragraph.', truefalse: true, confidence: 80, evidence: 'none' },
        ],
        evidenceData: [],
        indicators: {
          risk: 'Low',
          risk_suggestion: 'Do not modify',
          contradiction: 'Low',
          contradiction_suggestion: '-',
          contradiction_alert: false
        },
        chartData:
          {
            certainty: 72,
            contradition: 4,
            agree: 3,
            disagree: 0,
            none: 0
          }
      },
      { order: 5, title: 'Therapeutic Choice', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: '• Partial endoscopic surgery on T (5th type D cordectomy according to ELS) after vestibulectomy with pathological stage pT2, R0. \ n • Adjuvant radiotherapy (IMRT) on T, median (6th-7th level) and lateral (N) compartment',
        feedbackData: [
          { name: 'Maurizio Vigili', sentiment: 0.5, feedback: 'decision for LASER after intraoperative staging with angled optics (subglottic extension?) and intraop evaluation of the easy detachment plan from the internal perichondrium of the thyroid cartilage. HISTOLOGICAL on Margins?', truefalse: false, confidence: 50, evidence: 'none' },
          { name: 'Francesco Cariti', sentiment: 0.5, feedback: 'Procedure implemented (TOLS + RT) that can be shared, however, considering the risks and complications of a possible reoperation in the event of recurrence, not infrequent *, on T or N on an already irradiated neck / larynx Alternatively reconstructive surgery (variably extended based on T in hypoglottis) with l-c emptying; Adjuvant RT to open surgery at IE received and only in the presence of risk factors. * Am J Surg Pathol. 2002 Feb; 26 (2): 153-70. Spindle cell (sarcomatoid) carcinomas of the larynx: a clinicopathologic study of 187 cases. Thompson LD1, Wieneke JA, Miettinen M, Heffner DK.', truefalse: true, confidence: 50, evidence: 'none' },
          { name: 'Franco Ionna', sentiment: 0.5, feedback: 'I agree with the choice. However, the feasibility of an endoscopic conservative surgery should be discussed since the patient has already had a tracheotomy.', truefalse: true, confidence: 80, evidence: 'none' },
        ],
        evidenceData: [
          {
            type: 'ondemand_video',
            name: 'Caso Clinico Dr. Ciniglio_valutazione post-operatoria',
            link: 'https://www.youtube.com/embed/RFVPDXSTACU'
          }
        ],
        indicators: {
          risk: 'High',
          risk_suggestion: 'To be checked',
          contradiction: 'Low',
          contradiction_suggestion: '-',
          contradiction_alert: true
        },
        chartData:
          {
            certainty: 45,
            contradition: 19,
            agree: 2,
            disagree: 1,
            none: 0
          }
      },
      { order: 6, title: 'ConsideConcluding remarks and follow-up', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: '• Choice of laser endoscopic exeresis rather than an open treatment on T and N dictated by \ n or width of the endoscopic margins similar to that obtainable with OPSL type II (supra-cricoid) for hypoglottic extension3-4 \ n or Adjuvant radiotherapy however necessary for the necessity to irradiate the tracheostoma',
        feedbackData: [
          { name: 'Maurizio Vigili', sentiment: 1.0, feedback: 'endoscopic choice if not contraindications (see above) always preferable to chir open for rapid functional recovery - The patient\'s condition and family support environment also count in the evaluation of the choice.', truefalse: true, confidence: 90, evidence: 'none' },
          { name: 'Francesco Cariti', sentiment: 1.0, feedback: 'In the treatment performed, adjuvant RT choice is necessary due to the risk of occult lymph node metastases. Standard follow up.', truefalse: true, confidence: 50, evidence: 'none' },
          { name: 'Franco Ionna', sentiment: 1.0, feedback: 'Ok!	', truefalse: true, confidence: 75, evidence: 'none' },
        ],
        evidenceData: [],
        indicators: {
          risk: 'Low',
          risk_suggestion: 'Do not modify',
          contradiction: 'Low',
          contradiction_suggestion: '-',
          contradiction_alert: false
        },
        chartData:
          {
            certainty: 75,
            contradition: 3,
            agree: 3,
            disagree: 0,
            none: 0
          }
      }
    ],
    constraints: [],
    options_role: null,
    options_role_required: null,
    roles: []
  };
  private _casoSarcomatoid: any = {
    title: 'Manage a decision: Sarcomatoid larynx - AOICO',
    closing_date: '31 September',
    evaluation_sessions: [
      {id: 1, decision_start: '18 September 2022 19:47:59', decision_end: '25 September 2022 07:20:23', tracker: false}
    ],
    suggestions: [
      [{title: 'Attention, the proposal presents a critical decision-making node: Therapeutic choice ', description: 'It is advisable to revise this point. Participants have provided feedback, it is recommended that you use that feedback to review the point before finalizing your decision.'}],
    ],
    proposals: [
      {
        title: 'Case resolution strategy', proposed_by: 'Doctor Ciniglio', proposed_by_role: 'Requester', proposed_by_avatar: 'https://s3-eu-west-1.amazonaws.com/miodottore.it/doctor/cec5b8/cec5b8119e65cb40ede68a5284683e84_large.jpg', creation_session: 'Start table', when_evaluated: 'Evaluation Session 1', score: '5', score_value: '6', result: 'CHANGE', suggestion: 'Questa proposata presenta un nodo decisionale critico',
        framework: this.casoSarcomatoidFramework
      },
    ],
    team_members: [
      {
        username: 'Maurizio Vigili',
        email: 'mg.vigili@virgilio.it',
        organization_professional_role_id: null,
        process_role: 'CONSULTED',
        complete_process_enabled: true,
        review_enabled: true,
        provide_new_proposal_enabled: false,
        weight: 100
      },
      {
        username: 'Franco Ionna',
        email: 'f.ionna@istitutotumori.na.it',
        organization_professional_role_id: null,
        process_role: 'CONSULTED',
        complete_process_enabled: true,
        review_enabled: true,
        provide_new_proposal_enabled: false,
        weight: 100
      },
      {
        username: 'Francesco Cariti',
        email: 'francescocariti@yahoo.it',
        organization_professional_role_id: null,
        process_role: 'CONSULTED',
        complete_process_enabled: true,
        review_enabled: true,
        provide_new_proposal_enabled: false,
        weight: 100
      },
      {
        username: 'Enzo Troncone',
        email: 'enzo@buterflysrl.com',
        organization_professional_role_id: null,
        process_role: 'ACCOUNTABLE',
        complete_process_enabled: true,
        review_enabled: true,
        provide_new_proposal_enabled: false,
        weight: null
      },
    ]
  };
  private _casoSarcomatoidDashboard: any = {
    evaluation_sessions: [
      {id: 1, decision_start: '18 September 2022 19:47:59', decision_end: '25 September 2022 07:20:23', tracker: false}
    ],
    analysis_type: 2,
    feedbackData: [
      {
        expert: 'Maurizio Vigili',
        answers: true,
        time: '4 hours',
        cost: '250 €',
        total_cost: '1000 €',
        evaluation_session: '1',
        new_proposal: false,
        evidence: false,
      },
      {
        expert: 'Franco Ionna',
        answers: true,
        time: '2 hours',
        cost: '250 €',
        total_cost: '500 €',
        evaluation_session: '1',
        new_proposal: false,
        evidence: false
      },
      {
        expert: 'Francesco Cariti',
        answers: true,
        time: '3 hours',
        cost: '250 €',
        total_cost: '750 €',
        evaluation_session: '1',
        new_proposal: false,
        evidence: false
      }
    ],
    criteriaData: [
      {
        title: 'Anamnesis',
        accept: true,
        vote: {
          graph_vote_yes: 100,
          graph_vote_no: 0,
          graph_vote_none: 0,
        },
        risk: 95,
        contradiction: 3
      },
      {
        title: 'Staging',
        accept: true,
        vote: {
          graph_vote_yes: 100,
          graph_vote_no: 0,
          graph_vote_none: 0,
        },
        risk: 95,
        contradiction: 3
      },
      {
        title: 'Clinical stage',
        accept: true,
        vote: {
          graph_vote_yes: 67,
          graph_vote_no: 33,
          graph_vote_none: 0,
        },
        risk: 51,
        contradiction: 12
      },
      {
        title: 'Clinical considerations',
        accept: true,
        vote: {
          graph_vote_yes: 100,
          graph_vote_no: 0,
          graph_vote_none: 0,
        },
        risk: 72,
        contradiction: 4
      },
      {
        title: 'Therapeutic Choice',
        accept: false,
        vote: {
          graph_vote_yes: 67,
          graph_vote_no: 33,
          graph_vote_none: 0,
        },
        risk: 45,
        contradiction: 19
      },
      {
        title: 'Considerazioni conclusive e follow-up',
        accept: true,
        vote: {
          graph_vote_yes: 100,
          graph_vote_no: 0,
          graph_vote_none: 0,
        },
        risk: 75,
        contradiction: 3
      }
    ]
  };
  private _casoYamalFramework: any = {
    framework_name: 'Construction Strategy',
    organization_id: 3,
    analysis_type: 1,
    sectors: [{sector_key: 'ENGINEERING', subsector_key: 'INDUSTRIALENGINEERING'}],
    purpose_description: 'This framework is helping you in deciding a proper construction strategy of industrial plant in a severe conditions environment and to evaluate advantage of modularization approach.',
    primary_target: {type: 'primary', title: 'Project Success', evaluation_criteria: 'HIGHESTISTHEBEST'},
    secondary_targets: [
      { type: 'secondary', title: 'Cost', evaluation_criteria: 'LOWESTISTHEBEST'},
      { type: 'secondary', title: 'Delivery', evaluation_criteria: 'LOWESTISTHEBEST'},
      { type: 'secondary', title: 'Quality', evaluation_criteria: 'HIGHESTISTHEBEST'},
      { type: 'secondary', title: 'Risk', evaluation_criteria: 'HIGHESTISTHEBEST'},
    ],
    criteria: [
      { order: 1, title: 'Site conditions', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'Paziente di sesso maschile di 54 aa \n Forte fumatore, bevitore di alcolici \n Disfonia ingravescente da oltre un anno \n Co-morbidità: \n •	ipertensione arteriosa \n •	psoriasi \n Agosto 2016: tracheotomia d’urgenza per dispnea; micro laringoscopia contestuale diagnostica: neoformazione laringea glottica ipoglottica che ad esame bioptico risulta: carcinoma squamoso a cellule fusate  (spindle cell carcinoma) scarsamente differenziato G3.',
        feedbackData: [
          { name: 'Maurizio Vigili', sentiment: 0.5, feedback: 'dati essenziali presentazione clinica ok- Quali erano le condizioni generali? Da quanti anni forte fumatore', truefalse: true, confidence: 85, evidence: 'none' },
          { name: 'Francesco Cariti', sentiment: 0.8, feedback: 'approccio condivisibile sia relativo all’urgenza che alla concomitante necessità diagnostica. Spindle cell frequente in forti fumatori e bevitori', truefalse: true, confidence: 100, evidence: 'none' },
          { name: 'Franco Ionna', sentiment: 0.2, feedback: 'Sono d\'accordo sulla procedura visto che si trattava di urgenza. Avrei fatto la stessa cosa', truefalse: true, confidence: 90, evidence: 'none' },
        ],
        evidenceData: [],
        indicators: {
          risk: 'Low',
          risk_suggestion: 'Do not modify',
          contradiction: 'Low',
          contradiction_suggestion: '-',
          contradiction_alert: false
        },
        chartData:
          {
            certainty: 95,
            contradition: 3,
            agree: 3,
            disagree: 0,
            none: 0
          }
      },
      { order: 2, title: 'Project Schedule', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: '•	Endoscopia: carcinoma glottico-ipoglottico sinistro con interessamento cordo-commissurale esteso in ipoglottide; motilità laringea conservata \n •	RM e TC laringe e total body con mdc: carcinoma glottico-ipoglottico sinistro, senza interessamento dello spazio paraglottico e/o dello scheletro cartilagineo laringeo; non adenopatie metastatiche; non secondi T o metastasi a distanza',
        feedbackData: [
          { name: 'Maurizio Vigili', sentiment: 1.0, feedback: 'Manca dettaglio estensione ipoglottica in rapporto piano passante bordo superiore cartilagine cricoide . Dalle immagini non sicuro clivaggio dalla cartilagine tiroide', truefalse: true, confidence: 95, evidence: 'none' },
          { name: 'Francesco Cariti', sentiment: 1.0, feedback: 'FB positivo per scelta di indagini ed indicazione dei punti radiologici specifici da esaminare', truefalse: true, confidence: 100, evidence: 'none' },
          { name: 'Franco Ionna', sentiment: 1.0, feedback: 'Ok per me! Avendo già fatto l\'esame bioptico , la videolaringoscpia , la RMN e la TC sono necessari ed adeguati per una pianificazione terapeutica. Avrei limitato gli esami di imaging al collo e torace', truefalse: true, confidence: 85, evidence: 'none' },
        ],
        evidenceData: [
          {
            type: 'crop_original',
            name: 'Allegato 1',
            link: 'asset/54/0'
          },
          {
            type: 'crop_original',
            name: 'Allegato 2',
            link: 'asset/55/0'
          },
          {
            type: 'crop_original',
            name: 'Allegato 3',
            link: 'asset/56/0'
          },
          {
            type: 'ondemand_video',
            name: 'Caso Clinico Dr. Ciniglio_valutazione preoperatoria',
            link: 'https://www.youtube.com/embed/Ibjy42pjEew'
          },
        ],
        indicators: {
          risk: 'Low',
          risk_suggestion: 'Do not modify',
          contradiction: 'Low',
          contradiction_suggestion: '-',
          contradiction_alert: false
        },
        chartData:
          {
            certainty: 95,
            contradition: 3,
            agree: 3,
            disagree: 0,
            none: 0
          }
      },
      { order: 3, title: 'Installation and Erection on Site', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'spindle cell carcinoma G3 della laringe glottico-ipoglottico in stadio cT2, cN0, cM0 – St.2° ',
        feedbackData: [
          { name: 'Maurizio Vigili', sentiment: 0.5, feedback: 'mancano immagini istologiche', truefalse: false, confidence: 75, evidence: 'none' },
          { name: 'Francesco Cariti', sentiment: 0.5, feedback: '', truefalse: true, confidence: 100, evidence: 'none' },
          { name: 'Franco Ionna', sentiment: 1.0, feedback: 'Istotipo complesso che può offrire spunti di discussione in relazione alla decisione terapeutica', truefalse: true, confidence: 92, evidence: 'none' },
        ],
        evidenceData: [],
        indicators: {
          risk: 'Medio',
          risk_suggestion: 'To be checked',
          contradiction: 'Low',
          contradiction_suggestion: '-',
          contradiction_alert: false
        },
        chartData:
          {
            certainty: 51,
            contradition: 12,
            agree: 2,
            disagree: 1,
            none: 0
          }
      },
      { order: 4, title: 'Integration', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: '1.	Interessamento delle sottosedi  glottico-ipoglottica \n 2.	Istotipo scarsamente responsivo alla chemioterapia \n 3.	Necessità di irradiare il compartimento mediano ed il mediastino per la presenza di tracheostoma1',
        feedbackData: [
          { name: 'Maurizio Vigili', sentiment: 1.0, feedback: 'impostazione strategia terapeutica corretta - per me decisione sulla Radioterapia complementare dopo esame istologico definitivo Concordo strategia chirurgica', truefalse: true, confidence: 75, evidence: 'none' },
          { name: 'Francesco Cariti', sentiment: 0.5, feedback: 'Necessità di trattare l\'N oltre il T per rischio di metastasi occulte.', truefalse: true, confidence: 50, evidence: 'none' },
          { name: 'Franco Ionna', sentiment: 1.0, feedback: 'Avrei discusso il caso in Multidisciplinare per avere maggiore forza ed obbiettività nella decisione della scelta di trattamento. Avere un parere sull\'eventuale utilizzo di farmaci biologici in associazione ad RT anche se concordo con la scelta terapeutica come indicata nel successivo paragrafo.', truefalse: true, confidence: 80, evidence: 'none' },
        ],
        evidenceData: [],
        indicators: {
          risk: 'Low',
          risk_suggestion: 'Do not modify',
          contradiction: 'Low',
          contradiction_suggestion: '-',
          contradiction_alert: false
        },
        chartData:
          {
            certainty: 72,
            contradition: 4,
            agree: 3,
            disagree: 0,
            none: 0
          }
      },
      { order: 5, title: 'Project Management Plan', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: '•	Chirurgia parziale endoscopica su T (cordectomia di 5°tipo D sec. ELS) previa vestibulectomia con stadio patologico pT2, R0. \n •	Radioterapia adiuvante (IMRT) su T, compartimento mediano (6°-7° liv.) e laterale (N)',
        feedbackData: [
          { name: 'Maurizio Vigili', sentiment: 0.5, feedback: 'decisione per LASER dopo stadiazione intraoperatoria con ottiche angolate ( estensione sottoglottica?) e valutazione intraop piano di scollamento agevole dal pericondrio interno cartilagine tiroidea . ISTOLOGICO sui Margini?', truefalse: false, confidence: 50, evidence: 'none' },
          { name: 'Francesco Cariti', sentiment: 0.5, feedback: 'Procedura attuata (TOLS + RT) condivisibile, considerando comunque i rischi e complicanze di un eventuale reintervento in caso di recidiva, non infrequente *, su T o N su un collo/laringe già irradiati In alternativa chirurgia ricostruttiva (variabilmente estesa in base al T in ipoglottide) con svuotamento l-c; RT adiuvante a chirurgia open ad EI pervenuto e solo in presenza di fattori di rischio. *Am J Surg Pathol. 2002 Feb;26(2):153-70. Spindle cell (sarcomatoid) carcinomas of the larynx: a clinicopathologic study of 187 cases. Thompson LD1, Wieneke JA, Miettinen M, Heffner DK.', truefalse: true, confidence: 50, evidence: 'none' },
          { name: 'Franco Ionna', sentiment: 0.5, feedback: 'Concordo con la scelta. Valgono le considerazioni precedenti !Purtuttavia va discussa la fattibilità di una chirurgia conservativa endoscopica avendo già il paziente una tracheotomia.', truefalse: true, confidence: 80, evidence: 'none' },
        ],
        evidenceData: [
          {
            type: 'ondemand_video',
            name: 'Caso Clinico Dr. Ciniglio_valutazione post-operatoria',
            link: 'https://www.youtube.com/embed/RFVPDXSTACU'
          }
        ],
        indicators: {
          risk: 'High',
          risk_suggestion: 'To be checked',
          contradiction: 'Low',
          contradiction_suggestion: '-',
          contradiction_alert: true
        },
        chartData:
          {
            certainty: 45,
            contradition: 19,
            agree: 2,
            disagree: 1,
            none: 0
          }
      },
      { order: 6, title: 'Logistic Philosophy', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: '•	Scelta di exeresi endoscopica laser piuttosto di un trattamento open su T ed N dettata da \n o	ampiezza dei margini endoscopici analoga a quella ottenibile con OPSL tipo II (sovra-cricoidea) per estensione ipoglottica3-4 \n o	Radioterapia adiuvante comunque necessaria per la necessità di irradiare il tracheostoma',
        feedbackData: [
          { name: 'Maurizio Vigili', sentiment: 1.0, feedback: 'scelta endoscopica se non controindicazioni ( vedi sopra) sempre preferibile a chir open per rapidità recupero funzionale - Nella valutazione della scelta contano anche le condizioni del paziente e l\'ambiente di supporto familiare..', truefalse: true, confidence: 90, evidence: 'none' },
          { name: 'Francesco Cariti', sentiment: 1.0, feedback: 'Nel trattamento eseguito, scelta RT adiuvante necessaria per rischio di metastasi linfonodali occulte. Follow up standard.', truefalse: true, confidence: 50, evidence: 'none' },
          { name: 'Franco Ionna', sentiment: 1.0, feedback: 'Ok!	', truefalse: true, confidence: 75, evidence: 'none' },
        ],
        evidenceData: [],
        indicators: {
          risk: 'Low',
          risk_suggestion: 'Do not modify',
          contradiction: 'Low',
          contradiction_suggestion: '-',
          contradiction_alert: false
        },
        chartData:
          {
            certainty: 75,
            contradition: 3,
            agree: 3,
            disagree: 0,
            none: 0
          }
      },
      { order: 7, title: 'HSE Operation', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: '•	Scelta di exeresi endoscopica laser piuttosto di un trattamento open su T ed N dettata da \n o	ampiezza dei margini endoscopici analoga a quella ottenibile con OPSL tipo II (sovra-cricoidea) per estensione ipoglottica3-4 \n o	Radioterapia adiuvante comunque necessaria per la necessità di irradiare il tracheostoma',
        feedbackData: [
          { name: 'Maurizio Vigili', sentiment: 1.0, feedback: 'scelta endoscopica se non controindicazioni ( vedi sopra) sempre preferibile a chir open per rapidità recupero funzionale - Nella valutazione della scelta contano anche le condizioni del paziente e l\'ambiente di supporto familiare..', truefalse: true, confidence: 90, evidence: 'none' },
          { name: 'Francesco Cariti', sentiment: 1.0, feedback: 'Nel trattamento eseguito, scelta RT adiuvante necessaria per rischio di metastasi linfonodali occulte. Follow up standard.', truefalse: true, confidence: 50, evidence: 'none' },
          { name: 'Franco Ionna', sentiment: 1.0, feedback: 'Ok!	', truefalse: true, confidence: 75, evidence: 'none' },
        ],
        evidenceData: [],
        indicators: {
          risk: 'Low',
          risk_suggestion: 'Do not modify',
          contradiction: 'Low',
          contradiction_suggestion: '-',
          contradiction_alert: false
        },
        chartData:
          {
            certainty: 75,
            contradition: 3,
            agree: 3,
            disagree: 0,
            none: 0
          }
      },
      { order: 8, title: 'Procurement Management Strategy', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: '•	Scelta di exeresi endoscopica laser piuttosto di un trattamento open su T ed N dettata da \n o	ampiezza dei margini endoscopici analoga a quella ottenibile con OPSL tipo II (sovra-cricoidea) per estensione ipoglottica3-4 \n o	Radioterapia adiuvante comunque necessaria per la necessità di irradiare il tracheostoma',
        feedbackData: [
          { name: 'Maurizio Vigili', sentiment: 1.0, feedback: 'scelta endoscopica se non controindicazioni ( vedi sopra) sempre preferibile a chir open per rapidità recupero funzionale - Nella valutazione della scelta contano anche le condizioni del paziente e l\'ambiente di supporto familiare..', truefalse: true, confidence: 90, evidence: 'none' },
          { name: 'Francesco Cariti', sentiment: 1.0, feedback: 'Nel trattamento eseguito, scelta RT adiuvante necessaria per rischio di metastasi linfonodali occulte. Follow up standard.', truefalse: true, confidence: 50, evidence: 'none' },
          { name: 'Franco Ionna', sentiment: 1.0, feedback: 'Ok!	', truefalse: true, confidence: 75, evidence: 'none' },
        ],
        evidenceData: [],
        indicators: {
          risk: 'Low',
          risk_suggestion: 'Do not modify',
          contradiction: 'Low',
          contradiction_suggestion: '-',
          contradiction_alert: false
        },
        chartData:
          {
            certainty: 75,
            contradition: 3,
            agree: 3,
            disagree: 0,
            none: 0
          }
      },
      { order: 9, title: 'Engineering Design', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: '•	Scelta di exeresi endoscopica laser piuttosto di un trattamento open su T ed N dettata da \n o	ampiezza dei margini endoscopici analoga a quella ottenibile con OPSL tipo II (sovra-cricoidea) per estensione ipoglottica3-4 \n o	Radioterapia adiuvante comunque necessaria per la necessità di irradiare il tracheostoma',
        feedbackData: [
          { name: 'Maurizio Vigili', sentiment: 1.0, feedback: 'scelta endoscopica se non controindicazioni ( vedi sopra) sempre preferibile a chir open per rapidità recupero funzionale - Nella valutazione della scelta contano anche le condizioni del paziente e l\'ambiente di supporto familiare..', truefalse: true, confidence: 90, evidence: 'none' },
          { name: 'Francesco Cariti', sentiment: 1.0, feedback: 'Nel trattamento eseguito, scelta RT adiuvante necessaria per rischio di metastasi linfonodali occulte. Follow up standard.', truefalse: true, confidence: 50, evidence: 'none' },
          { name: 'Franco Ionna', sentiment: 1.0, feedback: 'Ok!	', truefalse: true, confidence: 75, evidence: 'none' },
        ],
        evidenceData: [],
        indicators: {
          risk: 'Low',
          risk_suggestion: 'Do not modify',
          contradiction: 'Low',
          contradiction_suggestion: '-',
          contradiction_alert: false
        },
        chartData:
          {
            certainty: 75,
            contradition: 3,
            agree: 3,
            disagree: 0,
            none: 0
          }
      },
      { order: 10, title: 'Modularization Site Selection', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: '•	Scelta di exeresi endoscopica laser piuttosto di un trattamento open su T ed N dettata da \n o	ampiezza dei margini endoscopici analoga a quella ottenibile con OPSL tipo II (sovra-cricoidea) per estensione ipoglottica3-4 \n o	Radioterapia adiuvante comunque necessaria per la necessità di irradiare il tracheostoma',
        feedbackData: [
          { name: 'Maurizio Vigili', sentiment: 1.0, feedback: 'scelta endoscopica se non controindicazioni ( vedi sopra) sempre preferibile a chir open per rapidità recupero funzionale - Nella valutazione della scelta contano anche le condizioni del paziente e l\'ambiente di supporto familiare..', truefalse: true, confidence: 90, evidence: 'none' },
          { name: 'Francesco Cariti', sentiment: 1.0, feedback: 'Nel trattamento eseguito, scelta RT adiuvante necessaria per rischio di metastasi linfonodali occulte. Follow up standard.', truefalse: true, confidence: 50, evidence: 'none' },
          { name: 'Franco Ionna', sentiment: 1.0, feedback: 'Ok!	', truefalse: true, confidence: 75, evidence: 'none' },
        ],
        evidenceData: [],
        indicators: {
          risk: 'Low',
          risk_suggestion: 'Do not modify',
          contradiction: 'Low',
          contradiction_suggestion: '-',
          contradiction_alert: false
        },
        chartData:
          {
            certainty: 75,
            contradition: 3,
            agree: 3,
            disagree: 0,
            none: 0
          }
      },
      { order: 11, title: 'Time', measure_unit: 'MONTH', evaluation: 'HIGHESTISTHEBEST', typology: 'QUANTITATIVE', description: '•	Scelta di exeresi endoscopica laser piuttosto di un trattamento open su T ed N dettata da \n o	ampiezza dei margini endoscopici analoga a quella ottenibile con OPSL tipo II (sovra-cricoidea) per estensione ipoglottica3-4 \n o	Radioterapia adiuvante comunque necessaria per la necessità di irradiare il tracheostoma',
        feedbackData: [
          { name: 'Maurizio Vigili', sentiment: 1.0, feedback: 'scelta endoscopica se non controindicazioni ( vedi sopra) sempre preferibile a chir open per rapidità recupero funzionale - Nella valutazione della scelta contano anche le condizioni del paziente e l\'ambiente di supporto familiare..', truefalse: true, confidence: 90, evidence: 'none' },
          { name: 'Francesco Cariti', sentiment: 1.0, feedback: 'Nel trattamento eseguito, scelta RT adiuvante necessaria per rischio di metastasi linfonodali occulte. Follow up standard.', truefalse: true, confidence: 50, evidence: 'none' },
          { name: 'Franco Ionna', sentiment: 1.0, feedback: 'Ok!	', truefalse: true, confidence: 75, evidence: 'none' },
        ],
        evidenceData: [],
        indicators: {
          risk: 'Low',
          risk_suggestion: 'Do not modify',
          contradiction: 'Low',
          contradiction_suggestion: '-',
          contradiction_alert: false
        },
        chartData:
          {
            certainty: 75,
            contradition: 3,
            agree: 3,
            disagree: 0,
            none: 0
          }
      }
    ],
    constraints: [],
    options_role: null,
    options_role_required: null,
    roles: []
  };
  private _casoYamal: any = {
    title: 'Manage a decision: DM-YAMAL-STRATEGY - PMI',
    closing_date: '11 SEPTEMBER',
    evaluation_sessions: [
      {id: 1, decision_start: '25 August 2022 13:10:09', decision_end: '10 September 2022 07:20:23', tracker: false}
    ],
    suggestions: [
      [{title: 'Attention, it is advisable to accept the \'Modularization\' proposal and possibly use the feedback to improve the proposal in the \'Logistic Philosophy\' section.'}],
    ],
    proposals: [
      {
        title: 'Modularizzation', proposed_by: 'Enzo Troncone', proposed_by_role: 'Accountable', proposed_by_avatar: 'https://pbs.twimg.com/profile_images/1246785644371771393/6g1yXzF5_400x400.jpg', creation_session: 'Start table', when_evaluated: 'Evaluation Session 1', score: '82', score_value: '100', result: 'GOOD', suggestion: 'PROPOSAL TO BE ACCEPTED',
        framework: {
          framework_name: 'Construction Strategy',
          organization_id: 3,
          analysis_type: 1,
          sectors: [{sector_key: 'ENGINEERING', subsector_key: 'INDUSTRIALENGINEERING'}],
          purpose_description: 'This framework is helping you in deciding a proper construction strategy of industrial plant in a severe conditions environment and to evaluate advantage of modularization approach.',
          primary_target: {type: 'primary', title: 'Project Success', evaluation_criteria: 'HIGHESTISTHEBEST'},
          secondary_targets: [
            { type: 'secondary', title: 'Cost', evaluation_criteria: 'LOWESTISTHEBEST', chartData: { value: 167.53 } },
            { type: 'secondary', title: 'Delivery', evaluation_criteria: 'LOWESTISTHEBEST', chartData: { value: 252.38 } },
            { type: 'secondary', title: 'Quality', evaluation_criteria: 'HIGHESTISTHEBEST', chartData: { value: 164.94 } },
            { type: 'secondary', title: 'Risk', evaluation_criteria: 'HIGHESTISTHEBEST', chartData: { value: 223.9 } },
          ],
          criteria: [
            { order: 1, title: 'Site conditions', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'Extreme environmental conditions: very low temperatures, site not reachable by sea for 9 months a year. Modularization could be used as management and construction strategy in order to properly face critical aspects due to extreme environmental conditions by simplifying all activities to be carried out on site.',
              feedbackData: [
                { name: 'Danilo D\'Amato', sentiment: 0.6, feedback: 'Modularization is the preferable option for managing site condition which might be very changeable and have high impact on the project delivery. I am quite confident for this selection because I have technical experience on the specific field of application.', load: 56, confidence: 33, evidence: 'none' },
                { name: 'Francesca Alessia Bartiromo', sentiment: 0.0, feedback: 'Modularization is preferable when the complex system involves the construction of numerous pieces in different parts of the world both for management and for compliance with deadlines. Furthermore, the extreme conditions of the site make planning and on-site production very difficult', load: 80, confidence: 80, evidence: 'none' },
                { name: 'Laura Rispoli', sentiment: 0.3, feedback: 'Modularization could be used only supported by a strong scheduling and project management organization', load: 75, confidence: 90, evidence: 'none' },
                { name: 'Giuseppe Savino', sentiment: 0.9, feedback: 'Modularization certainly requires more management but eliminates the risk linked to the climate, making the best use of the best periods on the site.', load: 65, confidence: 75, evidence: 'none' },
              ],
              evidenceData: [],
              indicators: {
                risk: 'Low',
                risk_suggestion: 'Do not modify',
                contradiction: 'Low',
                contradiction_suggestion: '-',
                contradiction_alert: false
              },
              chartData:
              {
                avg_load: 69,
                avg_confidence: 69.5
              }
            },
            { order: 2, title: 'Project Schedule', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'Due to extreme site conditions, time schedule is critical and all activities, in terms of materials delivery have to be carried out during a restricted time window. Modularization could be useful to meet time schedule by avoiding additional critical issued due to activities to be carried out directly on site.',
              feedbackData: [
                { name: 'Danilo D\'Amato', sentiment: 0.4, feedback: 'The modularization option can offer more flexibility and \'emergency solutions\' in case some activity is delayed.', load: 78, confidence: 69, evidence: 'none' },
                { name:  'Francesca Alessia Bartiromo', sentiment: 0.4, feedback: 'The management of multiple suppliers from all over the world can be difficult, but at the same time it can be easier to meet the deadlines.', load: 80, confidence: 50, evidence: 'none' },
                { name:  'Laura Rispoli', sentiment: 0.8, feedback: 'Scheduling failure could be very critical in case of non modularization and carry up to project failure in terms of delivery time and costs', load: 60, confidence: 85, evidence: 'none' },
                { name:  'Giuseppe Savino', sentiment: 0.5, feedback: 'The experience shows that on-site assembly certainly has less risk than doing all the work', load: 80, confidence: 80, evidence: 'none' },
              ],
              evidenceData: [
                // {
                //   type: 'crop_original',
                //   name: 'Allegato 1',
                //   link: 'asset/54/0'
                // },
                // {
                //   type: 'crop_original',
                //   name: 'Allegato 2',
                //   link: 'asset/55/0'
                // },
                // {
                //   type: 'crop_original',
                //   name: 'Allegato 3',
                //   link: 'asset/56/0'
                // },
                // {
                //   type: 'ondemand_video',
                //   name: 'Caso Clinico Dr. Ciniglio_valutazione preoperatoria',
                //   link: 'https://www.youtube.com/embed/Ibjy42pjEew'
                // },
              ],
              indicators: {
                risk: 'Low',
                risk_suggestion: 'Do not modify',
                contradiction: 'Low',
                contradiction_suggestion: '-',
                contradiction_alert: false
              },
              chartData:
              {
                avg_load: 74.5,
                avg_confidence: 71
              }
            },
            { order: 3, title: 'Installation and Erection on Site', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'Installation and erection activities on site can be carried out only when weather conditions permit them. Modularization can simply all activities related to installation and erection as just small and few integration activities will remain to be finalize on site.',
              feedbackData: [
                { name: 'Danilo D\'Amato', sentiment: 0.8, feedback: 'Modularization can offer multiple choices for installation and erection procedure and sequence.', load: 60, confidence: 35, evidence: 'none' },
                { name: 'Francesca Alessia Bartiromo', sentiment: 0.7, feedback: 'I am quite confident because the site conditions are very extreme.', load: 90, confidence: 90, evidence: 'none' },
                { name: 'Laura Rispoli', sentiment: 0.0, feedback: 'Installation and erection on site in case of no modularization could cost an high risk level due to unpredictable weather condition events (accidents or operations delays)', load: 80, confidence: 92, evidence: 'none' },
                { name: 'Giuseppe Savino', sentiment: 0.9, feedback: 'A good schedule certainly allows you to take advantage of the best atmospheric periods of the place (activities on site)', load: 70, confidence: 90, evidence: 'none' },
              ],
              evidenceData: [],
              indicators: {
                risk: 'Medio',
                risk_suggestion: 'To be checked',
                contradiction: 'Low',
                contradiction_suggestion: '-',
                contradiction_alert: false
              },
              chartData:
              {
                avg_load: 75,
                avg_confidence: 76.75
              }
            },
            { order: 4, title: 'Integration', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'All packages have to shipped and delivered to site as much as possible completed; system integration will be a critical aspect to let proper plant operation. This activity is an essential step to get all positive impacts from modularization strategy.',
              feedbackData: [
                { name: 'Danilo D\'Amato', sentiment: 0.3, feedback: 'Modularization can offer a preferable background for integration application.', load: 70, confidence: 74, evidence: 'none' },
                { name: 'Francesca Alessia Bartiromo', sentiment: 0.4, feedback: 'Integration is more difficult for suppliers but easier to carry out on the site.', load: 70, confidence: 70, evidence: 'none' },
                { name: 'Laura Rispoli', sentiment: 0.3, feedback: 'Integration is a critical aspetc that has to be tuned by cooperation of project and production management in cooperation with design manager', load: 90, confidence: 55, evidence: 'none' },
                { name: 'Giuseppe Savino', sentiment: 0.0, feedback: 'It\'s the main condition for respecting modularization', load: 80, confidence: 80, evidence: 'none' },
              ],
              evidenceData: [],
              indicators: {
                risk: 'Low',
                risk_suggestion: 'Do not modify',
                contradiction: 'Low',
                contradiction_suggestion: '-',
                contradiction_alert: false
              },
              chartData:
              {
                avg_load: 77.5,
                avg_confidence: 69.75
              }
            },
            { order: 5, title: 'Project Management Plan', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'Triple constrains triangle factors can be put in a well defined priority order: time, quality and cost. Modularization can permit to divide in more simple activities each project activity and manage easily the Project Scope.',
              feedbackData: [
                { name: 'Danilo D\'Amato', sentiment: 0.8, feedback: 'Project management plan is more effective and powerful for modularized solution.', load: 80, confidence: 44, evidence: 'none' },
                { name: 'Francesca Alessia Bartiromo', sentiment: 0.2, feedback: 'Certainly it is not easy to manage many suppliers, but it guarantees a division of costs, time and quality to manage activities in a simpler way.', load: 80, confidence: 70, evidence: 'none' },
                { name: 'Laura Rispoli', sentiment: 0.4, feedback: 'Dividing in activities requires more coordination among activity managers in order to reduce problems due to interfacing each other activities', load: 65, confidence: 80, evidence: 'none' },
                { name: 'Giuseppe Savino', sentiment: 0.8, feedback: 'It\'s necessary a good project management level', load: 75, confidence: 90, evidence: 'none' },
              ],
              evidenceData: [
                // {
                //   type: 'ondemand_video',
                //   name: 'Caso Clinico Dr. Ciniglio_valutazione post-operatoria',
                //   link: 'https://www.youtube.com/embed/RFVPDXSTACU'
                // }
              ],
              indicators: {
                risk: 'High',
                risk_suggestion: 'Do not modify',
                contradiction: 'Low',
                contradiction_suggestion: '-',
                contradiction_alert: false
              },
              chartData:
              {
                avg_load: 75,
                avg_confidence: 71
              }
            },
            { order: 6, title: 'Logistic Philosophy', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'All packages have to shipped and delivered to site as much as possible completed and during a well defined time. Modularization will be more complex to be applied from Logistic point of view as all the Vendors will have to meet timely the completion of packages they are providing.',
              feedbackData: [
                { name: 'Danilo D\'Amato', sentiment: 0.0, feedback: 'Non modularized solution in my opinion is preferable for logistic since it imply simplified approach to logistic.', load: 30, confidence: 50, evidence: 'none' },
                { name: 'Francesca Alessia Bartiromo', sentiment: 0.4, feedback: 'I am not very confident of this answer because each supplier should guarantee compliance with the deadlines and delivery to the site to meet timely the completion of packages they are providing.', load: 50, confidence: 50, evidence: 'none' },
                { name: 'Laura Rispoli', sentiment: 0.0, feedback: '', load: 50, confidence: 70, evidence: 'none' },
                { name: 'Giuseppe Savino', sentiment: 0.1, feedback: 'Time e Logistic Management ', load: 85, confidence: 65, evidence: 'none' },
              ],
              evidenceData: [],
              indicators: {
                risk: 'Low',
                risk_suggestion: 'To be checked',
                contradiction: 'Low',
                contradiction_suggestion: '-',
                contradiction_alert: true
              },
              chartData:
              {
                avg_load: 48.75,
                avg_confidence: 58.75
              }
            },
            { order: 7, title: 'HSE Operation', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'Due to the particular climatic conditions, the aspects related to workers\' safety and health can have a decisive impact on the entire project. Modularization reduce risky activity to be carried out on site.',
              feedbackData: [
                { name: 'Danilo D\'Amato', sentiment: 0.2, feedback: 'Modularized solution will expose the workers to a prolonged work time and period.', load: 13, confidence: 45, evidence: 'none' },
                { name: 'Francesca Alessia Bartiromo', sentiment: 0.2, feedback: 'The reduced complexity of the construction on the site makes it possible to have fewer men at work and therefore reduce the risk of accidents.', load: 90, confidence: 100, evidence: 'none' },
                { name: 'Laura Rispoli', sentiment: 0.7, feedback: 'Risks could have heavy setbacks in terms of human costs or material\'s costs, so the first strategy could be te ideal one to follow', load: 90, confidence: 75, evidence: 'none' },
                { name: 'Giuseppe Savino', sentiment: 0.1, feedback: 'One of the main advantages in modularization', load: 90, confidence: 95, evidence: 'none' },
              ],
              evidenceData: [],
              indicators: {
                risk: 'Low',
                risk_suggestion: 'Do not modify',
                contradiction: 'Low',
                contradiction_suggestion: '-',
                contradiction_alert: false
              },
              chartData:
              {
                avg_load: 70.75,
                avg_confidence: 78.75
              }
            },
            { order: 8, title: 'Procurement Management Strategy', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'Vendors selection based on vendors factory location and vendors past performance in meeting delivery. Modularization has to be the base of Vendors selection in order to have the proper results in terms of delivery and quality.',
              feedbackData: [
                { name: 'Danilo D\'Amato', sentiment: 0.6, feedback: 'Clearly the subvendors experience is essential for the success of the project.', load: 77, confidence: 56, evidence: 'none' },
                { name: 'Francesca Alessia Bartiromo', sentiment: 0.2, feedback: 'It is not always possible to choose suppliers adequately when there are many variables that must be respected.', load: 60, confidence: 70, evidence: 'none' },
                { name: 'Laura Rispoli', sentiment: 0.3, feedback: 'A NC problem with some materials could be more difficoult to manage and resolve on modularization strategy than on no-mod: the vendor selection based on performance in D and Q is highly critical in case of modularization strategy.', load: 75, confidence: 75, evidence: 'none' },
                { name: 'Giuseppe Savino', sentiment: 0.2, feedback: 'A specific supplier team is required', load: 65, confidence: 70, evidence: 'none' },
              ],
              evidenceData: [],
              indicators: {
                risk: 'Low',
                risk_suggestion: 'Do not modify',
                contradiction: 'Low',
                contradiction_suggestion: '-',
                contradiction_alert: false
              },
              chartData:
              {
                avg_load: 69.25,
                avg_confidence: 67.75
              }
            },
            { order: 9, title: 'Engineering Design', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'Engineering design in order to reduce any construction activity (e.g. welding operation, ...) as much as possible and to simplify any on site assembly (integration) activity. Modularization implies a big effort to prepare a proper set of documents and drawings and all the procedures to properly execute it on site.',
              feedbackData: [
                { name: 'Danilo D\'Amato', sentiment: 0.9, feedback: 'I am very confident for this selection since engineering of modularized option is tailored and can be adapted to any modification which may arise during installation & construction.', load: 65, confidence: 69, evidence: 'none' },
                { name: 'Francesca Alessia Bartiromo', sentiment: 0.0, feedback: 'Engineering is complex for all suppliers who therefore must also be chosen on the basis of their degree of integration with other systems.', load: 90, confidence: 70, evidence: 'none' },
                { name: 'Laura Rispoli', sentiment: 0.9, feedback: 'Modularization strategy is highly driven by a very excellent engineering design that could involve some critical delay or production problems if some fails should appear in some critical phases.', load: 60, confidence: 75, evidence: 'none' },
                { name: 'Giuseppe Savino', sentiment: 0.7, feedback: 'Modularization implies great management in these cases but offers significant advantages over the ratio of direct risks', load: 45, confidence: 70, evidence: 'none' },
              ],
              evidenceData: [],
              indicators: {
                risk: 'Low',
                risk_suggestion: 'Do not modify',
                contradiction: 'Low',
                contradiction_suggestion: '-',
                contradiction_alert: false
              },
              chartData:
              {
                avg_load: 65,
                avg_confidence: 71
              }
            },
            { order: 10, title: 'Modularization Site Selection', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'In case of modularization strategy selection, modularization site (yard) need to have particular characteristics suitable for fast transport of all modules to site. Logistic is fundamental success factor. Modularization strategy implies the correct selection of modularization site and this could be an additional risk on the Project.',
              feedbackData: [
                { name: 'Danilo D\'Amato', sentiment: 0.6, feedback: 'I am very confident because site yard is applicable only for modularized solution.', load: 60, confidence: 77, evidence: 'none' },
                { name: 'Francesca Alessia Bartiromo', sentiment: 0.8, feedback: 'Due to the extreme conditions of the site, it is not possible to build the entire plant on the site.', load: 50, confidence: 90, evidence: 'none' },
                { name: 'Laura Rispoli', sentiment: 0.9, feedback: '', load: 60, confidence: 75, evidence: 'none' },
                { name: 'Giuseppe Savino', sentiment: 0.1, feedback: 'this is the main risk of modularization but it can be managed', load: 40, confidence: 70, evidence: 'none' },
              ],
              evidenceData: [],
              indicators: {
                risk: 'Low',
                risk_suggestion: 'Do not modify',
                contradiction: 'Low',
                contradiction_suggestion: '-',
                contradiction_alert: false
              },
              chartData:
              {
                avg_load: 52.5,
                avg_confidence: 78
              }
            },
            { order: 11, title: 'Time', measure_unit: 'MONTH', evaluation: 'HIGHESTISTHEBEST', typology: 'QUANTITATIVE', description: '11 MONTHs',
              feedbackData: [
                // { name: "Danilo D'Amato", sentiment: 1.0, feedback: "scelta endoscopica se non controindicazioni ( vedi sopra) sempre preferibile a chir open per rapidità recupero funzionale - Nella valutazione della scelta contano anche le condizioni del paziente e l'ambiente di supporto familiare..", load: 50, confidence: 90, evidence: "none" },
                // { name: "Francesca Alessia Bartiromo", sentiment: 1.0, feedback: "Nel trattamento eseguito, scelta RT adiuvante necessaria per rischio di metastasi linfonodali occulte. Follow up standard.", load: 50, confidence: 50, evidence: "none" },
                // { name: "Laura Rispoli", sentiment: 1.0, feedback: "Ok!	", load: 50, confidence: 75, evidence: "none" },
                // { name: "Giuseppe Savino", sentiment: 1.0, feedback: "Ok!	", load: 50, confidence: 75, evidence: "none" },
              ],
              evidenceData: [],
              indicators: {
                // risk: 'Low',
                // risk_suggestion: 'Do not modify',
                // contradiction: 'Low',
                // contradiction_suggestion: '-',
                // contradiction_alert: false
              },
              chartData:
                {
                  // certainty: 75,
                  // contradition: 3,
                  // agree: 3,
                  // disagree: 0,
                  // none: 0
                }
            }
          ]
        }
      },
      {
        title: 'No Modularizzation', proposed_by: 'Francesco Spadera', proposed_by_role: 'Requester', proposed_by_avatar: 'https://www.istum.it/wp-content/uploads/2020/11/Francesco-Spadera_SQ_550.jpg', creation_session: 'Start table', when_evaluated: 'Evaluation Session 1', score: '40', score_value: '100', result: 'DISCARD', suggestion: 'PROPOSAL TO BE DISCARDED',
        framework: {
          framework_name: 'Construction Strategy',
          organization_id: 3,
          analysis_type: 1,
          sectors: [{sector_key: 'ENGINEERING', subsector_key: 'INDUSTRIALENGINEERING'}],
          purpose_description: 'This framework is helping you in deciding a proper construction strategy of industrial plant in a severe conditions environment and to evaluate advantage of modularization approach.',
          primary_target: {type: 'primary', title: 'Project Success', evaluation_criteria: 'HIGHESTISTHEBEST'},
          secondary_targets: [
            { type: 'secondary', title: 'Cost', evaluation_criteria: 'LOWESTISTHEBEST', chartData: { value: 82.9 } },
            { type: 'secondary', title: 'Delivery', evaluation_criteria: 'LOWESTISTHEBEST', chartData: { value: 129.77 } },
            { type: 'secondary', title: 'Quality', evaluation_criteria: 'HIGHESTISTHEBEST', chartData: { value: 84.87 } },
            { type: 'secondary', title: 'Risk', evaluation_criteria: 'HIGHESTISTHEBEST', chartData: { value: 107.89 } },
          ],
          criteria: [
            { order: 1, title: 'Site conditions', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'Extreme environmental conditions: very low temperatures, site not reachable by sea for 9 months a year.Non-Modularization couldn\'t be used as management and construction strategy in order to properly face critical aspects due to extreme environmental conditions by simplifying all activities to be carried out on site.',
              feedbackData: [
                { name: 'Danilo D\'Amato', sentiment: 0.6, feedback: 'Modularization is the preferable option for managing site condition which might be very changeable and have high impact on the project delivery. I am quite confident for this selection because I have technical experience on the specific field of application.', load: 44, confidence: 33, evidence: 'none' },
                { name: 'Francesca Alessia Bartiromo', sentiment: 0.0, feedback: 'Modularization is preferable when the complex system involves the construction of numerous pieces in different parts of the world both for management and for compliance with deadlines. Furthermore, the extreme conditions of the site make planning and on-site production very difficult', load: 20, confidence: 80, evidence: 'none' },
                { name: 'Laura Rispoli', sentiment: 0.3, feedback: 'Modularization could be used only supported by a strong scheduling and project management organization', load: 30, confidence: 90, evidence: 'none' },
                { name: 'Giuseppe Savino', sentiment: 0.9, feedback: 'Modularization certainly requires more management but eliminates the risk linked to the climate, making the best use of the best periods on the site.', load: 35, confidence: 90, evidence: 'none' },
              ],
              evidenceData: [],
              indicators: {
                risk: 'Low',
                risk_suggestion: 'Do not modify',
                contradiction: 'Low',
                contradiction_suggestion: '-',
                contradiction_alert: true
              },
              chartData:
              {
                avg_load: 32.25,
                avg_confidence: 73.25
              }
            },
            { order: 2, title: 'Project Schedule', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'Due to extreme site conditions, time schedule is critical and all activities, in terms of materials delivery have to be carried out during a restricted time window. Non-Modularization set bigger issues to meet time schedule by avoiding additional critical issued due to activities to be carried out directly on site.',
              feedbackData: [
                { name: 'Danilo D\'Amato', sentiment: 0.4, feedback: 'The modularization option can offer more flexibility and \'emergency solutions\' in case some activity is delayed.', load: 22, confidence: 69, evidence: 'none' },
                { name: 'Francesca Alessia Bartiromo', sentiment: 0.4, feedback: 'The management of multiple suppliers from all over the world can be difficult, but at the same time it can be easier to meet the deadlines.', load: 20, confidence: 50, evidence: 'none' },
                { name:  'Laura Rispoli', sentiment: 0.8, feedback: 'Scheduling failure could be very critical in case of non modularization and carry up to project failure in terms of delivery time and costs', load: 30, confidence: 85, evidence: 'none' },
                { name:  'Giuseppe Savino', sentiment: 0.5, feedback: 'The experience shows that on-site assembly certainly has less risk than doing all the work', load: 20, confidence: 85, evidence: 'none' },
              ],
              evidenceData: [
                // {
                //   type: 'crop_original',
                //   name: 'Allegato 1',
                //   link: 'asset/54/0'
                // },
                // {
                //   type: 'crop_original',
                //   name: 'Allegato 2',
                //   link: 'asset/55/0'
                // },
                // {
                //   type: 'crop_original',
                //   name: 'Allegato 3',
                //   link: 'asset/56/0'
                // },
                // {
                //   type: 'ondemand_video',
                //   name: 'Caso Clinico Dr. Ciniglio_valutazione preoperatoria',
                //   link: 'https://www.youtube.com/embed/Ibjy42pjEew'
                // },
              ],
              indicators: {
                risk: 'Low',
                risk_suggestion: 'Do not modify',
                contradiction: 'Low',
                contradiction_suggestion: '-',
                contradiction_alert: true
              },
              chartData:
              {
                avg_load: 23,
                avg_confidence: 72.25
              }
            },
            { order: 3, title: 'Installation and Erection on Site', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'Installation and erection activities on site can be carried out only when weather conditions permit them. Non-Modularization can make difficulty all activities related to installation and erection as several and complex integration activities will remain to be finalize on site.',
              feedbackData: [
                { name: 'Danilo D\'Amato', sentiment: 0.8, feedback: 'Modularization can offer multiple choices for installation and erection procedure and sequence.', load: 40, confidence: 35, evidence: 'none' },
                { name: 'Francesca Alessia Bartiromo', sentiment: 0.7, feedback: 'I am quite confident because the site conditions are very extreme.', load: 10, confidence: 90, evidence: 'none' },
                { name: 'Laura Rispoli', sentiment: 0.0, feedback: 'Installation and erection on site in case of no modularization could cost an high risk level due to unpredictable weather condition events (accidents or operations delays)', load: 20, confidence: 92, evidence: 'none' },
                { name: 'Giuseppe Savino', sentiment: 0.9, feedback: 'A good schedule certainly allows you to take advantage of the best atmospheric periods of the place (activities on site)', load: 15, confidence: 92, evidence: 'none' },
              ],
              evidenceData: [],
              indicators: {
                risk: 'Medio',
                risk_suggestion: 'To be checked',
                contradiction: 'Low',
                contradiction_suggestion: '-',
                contradiction_alert: true
              },
              chartData:
              {
                avg_load: 21.25,
                avg_confidence: 77.25
              }
            },
            { order: 4, title: 'Integration', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'All packages have to shipped and delivered to site as much as possible completed; system integration will be a critical aspect to let proper plant operation. This activity is an essential step to get all positive impacts from modularization strategy.',
              feedbackData: [
                { name: 'Danilo D\'Amato', sentiment: 0.3, feedback: 'Modularization can offer a preferable background for integration application.', load: 30, confidence: 74, evidence: 'none' },
                { name: 'Francesca Alessia Bartiromo', sentiment: 0.4, feedback: 'Integration is more difficult for suppliers but easier to carry out on the site.', load: 30, confidence: 70, evidence: 'none' },
                { name: 'Laura Rispoli', sentiment: 0.3, feedback: 'Integration is a critical aspetc that has to be tuned by cooperation of project and production management in cooperation with design manager', load: 60, confidence: 80, evidence: 'none' },
                { name: 'Giuseppe Savino', sentiment: 0.0, feedback: 'It\'s the main condition for respecting modularization', load: 30, confidence: 80, evidence: 'none' },
              ],
              evidenceData: [],
              indicators: {
                risk: 'Low',
                risk_suggestion: 'Do not modify',
                contradiction: 'Low',
                contradiction_suggestion: '-',
                contradiction_alert: true
              },
              chartData:
              {
                avg_load: 37.5,
                avg_confidence: 76
              }
            },
            { order: 5, title: 'Project Management Plan', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'Triple constrains triangle factors can be put in a well defined priority order: time, quality and cost. Non-Modularization can\'t permit to divide in more simple activities each project activity and manage easily the Project Scope.',
              feedbackData: [
                { name: 'Danilo D\'Amato', sentiment: 0.8, feedback: 'Project management plan is more effective and powerful for modularized solution.', load: 20, confidence: 44, evidence: 'none' },
                { name: 'Francesca Alessia Bartiromo', sentiment: 0.2, feedback: 'Certainly it is not easy to manage many suppliers, but it guarantees a division of costs, time and quality to manage activities in a simpler way.', load: 20, confidence: 70, evidence: 'none' },
                { name: 'Laura Rispoli', sentiment: 0.4, feedback: 'Dividing in activities requires more coordination among activity managers in order to reduce problems due to interfacing each other activities', load: 20, confidence: 80, evidence: 'none' },
                { name: 'Giuseppe Savino', sentiment: 0.8, feedback: 'It\'s necessary a good project management level', load: 25, confidence: 90, evidence: 'none' },
              ],
              evidenceData: [
                {
                  type: 'ondemand_video',
                  name: 'Caso Clinico Dr. Ciniglio_valutazione post-operatoria',
                  link: 'https://www.youtube.com/embed/RFVPDXSTACU'
                }
              ],
              indicators: {
                risk: 'High',
                risk_suggestion: 'To be checked',
                contradiction: 'Low',
                contradiction_suggestion: '-',
                contradiction_alert: true
              },
              chartData:
              {
                avg_load: 21.25,
                avg_confidence: 71
              }
            },
            { order: 6, title: 'Logistic Philosophy', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'AlAll packages have to shipped and delivered to site as much as possible completed and during a well defined time. Non-Modularization will be more complex to be applied from Logistic point of view as all the Vendors will have to meet timely the completion of packages they are providing.',
              feedbackData: [
                { name: 'Danilo D\'Amato', sentiment: 0.0, feedback: 'Non modularized solution in my opinion is preferable for logistic since it imply simplified approach to logistic.', load: 70, confidence: 50, evidence: 'none' },
                { name: 'Francesca Alessia Bartiromo', sentiment: 0.4, feedback: 'I am not very confident of this answer because each supplier should guarantee compliance with the deadlines and delivery to the site to meet timely the completion of packages they are providing.', load: 50, confidence: 50, evidence: 'none' },
                { name: 'Laura Rispoli', sentiment: 0.0, feedback: '', load: 50, confidence: 70, evidence: 'none' },
                { name: 'Giuseppe Savino', sentiment: 0.1, feedback: 'Time e Logistic Management ', load: 15, confidence: 65, evidence: 'none' },
              ],
              evidenceData: [],
              indicators: {
                risk: 'Low',
                risk_suggestion: 'Do not modify',
                contradiction: 'Low',
                contradiction_suggestion: '-',
                contradiction_alert: true
              },
              chartData:
              {
                avg_load: 46.25,
                avg_confidence: 58.75
              }
            },
            { order: 7, title: 'HSE Operation', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'Due to the particular climatic conditions, the aspects related to workers\' safety and health can have a decisive impact on the entire project. Non-Modularization increases risky activity to be carried out on site.',
              feedbackData: [
                { name: 'Danilo D\'Amato', sentiment: 0.2, feedback: 'Modularized solution will expose the workers to a prolonged work time and period.', load: 87, confidence: 45, evidence: 'none' },
                { name: 'Francesca Alessia Bartiromo', sentiment: 0.2, feedback: 'The reduced complexity of the construction on the site makes it possible to have fewer men at work and therefore reduce the risk of accidents.', load: 10, confidence: 100, evidence: 'none' },
                { name: 'Laura Rispoli', sentiment: 0.7, feedback: 'Risks could have heavy setbacks in terms of human costs or material\'s costs, so the first strategy could be te ideal one to follow', load: 10, confidence: 75, evidence: 'none' },
                { name: 'Giuseppe Savino', sentiment: 0.1, feedback: 'One of the main advantages in modularization', load: 10, confidence: 95, evidence: 'none' },
              ],
              evidenceData: [],
              indicators: {
                risk: 'Low',
                risk_suggestion: 'Do not modify',
                contradiction: 'Low',
                contradiction_suggestion: '-',
                contradiction_alert: true
              },
              chartData:
              {
                avg_load: 29.26,
                avg_confidence: 78.75
              }
            },
            { order: 8, title: 'Procurement Management Strategy', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'Vendors selection based on vendors factory location and vendors past performance in meeting delivery. Non-Modularization has to be the base of Vendors selection in order to have the proper results in terms of delivery and quality.',
              feedbackData: [
                { name: 'Danilo D\'Amato', sentiment: 0.6, feedback: 'Clearly the subvendors experience is essential for the success of the project.', load: 23, confidence: 56, evidence: 'none' },
                { name: 'Francesca Alessia Bartiromo', sentiment: 0.2, feedback: 'It is not always possible to choose suppliers adequately when there are many variables that must be respected.', load: 40, confidence: 70, evidence: 'none' },
                { name: 'Laura Rispoli', sentiment: 0.3, feedback: 'A NC problem with some materials could be more difficoult to manage and resolve on modularization strategy than on no-mod: the vendor selection based on performance in D and Q is highly critical in case of modularization strategy.', load: 30, confidence: 75, evidence: 'none' },
                { name: 'Giuseppe Savino', sentiment: 0.2, feedback: 'A specific supplier team is required', load: 35, confidence: 70, evidence: 'none' },
              ],
              evidenceData: [],
              indicators: {
                risk: 'Low',
                risk_suggestion: 'Do not modify',
                contradiction: 'Low',
                contradiction_suggestion: '-',
                contradiction_alert: true
              },
              chartData:
              {
                avg_load: 32,
                avg_confidence: 47.75
              }
            },
            { order: 9, title: 'Engineering Design', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'Engineering design in order to reduce any construction activity (e.g. welding operation, ...) as much as possible and to simplify any on site assembly (integration) activity. Non-Modularization implies a smaller effort to prepare a proper set of documents and drawings and a big effort for preparing all the procedures to properly execute each activity on site.',
              feedbackData: [
                { name: 'Danilo D\'Amato', sentiment: 0.9, feedback: 'I am very confident for this selection since engineering of modularized option is tailored and can be adapted to any modification which may arise during installation & construction.', load: 35, confidence: 69, evidence: 'none' },
                { name: 'Francesca Alessia Bartiromo', sentiment: 0.0, feedback: 'Engineering is complex for all suppliers who therefore must also be chosen on the basis of their degree of integration with other systems.', load: 50, confidence: 70, evidence: 'none' },
                { name: 'Laura Rispoli', sentiment: 0.9, feedback: 'Modularization strategy is highly driven by a very excellent engineering design that could involve some critical delay or production problems if some fails should appear in some critical phases.', load: 60, confidence: 75, evidence: 'none' },
                { name: 'Giuseppe Savino', sentiment: 0.7, feedback: 'Modularization implies great management in these cases but offers significant advantages over the ratio of direct risks', load: 55, confidence: 70, evidence: 'none' },
              ],
              evidenceData: [],
              indicators: {
                risk: 'Low',
                risk_suggestion: 'Do not modify',
                contradiction: 'Low',
                contradiction_suggestion: '-',
                contradiction_alert: false
              },
              chartData:
              {
                avg_load: 50,
                avg_confidence: 71
              }
            },
            { order: 10, title: 'Modularization Site Selection', measure_unit: null, evaluation: null, typology: 'QUALITATIVE', description: 'In case of non-modularization strategy selection, modularization site is not applicable. Logistic is fundamental success factor. Non-Modularization strategy implies the correct selection each Vendors.',
              feedbackData: [
                { name: 'Danilo D\'Amato', sentiment: 0.6, feedback: 'I am very confident because site yard is applicable only for modularized solution.', load: 60, confidence: 77, evidence: 'none' },
                { name: 'Francesca Alessia Bartiromo', sentiment: 0.8, feedback: 'Due to the extreme conditions of the site, it is not possible to build the entire plant on the site.', load: 10, confidence: 90, evidence: 'none' },
                { name: 'Laura Rispoli', sentiment: 0.9, feedback: '', load: 35, confidence: 75, evidence: 'none' },
                { name: 'Giuseppe Savino', sentiment: 1.0, feedback: 'Ok!	', load: 40, confidence: 75, evidence: 'none' },
              ],
              evidenceData: [],
              indicators: {
                risk: 'Low',
                risk_suggestion: 'Do not modify',
                contradiction: 'Low',
                contradiction_suggestion: '-',
                contradiction_alert: true
              },
              chartData:
              {
                avg_load: 36.25,
                avg_confidence: 79.25
              }
            },
            { order: 11, title: 'Time', measure_unit: 'MONTH', evaluation: 'HIGHESTISTHEBEST', typology: 'QUANTITATIVE', description: '18 MONTHs',
              feedbackData: [
                // { name: "Danilo D'Amato", sentiment: 1.0, feedback: "scelta endoscopica se non controindicazioni ( vedi sopra) sempre preferibile a chir open per rapidità recupero funzionale - Nella valutazione della scelta contano anche le condizioni del paziente e l'ambiente di supporto familiare..", load: 0, confidence: 90, evidence: "none" },
                // { name: "Francesca Alessia Bartiromo", sentiment: 1.0, feedback: "Nel trattamento eseguito, scelta RT adiuvante necessaria per rischio di metastasi linfonodali occulte. Follow up standard.", load: 0, confidence: 50, evidence: "none" },
                // { name: "Laura Rispoli", sentiment: 1.0, feedback: "Ok!	", load: 0, confidence: 75, evidence: "none" },
                // { name: "Giuseppe Savino", sentiment: 1.0, feedback: "Ok!	", load: 0, confidence: 75, evidence: "none" },
              ],
              evidenceData: [],
              indicators: {
                // risk: 'Low',
                // risk_suggestion: 'Do not modify',
                // contradiction: 'Low',
                // contradiction_suggestion: '-',
                // contradiction_alert: false
              },
              chartData:
                {
                  // certainty: 75,
                  // contradition: 3,
                  // agree: 3,
                  // disagree: 0,
                  // none: 0
                }
            }
          ]
        }
      },
    ],
    team_members: [
      {
        username: 'Danilo D\'Amato',
        email: 'dd.dolphy@gmail.com',
        organization_professional_role_id: null,
        process_role: 'CONSULTED',
        complete_process_enabled: true,
        review_enabled: true,
        provide_new_proposal_enabled: false,
        weight: 80
      },
      {
        username: 'Francesca Alessia Bartiromo',
        email: 'francesca.a.bartiromo@gmail.com',
        organization_professional_role_id: null,
        process_role: 'CONSULTED',
        complete_process_enabled: true,
        review_enabled: true,
        provide_new_proposal_enabled: false,
        weight: 90
      },
      {
        username: 'Laura Rispoli',
        email: 'ing.laurarispoli@gmail.com',
        organization_professional_role_id: null,
        process_role: 'CONSULTED',
        complete_process_enabled: true,
        review_enabled: true,
        provide_new_proposal_enabled: false,
        weight: 90
      },
      {
        username: 'Giuseppe Savino',
        email: 'ing.giuseppe.savino@gmail.com',
        organization_professional_role_id: null,
        process_role: 'CONSULTED',
        complete_process_enabled: true,
        review_enabled: true,
        provide_new_proposal_enabled: false,
        weight: 100
      },
      {
        username: 'Enzo Troncone',
        email: 'enzo@buterflysrl.com',
        organization_professional_role_id: null,
        process_role: 'ACCOUNTABLE',
        complete_process_enabled: true,
        review_enabled: true,
        provide_new_proposal_enabled: false,
        weight: null
      },
    ]
  };
  private _casoYamalDashboard: any = {
    evaluation_sessions: [
      {id: 1, decision_start: '25 April 2022 13:10:09', decision_end: '10 May 2022 07:20:23', tracker: false}
    ],
    analysis_type: 1,
    feedbackData: [
      {
        expert: 'Danilo D\'Amato',
        answers: true,
        time: '3 hours',
        cost: '250 €',
        total_cost: '750 €',
        evaluation_session: '1',
        new_proposal: false,
        evidence: false,
      },
      {
        expert: 'Francesca Alessia Bartiromo',
        answers: true,
        time: '2 hours',
        cost: '250 €',
        total_cost: '500 €',
        evaluation_session: '1',
        new_proposal: false,
        evidence: false
      },
      {
        expert: 'Laura Rispoli',
        answers: true,
        time: '1 hours',
        cost: '250 €',
        total_cost: '250 €',
        evaluation_session: '1',
        new_proposal: false,
        evidence: false
      },
      {
        expert: 'Giuseppe Savino',
        answers: true,
        time: '3 hours',
        cost: '250 €',
        total_cost: '750 €',
        evaluation_session: '1',
        new_proposal: false,
        evidence: false
      }
    ],
    criteriaData: []
  };
  private _projectDemos: any = [
    {
      projectDetails: {
        title: 'Progetto 1',
        description: 'Descrizione progetto 1',
      },
      decisionTables: [],
      surveys: [],
      pico: {
        patients: 'Pazienti adulti con ipertensione',
        intervention: 'Assunzione di farmaci anti-ipertensivi',
        comparison: 'Cambiamento dello stile di vita (dieta e attività fisica)',
        outcomes: 'Riduzione della pressione arteriosa',
        response: 'Nei pazienti adulti con ipertensione, l’assunzione di farmaci anti-ipertensivi è più efficace rispetto al cambiamento dello stile di vita (dieta e attività fisica) per la riduzione della pressione arteriosa?'
      },
      biblio: {
        keywords: [
          'Hypertension',
          'Blood Pressure',
          'Antihypertensive Medications',
          'Lifestyle Changes',
          'Diet and Exercise',
          'Pharmacotherapy',
          'Clinical Trials',
          'Blood Pressure Reduction',
          'Hypertensive Patients',
          'Long-term Outcomes',
          'Cardiovascular Health',
          'Dietary Interventions',
          'Physical Activity',
          'Hypertension Management',
          'Anti-hypertensive Therapy',
          'Health Outcomes',
          'Comparative Effectiveness',
          'Treatment Efficacy',
          'Risk Reduction',
          'Chronic Disease Management'
        ],
        articles: [
          {
            articleTitle: 'Effect of Antihypertensive Medications vs. Lifestyle Changes on Blood Pressure Control in Hypertensive Patients',
            authors: 'Smith et al.',
            year: 2020,
            studyType: 'Randomized Controlled Trial',
            mainOutcome: 'Medications reduced blood pressure more significantly than lifestyle changes.',
            riskOfBias: 'Low',
            consistency: 'High',
            precision: 'High',
            effectSize: 'Large',
            gradeQuality: 'High'
          },
          {
            articleTitle: 'Comparative Impact of Lifestyle Modifications and Pharmacotherapy on Hypertension',
            authors: 'Johnson et al.',
            year: 2019,
            studyType: 'Meta-analysis',
            mainOutcome: 'Lifestyle changes showed similar effects to medications, with fewer side effects.',
            riskOfBias: 'Moderate',
            consistency: 'Moderate',
            precision: 'Moderate',
            effectSize: 'Moderate',
            gradeQuality: 'Moderate'
          },
          {
            articleTitle: 'Long-term Outcomes of Antihypertensive Therapy vs. Lifestyle Interventions',
            authors: 'Martinez et al.',
            year: 2018,
            studyType: 'Cohort Study',
            mainOutcome: 'Medications were more effective in the short term, but lifestyle changes improved long-term outcomes.',
            riskOfBias: 'Moderate',
            consistency: 'High',
            precision: 'Moderate',
            effectSize: 'Small',
            gradeQuality: 'Moderate'
          },
          {
            articleTitle: 'Diet and Exercise vs. Medication in the Management of Hypertension: A Review',
            authors: 'Nguyen et al.',
            year: 2021,
            studyType: 'Systematic Review',
            mainOutcome: 'A combination of medications and lifestyle changes produced the best outcomes.',
            riskOfBias: 'Low',
            consistency: 'High',
            precision: 'High',
            effectSize: 'Large',
            gradeQuality: 'High'
          }
        ]
      }
    }
  ];
  public setProposal(proposal: any) {
    this._casoYamal.proposals.push(proposal);
  }

  //   criteriaData: [
  //     {
  //       title: "Anamnesi",
  //       accept: true,
  //       vote: {
  //         graph_vote_yes: 100,
  //         graph_vote_no: 0,
  //         graph_vote_none: 0,
  //       },
  //       risk: 95,
  //       contradiction: 3
  //     },
  //     {
  //       title: "Staging",
  //       accept: true,
  //       vote: {
  //         graph_vote_yes: 100,
  //         graph_vote_no: 0,
  //         graph_vote_none: 0,
  //       },
  //       risk: 95,
  //       contradiction: 3
  //     },
  //     {
  //       title: "Stadio Clinico",
  //       accept: true,
  //       vote: {
  //         graph_vote_yes: 67,
  //         graph_vote_no: 33,
  //         graph_vote_none: 0,
  //       },
  //       risk: 51,
  //       contradiction: 12
  //     },
  //     {
  //       title: "Considerazioni cliniche",
  //       accept: true,
  //       vote: {
  //         graph_vote_yes: 100,
  //         graph_vote_no: 0,
  //         graph_vote_none: 0,
  //       },
  //       risk: 72,
  //       contradiction: 4
  //     },
  //     {
  //       title: "Scelta Terapeutica",
  //       accept: false,
  //       vote: {
  //         graph_vote_yes: 67,
  //         graph_vote_no: 33,
  //         graph_vote_none: 0,
  //       },
  //       risk: 45,
  //       contradiction: 19
  //     },
  //     {
  //       title: "Considerazioni conclusive e follow-up",
  //       accept: true,
  //       vote: {
  //         graph_vote_yes: 100,
  //         graph_vote_no: 0,
  //         graph_vote_none: 0,
  //       },
  //       risk: 75,
  //       contradiction: 3
  //     }
  //   ]
  // }


}
