import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-modal-context-discovery',
  templateUrl: './modal-context-discovery.component.html',
  styleUrls: ['./modal-context-discovery.component.scss']
})
export class ModalContextDiscoveryComponent implements OnInit {

  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: {}) { }

  initData = null;
  loading = false;
  imgSrc = null;

  ngOnInit(): void {
    this.imgSrc = '../../../assets/img/context-discovery-' + this.data + '.jpg';
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

  closeDialogue() {
    this.dialog.closeAll();
  }

}
