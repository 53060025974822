import { Component, OnInit } from '@angular/core';
import { AnalysisMethodsService } from 'src/app/services/analysis-methods.service';
import { UserService } from 'src/app/services/user.service';
import { CriteriaService } from 'src/app/services/config/criteria.service';
import { UnitsService } from 'src/app/services/config/units.service';
import { WorkingSectorsService } from 'src/app/services/config/workingSectors.service';
import { ToastService } from 'src/app/services/general/toast.service';
import { FrameworkService } from 'src/app/services/framework.service';
import { environment } from 'src/environments/environment';
import { DemoService } from 'src/app/services/demo.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-framework',
  templateUrl: './framework.component.html',
  styleUrls: ['./framework.component.scss']
})
export class FrameworkComponent implements OnInit {

  private _activeRouteSubscription: Subscription;

  loading: boolean = false;

  id: any;

  //  modello del framework
  model: any = {
    framework_name: null,
    organization_id: null,
    analysis_type: null,
    sectors: [],
    purpose_description: '',
    primary_target: {type: 'primary', title: '', evaluation_criteria: null},
    secondary_targets: [],
    criteria: [],
    constraints: [],
    options_role: null,
    options_role_required: null,
    roles: []
  };

  //  TODO_MICHELE: aggiungere i ruoli che vengono gestiti dal framework
  process_roles: any = [
    { key: 'RESPONSIBLE', title: 'Responsible'},
    { key: 'ACCOUNTABLE', title: 'Accountable'},
    { key: 'CONSULTED', title: 'Accountable'},
    { key: 'INFORMED', title: 'Informed'}
  ];

  analysisMethods: any = [];
  userOrganizzation: any = [];
  professionalRoles: any = [];

  constructor(private toast: ToastService, private unitsService: UnitsService, private criteriaService: CriteriaService,
            private workingSectorService: WorkingSectorsService, private analysisMethodsService: AnalysisMethodsService,
            private userService: UserService, private frameworkService: FrameworkService, private demoService: DemoService,
            private activeRoute: ActivatedRoute) { }


  ngOnInit(): void {

    //  controllo se sono in modifica o in creazione
    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.id = routeParams.id_framework;
      this.init();
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();
  }

  init() {
    //  inizializzo i metodi disponibili
    this.analysisMethods = this.analysisMethodsService.getAllAnalysisMethods().map(v => ({...v, enabled: false}));

    //  inizializzo i dati dell'utente
    this.userOrganizzation = this.userService.getAllOrganizzations();
    this.professionalRoles = this.userService.getAllProfessionalRoles();

    //  all'avvio inserisco un settore vuoto
    if (this.model.sectors.length == 0)
      this.addSector();

    //  all'avvio inserisco un criterio vuoto
    if (this.model.criteria.length == 0)
      this.addCriteria();

    //  all'avvio inserisco un ruolo vuoto
    if (this.model.roles.length == 0)
      this.addRole();

    //  TODO_MICHELE: versione demo per Enzo
    if (environment.demo) {

      switch (this.id) {
        case "1":
          this.model = this.demoService.casoSarcomatoidFramework;

          var methodIndex = this.analysisMethods.findIndex(x => x.type == this.model.analysis_type);
          if (methodIndex >= 0) {
            this.analysisMethods[methodIndex].enabled = true;
          }
          break;
        case "2":
          this.model = this.demoService.casoYamalFramework;

          var methodIndex = this.analysisMethods.findIndex(x => x.type == this.model.analysis_type);
          if (methodIndex >= 0) {
            this.analysisMethods[methodIndex].enabled = true;
          }
          break;
        default:
          break;
      }

      console.log(this.model);
    }

  }


  /**
   * Gestione della selezione dei metodi di analisi
   */
  disableAnalysis() {
    this.analysisMethods.forEach(element => {
      element.enabled = false;
    });
  }
  analysisOnClick(methodIndex: number) {
    this.disableAnalysis();
    this.analysisMethods[methodIndex].enabled = !this.analysisMethods[methodIndex].enabled;
    this.model.analysis_type = this.analysisMethods[methodIndex].type;
  }


  /**
   * Gestione dei box dei settori
   */
  getSubsectorsArrayFiltered (sectorKey) {
    return this.workingSectorService.getAllsubsectorsForSelectBySectorKey(sectorKey);
  }
  addSector() {
    this.model.sectors.push({sector_key: null,  subsector_key: null});
  }
  removeSector(positionToDelete) {
    this.model.sectors.splice(positionToDelete, 1);
    if (this.model.sectors.length == 0)
      this.addSector();
  }


  /**
   * Gestione dei box degli obiettivi
   */
  addSecondaryTarget() {
    this.model.secondary_targets.push({type: 'secondary', title: '', evaluation_criteria: null});
  }
  removeSecondaryTarget(positionToDelete) {
    this.model.secondary_targets.splice(positionToDelete, 1);
  }


  /**
   * Gestione dei box dei criteri
   */
  addCriteria() {
    this.model.criteria.push({order: this.model.criteria.length+1,  title: '', measure_unit: null, evaluation: null, typology: null});
  }
  removeCriteria(positionToDelete) {
    this.model.criteria.splice(positionToDelete, 1);
    if (this.model.criteria.length == 0)
      this.addCriteria();
  }


  /**
   * Gestione dei box dei criteri
   */
  addConstraint() {
    this.model.constraints.push({description: '', from: null, to: null});
  }
  removeConstraint(positionToDelete) {
    this.model.constraints.splice(positionToDelete, 1);
  }


  /**
   * Gestione della selezione dei bottoni di opzione dei ruoli
   */
  optionRoleOnClick(event) {
    this.model.options_role = event;
  }


  /**
   * Gestione dell'obbligatorietà dei bottoni dei ruoli
   */
  optionRoleRequiredOnClick(event) {
    this.model.options_role_required = event;
  }


  /**
   * Gestione dei box dei ruoli
   */
  addRole() {
    this.model.roles.push({orgnization_professional_role_id: null, process_role: null,
      complete_process_enabled: false, review_enabled: false, provide_new_proposal_enabled: false});
  }
  removeRole(positionToDelete) {
    this.model.roles.splice(positionToDelete, 1);
    if (this.model.roles.length == 0)
      this.addRole();
  }




  /**
   * Evento di submit di tutta la pagina
   */
  onSubmit() {

    console.log(this.model);

    //  validazione dei dati
    if (!this.model.framework_name) { this.toast.error("Attenzione", "Non è stato inserito il nome del framework"); return; }
    if (!this.model.organization_id) { this.toast.error("Attenzione", "Non è stata selezionata l'organizzazione"); return; }
    if (!this.model.analysis_type) { this.toast.error("Attenzione", "Non è stata selezionata nessuna metodologia"); return;}
    if (!this.model.purpose_description) { this.toast.error("Attenzione", "Non è stata inserita una descrizione"); return;}
    if (!this.sectorsValid) { this.toast.error("Attenzione", "Non hai inserito il settore, o alcuni settori non sono validi"); return;}
    if (!this.primaryTargetsValid) { this.toast.error("Attenzione", "Non hai inserito l'obiettivo primario"); return;}
    if (!this.secondaryTargetsValid) { this.toast.error("Attenzione", "Alcuni obiettivi secondari non sono validi"); return;}
    if (!this.criteriaValid) { this.toast.error("Attenzione", "Alcuni criteri non sono validi"); return;}
    if (!this.constraintsValid) { this.toast.error("Attenzione", "Alcuni vincoli non sono validi"); return;}
    if (!this.question_role_satisfied) { this.toast.error("Attenzione", "Non hai risposto alle domande sul team"); return;}

    //  TODO_MICHELE: validare i campi per i ruoli del framework
    // if (!this.role_satisfied) { this.toast.error("Attenzione", "Non hai risposto alle domande sul team"); return;}

    console.log("------------- VALID -----------");


    this.loading = true;
    this.frameworkService.save(this.model, (result) => {
      console.log(result);
      this.loading = false;
    }, (error) => {
      this.loading = false;
    })

  }


  /**
   * Valida i settori
   */
  get sectorsValid() {
    if (this.model.sectors.length == 0)
      return false;
    for (let sector of this.model.sectors) {
      if (!sector.sector_key)
      return false;
    }
    return true;
  }


  /**
   * Valida gli obiettivi inseriti
   */
  get primaryTargetsValid() {
    if (!this.model.primary_target.evaluation_criteria || !this.model.primary_target.title) {
      return false;
    }
    return true;
  }

  /**
   * Valida gli obiettivi secondari inseriti
   */
  get secondaryTargetsValid() {
    for (let secondary_target of this.model.secondary_targets) {
      if (!secondary_target.title || !secondary_target.evaluation_criteria)
        return false;
    }
    return true;
  }

  /**
   * Valida i criteri inseriti
   */
  get criteriaValid() {
    for (let criteria of this.model.criteria) {
      if (!criteria.evaluation || !criteria.typology || !criteria.title)
        return false;
      else if (this.criteriaService.criteriaIsQuantitative(criteria.typology) && !criteria.measure_unit)
        return false;
    }
    return true;
  }

  /**
   * Valida i vincoli
   */
  get constraintsValid() {
    for(let constraint of this.model.constraints) {
      if (isNaN(parseInt(constraint.criteria_1_order)) || isNaN(parseInt(constraint.criteria_2_order)) || !constraint.description)
        return false;
      else if (constraint.criteria_1_order == constraint.criteria_2_order)
        return false;

      //  controllo anche se sono stati vincolati alcuni criteri simili tra loro
      let checkConstrait = this.model.constraints.filter(x => x.criteria_1_order == constraint.criteria_1_order);
      checkConstrait = checkConstrait.filter(y => y.criteria_2_order == constraint.criteria_2_order);
      if (checkConstrait.length > 1)
        return false;
      checkConstrait = this.model.constraints.filter(x => x.criteria_1_order == constraint.criteria_2_order);
      checkConstrait = checkConstrait.filter(y => y.criteria_2_order == constraint.criteria_1_order);
      if (checkConstrait.length > 0)
        return false;
    }
    return true;
  }

  /**
   * Valida le risposte prima di aprire il team
   */
  get question_role_satisfied () : boolean {

    //  se non si vogliono inserire i ruoli
    if (this.model.options_role == false)
      return false;

    //  se si vogliono inserire i ruoli e si è risposto alla richiesta di obbligatorietà di uso dei ruoli
    if (this.model.options_role == true && this.model.options_role_required != null)
      return true;

    return false;
  }


  /**
   * Valida i ruoli sul framework
   */
  get role_satisfied () : boolean {
    return true;
  }




  public getUnitsService() {
    return this.unitsService;
  }
  public getCriteriaService() {
    return this.criteriaService;
  }
  public getWorkingSectorService() {
    return this.workingSectorService;
  }

}
