import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-modal-new-proposal',
  templateUrl: './modal-new-proposal.component.html',
  styleUrls: ['./modal-new-proposal.component.scss']
})
export class ModalNewProposalComponent implements OnInit {

  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: {}) { }

  newProposal: boolean = true;
  proposalData: any = {};

  ngOnInit(): void {

    if (this.data && Object.keys(this.data).length > 0) {
      this.proposalData = JSON.parse(JSON.stringify(this.data));
      this.newProposal = false;
    }
  }


  closeDialogue() {
    this.dialog.closeAll();
  }

}
